import React, { useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Import of components
import  CartItem  from "./CartItem";
import SavedCarts from "./SavedCarts";

// Actions
import { showPaymentPopup } from "../actions/PaymentAction";
import { showSaveCartPopup, showSavedCartsDropdown, chooseCart, setDeletedItem } from "../actions/SaveCartAction";
import { dropCard } from "../actions/ShoppingCartAction";
import { ScrollToPosition } from "./ScrollToPosition";
import { addToCart, deleteCartItem, increment, decrement, createCartStart, getCartItemsList, createOrder } from "../actions/ShoppingCartAction";

import bc from "../callsetter";
import { Animated } from "react-animated-css";

const Cart = React.memo((props) => {

	const [dropCounter, setDropCounter] = useState(0);

	const dropAreaRef = useRef();
	const cartItemsRef = useRef();

	const savedCarts = useSelector(state => state.saveCartReducer.savedCarts);
	const cartData = useSelector(state => state.shoppingCartReducer);
	const cart = useSelector(state => state.shoppingCartReducer.cart);
	const cartDataId = useSelector(state => state.shoppingCartReducer.cart.id);
	const cartItems = useSelector(state => state.shoppingCartReducer.cartItems);
	const defaultCart = useSelector(state => state.shoppingCartReducer.defaultCart);
	const defaultCartId = useSelector(state => state.shoppingCartReducer.defaultCart.id);
	const chosenCart = useSelector(state => state.saveCartReducer.chosenCart);
	const chosenCartId = useSelector(state => state.saveCartReducer.chosenCart.id);
	const isAuthenticated = useSelector(state => state.authPopup.auth.isAuthenticated);
	const authUser = useSelector(state => state.authPopup.auth.authUser);
	const user = useSelector(state => state.userReducer.me);
	const showCart = useSelector(state => state.showCartReducer.showCart);
	const products = useSelector(state => state.productsReducer.products);
	const productsMap = useSelector(state => state.productsReducer.productsMap);

	const dispatch = useDispatch();

	const history = useHistory();

	/**
    * Change quantity on mouse click. This function increases the item number
	*/

	const addItem = (product) => {
		dispatch(addToCart(product));
	};

	/**
    * Change quantity on mouse click. This function decreases the item number
	*/

	const removeItem = (product) => {
		dispatch(decrement(product));
	};

	/**
    * Two functions passing the event parametar, allowing the user to drop an item in the required target[cart] area
	*/

	const drop = (e) => {
		e.preventDefault();
		var data = e.dataTransfer.getData("id");
		dispatch(addToCart(products[productsMap[data]]));
		setDropCounter(0);
		dropAreaRef.current.classList.remove("deactivate");
	};

	const allowDrop = (e) => {
		e.preventDefault();
		if (cartItemsRef.current !== null) {
			cartItemsRef.current.style.pointerEvents = "none";
		}
	};

	/**
	* Displays the Payment Popup
	*/

	const showPaymentPopup = () => {
		return bc.orders.createOrder(cartDataId)
			.then((params) => {
				if (params.ok) {
					dispatch(createOrder(params.data));
					history.push("/cart/" + (cartDataId));
				}
			})
			.catch((err) => { console.error(err) });
	};

	/**
	* Deletes the product from the users' shopping cart
	*/

	const removeProduct = (product) => {
		dispatch(deleteCartItem(product));
	};

	/**
	* Display the Create List Popup
	*/

	const goToCreateList = () => {
		history.push("/create-list");
	};

	/**
	* Pick Default cart from Lists dropdown
	*/

	const goToDefaultCart = () => {
		dispatch(chooseCart(defaultCart));
		dispatch(createCartStart(defaultCart));
		dispatch(getCartItemsList(defaultCartId));
	};

	/**
	* Pick any given list in the list dropdown
	*/

	const chooseList = (cartData) => {
		dispatch(chooseCart(cartData));
		dispatch(createCartStart(cartData));
		dispatch(getCartItemsList(cartData.id));
	}; 

	/**
	* Display the Create List Popup
	*/

	const askDeleteListConfirmation = (e, item) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(showSavedCartsDropdown(savedCarts));
		dispatch(setDeletedItem(item));
		history.push("/delete-list/" + item.id);
	};

	/**
	* Style drag area when item is about to be dropped
	*/

	const styleDragArea = (e) => {
		e.preventDefault();
		e.persist();
		setDropCounter(dropCounter + 1);
		e.target.classList.add("deactivate");
	};

	const styleDragAreaOff = (e) => {
		e.preventDefault();
		e.persist();
		setDropCounter( dropCounter - 1);
		if(dropCounter === 0) {
			e.target.classList.remove("deactivate");
		}
	};

	/**
    * Checks if product is on promotion do display promotional price. If not it switches to regular price
	*/

	const PriceValue = (product) => {
		let data;

		if (product.promotion_price !== null) {
			data = Math.trunc(product.promotion_price);
		} else {
			data = Math.trunc(product.price);
		}

		return data;
	};

		let sum = 0;

		const showSaveCartPopup = () => {
			dispatch(showSavedCartsDropdown(savedCarts));
		};

		return (
			<div className={"cart__content " + (dropCounter > 0 ? "drop-area" : "")} draggable="false" onDrop={drop} onDragOver={allowDrop} onDragEnter={styleDragArea} onDragLeave={styleDragAreaOff} ref={dropAreaRef}>
				{!isEmpty(user) && (isAuthenticated && authUser !== null) ?
					<SavedCarts
						showSavedCarts={showSaveCartPopup}
						items={savedCarts}
						goToCreateList={goToCreateList}
						askDeleteListConfirmation={askDeleteListConfirmation}
						goToDefaultCart={goToDefaultCart}
						chooseList={chooseList}
						defaultCart={defaultCart}
					/> : null}
				<div className={"cart__header " + (isAuthenticated && authUser !== null ? "push_header" : "")}>
					<h2><FormattedMessage id={"CART_HEADER"} /></h2>
					{cartItems.length !== 0 ?
						<div className="cart__count">
							{!ArrayIsEmpty(cartItems) ? "0" : cartItems.length}
						</div> : null}
				</div>

				{(ArrayIsEmpty(cartItems) && defaultCart.hasOwnProperty("id")) && (ArrayIsEmpty(products) && !isEmpty(productsMap)) ?
					<div className={"cart__items " + (ArrayIsEmpty(cartItems) ? "trim__items" : "")} ref={cartItemsRef}>
						<Animated style={{animationDuration:"0.2s"}} isVisible={ArrayIsEmpty(cartItems)} animationIn="fadeIn" animationOut="fadeOut" className="cart__items--scrollable--web cart__items--scrollable">

							<ScrollToPosition content="cart__items--scrollable--web" />

							{cartItems
								.filter((item) => {
									return !isEmpty(chosenCart) ? item.cart_id === chosenCartId : defaultCartId === item.cart_id;
								})
								.filter((item) => { return products.some(p => item.product_id === p.id) })
								.map((item) => {
									return (
										<div key={item.id} className={"cart-item-src " + (products[productsMap[item.product_id]].quantity <= 0 ? "unavaialbe" : "")}>

											{products[productsMap[item.product_id]].quantity <= 0 ? <span className="cart-item-status">не е достапен</span> : null}

											<CartItem
												countInfo={item}
												data={products[productsMap[item.product_id]]}
												increment={addItem}
												decrement={removeItem}
												removeProduct={removeProduct}
												showIncrement={true}
												availability={item.availability}
											/>
										</div>
									)
								})
							}
						</Animated>

					</div> : null }

				{cartItems.length === 0 ?
					<div id="empty-cart" className={"dragarea " + (!isAuthenticated || authUser === null ? "push-empty-state" : "")}>
						<div className="empty-cart__container">
							<div className="scaled">
								<div className="empty-cart-icon">
									<span></span>
								</div>

								<h2><FormattedMessage id={"EMPTY_CART"} /></h2>
							</div>
						</div>
					</div> : null}

				<div className="cart__payments">
					{cart.length !== 0 ? <div className="cart--gradient"></div> : null}

					<div className="cart--positioned">
						<div className="cart__payments-block cart__total-value">
							<div className="value__text">
								{(!isEmpty(cartData) && ArrayIsEmpty(cartItems)) || (ArrayIsEmpty(products) && !isEmpty(productsMap)) ?
									cartItems.map(item => {
										products[productsMap[item.product_id]] !== undefined && products[productsMap[item.product_id]].quantity !== 0 && item !== undefined ?
											sum += PriceValue(products[productsMap[item.product_id]].quantity < 0 ? null : products[productsMap[item.product_id]]) * parseInt(item.item_count, 0) : null
									}) : null}
								<FormattedMessage id={"CART_TOTAL"} /> 
								<h2>{sum} <FormattedMessage id={"MKD"} /></h2>
							</div>
						</div>

						<div className="cart__payments-block cart__buttons">
							<button aria-label="payment" disabled={!ArrayIsEmpty(cartItems)} className="cart__pay-button icon-button--primary" onClick={showPaymentPopup}>
								<span className="cart__pay-button-icon icon-button--naked">raft kasa</span>
								<FormattedMessage id={"GO_TO_PAYMENT"} />
							</button>
						</div>
					</div>
				</div>
			</div>
		)
}, (nextProps, props) => {
	return props.cartItems !== nextProps || props.cartItems !== nextProps.cartItems;
});

export default Cart;