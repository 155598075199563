import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Animated } from "react-animated-css";
import { FormattedMessage } from "react-intl";
import { withRouter, useHistory, useParams, useLocation } from "react-router-dom";

import bc from "../callsetter";
import { Bookmark } from "@oblax/sdk-js";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Component Imports
import  ProductImage  from "./ProductImage";
import QuantityPicker  from "./QuantityPicker";
import RecommendedProduct from "./RecommendedProduct";
import Badge from "./Badge";
import { ProductDetailsTabs } from "./ProductDetailsTabs/ProductDetailsTabs";

// Actions
import { addToCart } from "../actions/ShoppingCartAction";
import { removeFavoritedItem } from "../actions/FavoritesAction";
import { incrementProductCount, decrementProductCount, resetProductCount, setCountInProductMap } from "../actions/ProductsAction";

import ProductTitle from "./shared/ProductTitle";
import MetaMeasurment from "./shared/MetaMeasurment";
import { retrieveMap } from "echarts/lib/coord/geo/mapDataStorage";
import { filter } from "lodash";


const ProductPopup = (props) => {

	const [showP, setShowP] = useState(true);
	const [recommended, setRecommended] = useState([]);
	const [prevPath, setPrevPath] = useState("");
	const [productData, setProductData] = useState({});

	const cartItems = useSelector(state => state.shoppingCartReducer.cartItems);
	const productCount = useSelector(state => state.productsReducer.countProductMap);

	const dispatch = useDispatch();

	const Slider = useRef();

	let { id } = useParams();
	let location = useLocation();
	let history = useHistory();

	useEffect(() => {
		setProductData(props.products[props.productsMap[id]]);
		if(location.state !== undefined) {
			if(location.state.hasOwnProperty("fromRaft")) {
				if(location.state.fromRaft) {
					dispatch(setCountInProductMap(id, 1));
				}
			} else if(location.state.hasOwnProperty("fromCart")) {
				for(var i = 0; i < cartItems.length; i ++) {
					if(cartItems[i].product_id === id) {
						dispatch(setCountInProductMap(id, cartItems[i].item_count));
					}
				}
			}
		}
	}, []); 

	useEffect(() => {
		setProductData(props.products[props.productsMap[id]]);
	}, [id]);

	/**
    * Change quantity on mouse click. This function increases the item number
	*/

	const _addItem = (e) => {
		e.stopPropagation();
		dispatch(incrementProductCount(id));
	};

	/**
    * Change quantity on mouse click. This function decreases the item number
	*/

	const _removeItem = (e) => {
		e.stopPropagation();
		dispatch(decrementProductCount(id));
	};

	/**
	* Add Item to cart
	*/

	const handleAddToCart = () => {
		if(location.state !== undefined) {
			if (location.state.fromCart) {
			   for (var i = 0; i < cartItems.length; i++) {
				   if (cartItems[i].product_id === id) {
					   dispatch(addToCart(productData, productCount[id] - cartItems[i].item_count));
					   history.push("/");
				   }
			   }
		   } else {
			   dispatch(addToCart(productData, productCount[id]));
			   history.push("/");
		   }
		} else {
			dispatch(addToCart(productData, productCount[id]));
			history.push("/");
		}
	};

	/**
    * Style favorite items with a filled red heart icon
	*/

	const handleFavoritesStyle = (productId) => {
		let classes = ['raft-card__favorite icon-button--naked'];

		if (props.favorites.length !== 0) {
			props.favorites.forEach((fav) => {
				if (fav.record_id === productId) {
					classes.push('favorite--active');
				}
			})

			if (recommended.length !== 0) {
				classes.push('move-button');
			}
		}

		return classes.join(' ');
	};


	const handleFavorites = (productId) => {
		let favId = props.favorites.filter((item) => { return item.record_id === productId });

		if (props.favorites.length <= 0) {
			addToFavorites(productId);
		} else {
			if (props.favorites.includes(favId[0])) {
				removeFromFavorites(favId[0].id);
			} else {
				addToFavorites(productId);
			}
		}
	};

	/**
    * Add to favorites
	*/

	const addToFavorites = (productId) => {
		let b = new Bookmark();
		b.loadFromJSON({
			type: "product",
			record_id: productId,
			user_id: props.user.id
		});

		return bc.bookmarks.createBookmark(props.user.id, "product", b)
			.then((params) => {
				if (params !== null) {
					props.fetchFavorites();
				}
			})
			.catch((err) => {
				console.error("Error creating bookmark", err);
			})
	};

	/**
    * Remove from favorites
	*/

	const removeFromFavorites = (favId) => {
		return bc.bookmarks.removeBookmark(props.user.id, "product", favId)
			.then((params) => {
				if (params !== null) {
					dispatch(removeFavoritedItem(favId));
				}
			})
			.catch((err) => {
				console.error("Error removing bookmark", err);
			})
	};

	/**
    * Goes back to the previous product, if you've selected a product in the recommended section
	*/

	const _goBack = (data) => {

		history.goBack();
		setRecommended([
			...recommended.filter((rec) => {
				return rec.id !== data.id;
			})
		]);
	}; 

	/**
    * Goes to recomennded product
	*/

	const goToRecomendedItem = (data) => {
		let d = {
			id: data.id
		}
		history.push("/product/" + data.id);
		setRecommended([...recommended, d]);
	};

	/**
    * Closes the popup
	*/

	const _closePopup = () => {
		setShowP(false);
		setTimeout(() => {
			history.push("/");
			dispatch(resetProductCount());
		}, 100);
	};

	/**
    * Checks if product is on promotion do display promotional price. If not it switches to regular price
	*/

	const Price = (product) => {
		let data;

		if (product.promotion_price !== null) {
			data = <h2 className="raft-card__price">
				<span className="cross">
					<span className="main-price-data-discount">{Math.trunc(product.price)}</span>
					<span className="main-price-data-discount-new">
						-{Math.trunc(((product.price - product.promotion_price) / product.price) * 100)}%
					</span>
				</span>
				<span>
					<span className="main-price-data">{Math.trunc(product.promotion_price)}</span>
					<span className="price-currency"><FormattedMessage id={"MKD"} /></span>
				</span>
			</h2>
		} else {
			data = <h2 className="raft-card__price">
				<span>
					<span className="main-price-data">{Math.trunc(product.price)}</span>
					<span className="price-currency"><FormattedMessage id={"MKD"} /></span>
				</span>
			</h2>
		}

		return data;
	};

	/**
    * Checks if product is on promotion do display promotional price. If not it switches to regular price
	*/

	const PriceValue = (product) => {
		let data;

		if (product.promotion_price !== null) {
			data = Math.trunc(product.promotion_price);
		} else {
			data = Math.trunc(product.price);
		}

		return data;
	};

	const GoNext = () => {
		Slider.current.scrollBy({
			top: 0,
			left: 398,
			behavior: 'smooth'
		});
	};

	const GoPrev = () => {
		Slider.current.scrollBy({
			top: 0,
			left: -398,
			behavior: 'smooth'
		});
	};

		const _handleFavorites = (e) => {
			e.preventDefault();
			handleFavorites(productData.id);
		};

		let categories = !isEmpty(productData) ? productData.category.map(el => el.id) : [];

		const RecommendedByCategoryLength = props.products.length > 0 && !isEmpty(productData) ? props.products.filter((p, i) => { return p.id !== productData.id && p.category.some(c => categories.includes(c.id)) && p.quantity > 0 }) : [];

		return (
			!isEmpty(productData) && ArrayIsEmpty(props.products) && !isEmpty(props.productsMap) && !isEmpty(productCount) ?
				<div className={"product__popup " + (productData.quantity === 0 ? "unavailable-product" : "")} id="product--web">
					<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
						<div className="product__popup--overlay" onClick={() => _closePopup(productData)}></div>
					</Animated>
					<Animated style={{ animationDuration: "0.2s" }} className={"product__popup-container " + (productData.quantity <= 0 ? "expand-popup" : "")} animationIn="zoomIn" animationOut="fadeOut" isVisible={showP} >
						<div className="product__popup--full">
							<div className="section__favorite">
								<div className={"badges__container " + ((isEmpty(props.user) && isEmpty(props.auth) && !props.auth.isAuthenticated) || props.auth.authUser === null ? "clip-margin" : "")}>
									{productData.badges !== null ?
										productData.badges !== undefined &&
										productData.badges.map((badge, i) => {
											return (
												<Badge key={i} data={badge} />
											)
										}) : null}
								</div>

								{!isEmpty(props.user) && !isEmpty(props.auth) && props.auth.isAuthenticated && props.auth.authUser !== null ?
									<button aria-label="add-to-favorites" onClick={_handleFavorites} className={handleFavoritesStyle(productData.id)}></button> : null}

								{recommended.length <= 0 ? null : <button aria-label="back" key={productData.id} onClick={() => _goBack(productData)} className="go-back icon-button--naked">go back</button>}
							</div>

							<div className="section__close">
								<button aria-label="cancel" onClick={() => _closePopup(productData)} className="close__popup-button icon-button--naked"></button>
							</div>

						</div>

						<div className="product__popup-main">
							<div className="product__section--left">
								<ProductImage image={productData.images} />
								<MetaMeasurment type={productData.type} meta={productData.meta} />
							</div>

							<div className="product__section--right">
								<div className="product__section-block-inside product__section--top">
									<div className="product__titles">
										<span className="popup__product-category">{productData.description}</span>
										<ProductTitle
											class="popup__product-title"
											name={productData.name}
											insertBreak={false}
										/>
									</div>

									<div className="product__titles">
										{Price(productData)}
									</div>
								</div>

								<div className="product__section-block-inside popup__tabs">
									<ProductDetailsTabs productData={productData} />
									<div className="tabs-fade"></div>
									<div className="tabs__content-switchable"></div>
								</div>

								<div className="product__section-block-inside popup__quantity">
									<QuantityPicker
										items={productCount[id]}
										price={PriceValue(productData)}
										currency={productData.price_currency}
										// changeItemNumber={_changeItemNumber}
										addItem={_addItem}
										removeItem={_removeItem}
										showIncrement={true}
									/>
									<div className="button-disclaimer">
										{productData.quantity <= 0 ? <h2><FormattedMessage id={"UNAVAILABLE_PRODUCT"} /></h2> : null}
										<button aria-label="add-to-cart" disabled={productData.quantity <= 0} onClick={handleAddToCart} className="icon-button--secondary">
											<span className="popup__add-to-cart icon-button--naked">во кошничка</span>
											во кошничка
									</button>
									</div>
								</div>
							</div>
						</div>

						<div className="product__popup--recommended">
							<div className="recommended__header">
								<h2><FormattedMessage id={"RECOMMENDED_PRODUCTS"} /></h2>
							</div>
							<button disabled={RecommendedByCategoryLength.length <= 5} onClick={() => GoPrev()} className="carousel-arrow block carousel-prev custom" data-role="none"></button>
							{RecommendedByCategoryLength.length > 0 ?
								<div className="recommended-static" ref={Slider}>
									{props.products.filter((p, i) => { return p.id !== productData.id && p.category.some(c => categories.includes(c.id)) && p.quantity > 0 }).slice(0,10).map(product => {
										return (
											<RecommendedProduct
												goToRecomendedItem={() => goToRecomendedItem(product)}
												key={product.id}
												data={product}
											/>
										)
									})}
								</div> : <div className="empty-data-container recommended-by-category">
									<div className="empty-data-centered">
										<span>empty icon</span>
										<h2>Нема други производи во оваа категорија.</h2>
									</div>
								</div>
							}
							<button disabled={RecommendedByCategoryLength.length <= 5} onClick={() => GoNext()} className="carousel-arrow block carousel-next custom" data-role="none"></button>
							<div className="recommended__gradient--decorator"></div>
						</div>
					</Animated>
				</div> : null
		)
}

export default ProductPopup;