const initialState = {
	show: false,
	searchValue: ""
};

const searchReducer = (state = initialState, action) => {
	switch(action.type) {
		case "SHOW_SEARCH" : 
			return {
				...state,
				show: true
			}
		case "HIDE_SEARCH" : 
			return {
				...state,
				show: false
			}
		case "FILL_SEARCH_VALUE" :
				return {
					...state,
					searchValue: action.payload
				}
		case "CLEAR_SEARCH_VALUE" :
				return {
					...state,
					searchValue: ""
				}
		default: return state;
	}
}

export default searchReducer;