import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";


const DisclaimerPopup = (props) => {

	const [showP, setShowP] = useState(true);

	const history = useHistory();

	const closePopup = () => {

		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);
	};

	const takeMeToLogin = () => {

		setShowP(false);
		setTimeout(() => {
			history.push("/login");
		}, 100);
	};

		return (
			<div className="product__popup confirmation__popup" >
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
					<div onClick={closePopup} className="product__popup--overlay"></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP}>
					<div className="popup__header">
						<div className="popup__header--block">
							<h2>Помош</h2>
						</div>

						<div className="popup__header--block">
							<button aria-label="cancel" onClick={closePopup} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div className="popup__content">
						<h2 className="popup__disclaimer">Пробавте да се логирате со профил од една од социјалните мрежи Facebook и Google Plus, чија e-mail адреса веќе постои во нашата датабаза. Логирајте се со таа e-mail адреса за да ги споиме вашите профили.</h2>
					</div>

					<div className="popup__buttons">
						<button aria-label="cancel" className="button__ghost--primary" onClick={closePopup}>Откажи</button>
						<button aria-label="log-in" className="button--primary" onClick={takeMeToLogin}>Логирај се</button>
					</div>
				</Animated>
			</div>
		)
}

export default DisclaimerPopup;
