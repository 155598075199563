import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

/* Internationalization */
import { addLocaleData } from "react-intl";

// Store and Provider needed for REDUX
import { Provider } from "react-redux";
import store from "./store";

// Import of Components
import { App } from "./components/App";

// Import of MAIN CSS files
import "./assets/css/global.css";
import "./assets/css/media.css";

// Import of ANIMATE css files
import "./assets/css/animate.css";

// Import of TABS COMPONENT css files
import "./assets/css/tabs.css";

// Import of COLLAPSIBLE COMPONENT css files
import "./assets/css/collapse.css";

// Import of DATEPICKER css files
import 'react-day-picker/lib/style.css';
import './assets/css/react-datetime.css';

/* Languages used throughout app */
import en from "react-intl/locale-data/en";
import mk from "react-intl/locale-data/mk";

import * as serviceWorker from './serviceWorkerRegistration.js';

// Sentry.init({
// 	dsn: "https://5f828f85161b4eb7af263aa2fdc92295@sentry.monitoring.oblax.io/2",
// 	integrations: [new Integrations.BrowserTracing()],
// 	tracesSampleRate: 1.0,
// 	environment: process.env.REACT_APP_ENVIRONMENT
// });

/* Language imported */
addLocaleData([...en, ...mk]);

ReactDOM.render(
	<Provider store={store}>  
		<BrowserRouter>
			<Route path="/" component={ App } />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();