import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter, useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { ArrayIsEmpty } from "../../helper";

const TrackOrder = (props) => {
	const history = useHistory();
	const trackOrder = useSelector(state => state.trackOrder.slideDown);

	const goToOrderHistory= () => {
		history.push("/order-history");
	}

		return(
			<div id="web-track" className={"track-order " + (trackOrder ? "track-order--slidedown" : "track-order--slideup")}>
				{
					ArrayIsEmpty(props.orders)

					?

					<div className="track-order__container">
						<div className="track-order__item status">
							<div className="status__item packing">
								<div className="status__item--circle">
									<span></span>
								</div>
								<div className="status__item--info">
									<h2><FormattedMessage id={"PACKING"} /></h2>
									<span></span>
								</div>
							</div>

							<div className="status__item shipping">
								<div className="status__item--circle">
									<span></span>
								</div>
								<div className="status__item--info">
									<h2><FormattedMessage id={"SHIPPING"} /></h2>
									<span></span>
								</div>
							</div>

							<div className="status__item shipped">
								<div className="status__item--circle">
									<span></span>
								</div>
								<div className="status__item--info">
									<h2><FormattedMessage id={"DELIVERED_ORDER"} /></h2>
									<span></span>
								</div>
							</div>
						</div>

						<div className="track-order__item  timing">
							<span><FormattedMessage id={"DELIVERY_TIME_PH"} /></span>
							<h2>35 мин</h2>
						</div>

						<div className="track-order__item  prev__orders">
							<button aria-label="order-history" onClick={ goToOrderHistory } className="button--primary">
								<FormattedMessage id={"PREVIOUS_ORDERS"} />
							</button>
						</div>
					</div>

					:

					<div className="spinner-container order-history-id-spinner">
						<div className="centered-spinner">
							<span>empty icon</span>
							<h2>Немате активни нарачки</h2>
						</div>
					</div>
				}
			</div>
		)
	
}

export default TrackOrder;
