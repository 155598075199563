import React from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "../helper";
import { useHistory } from "react-router";

const Locations = (props) => {

	const history = useHistory();

	const _showMap = (address) => {
		props.showMap(address);
		history.push("/settings/locations/" + (address.id))
	}

		let empty = props.data.slice(1);

		return(
			<div className="settings-container settings-children-container" id="settings--locations">
				<div className="settings__content--block">
					<div className="settings__content--header">
						<div className="category-title">
							<a>
								<span className="chevron"></span>
								<h2><FormattedMessage id={"LOCATIONS"} /></h2>
							</a>
						</div> 

						<div className="settings__content-block--actions">
								<button aria-label="new-address" onClick={props.showEmptyPopup} className="button__ghost--primary full-width-mobile-button">
									<span className="new-location--button icon-button--naked">raft kasa</span>
									<FormattedMessage id={"NEW_ADDRESS"} />
								</button>
						</div>
					</div>

					<div className="settings__content--main">
						<div className="settings-info--holder">
							<ul className={"address-list " + (empty.length ===  0 ? "hide-list" : "")}>
								{

									!isEmpty(props.data)

									?

									props.data.filter(location => location.label !== "DEFAULT_LOCATION").map(l => {
										return (
											<li key={ l.id } onClick={ () => _showMap(l)}>
												<span className="address-info--holder">
													<span className="address-label">{l.label}</span>
													<span className="address-src">{l.address}</span>
												</span>
												<button aria-label="show-map" onClick={ () => _showMap(l) } className="show-map icon-button--naked">
													<span></span>
												</button>
											</li>
										)
									})

									:

									null
								}
							</ul>

							{
								empty.length ===  0

								?

								<div id="empty-locations">
									<div className="empty-cart__container">
										<div className="empty-state-icon empty-location-icon">
											<span></span>
										</div>
										<h2><FormattedMessage id={"EMPTY_LOCATIONS_PH"} /></h2>
									</div>
								</div>

								:

								null
							}
						</div>

					</div>				
				</div>
			</div>
		)

}

export default Locations;
