const initialState = {
	showSavePopup: false,
	savedCarts: [
		"Митровден шиндиг",
		"Кишни дани",
		"Лична Хигиена",
	],
	showSavedCarts: false,
	showSavedCartsDropdown: false,
	chosenCart: {},
	deleteItemReady: {}
}

const saveCartReducer = (state = initialState, action) => {
	switch(action.type) {
		case "SHOW_SAVE_CART_POPUP":
			return {
				...state,
				showSavePopup: true
			}
		case "HIDE_SAVE_CART_POPUP":
			return {
				...state,
				showSavePopup: false
			}
		case "SAVE_CART":
			return {
				...state,
				savedCarts: [action.values, ...state.savedCarts],
				showSavePopup: false,
				showSavedCarts: true
			}
		case "SHOW_SAVED_CARTS_DROPDOWN":
			return {
				...state,
				showSavedCartsDropdown: !state.showSavedCartsDropdown,
				savedCarts: state.savedCarts
			}
		case "HIDE_SAVED_CARTS_DROPDOWN":
			return {
				...state,
				showSavedCartsDropdown: false,
			}
		case "CHOOSE_CART" :
			return {
				...state,
				chosenCart: action.payload
			}
		case "CHOOSE_DEFAULT_CART" :
			return {
				...state,
				chosenCart: ""
			}
		case "SET_DELETED_ITEM" :
			return {
				...state,
				deleteItemReady: action.payload
			}
		default: return state;
	}
}

export default saveCartReducer;