import { useState } from 'react';

import Select from '../../ui/Select';
import Input from '../../ui/Input';
import TextArea from '../../ui/TextArea';

import Cities from '../../../data/cities_mk';
import Suburbs from '../../../data/suburbs_sk';

const CheckoutPopupStepDeliveryLocation = (props) => {

    let cities = Cities.map(c => {
        return {value: c.name_latin, label: c.name}
    });

    return (
        <div className="workflow-step__delivery-location">
            <div className="workflow-step__form-container">
                <div className="workflow-step__delivery-location__city-region">
                    <Select 
                        label="Град" 
                        options={cities} 
                        firstLineEmpty={true}
                        error={props.errors.delivery_city.error}
                        name="delivery_city"
                        value={props.data.delivery_city}
                        onChange={props.onChange}
                        placeholder="Одберете град"
                    />
                    {
                        props.data.delivery_city === 'Skopje' ?
                        <Select 
                            label="Населба" 
                            options={Suburbs} 
                            firstLineEmpty={true}
                            error={props.errors.delivery_region.error}
                            name="delivery_region"
                            value={props.data.delivery_region}
                            onChange={props.onChange}
                            placeholder="Одберете нас..."
                        /> :
                        null
                    }
                </div>
                <Input 
                    label="Адреса и број"
                    error={props.errors.delivery_address.error}
                    name="delivery_address"
                    value={props.data.delivery_address}
                    onChange={props.onChange}
                />
                <div className="workflow-step__delivery-location__appartment">
                    <Input 
                        label="Влез" 
                        error={props.errors.delivery_building_entry.error}
                        name="delivery_building_entry"
                        value={props.data.delivery_building_entry}
                        onChange={props.onChange}
                    />
                    <Input 
                        label="Стан" 
                        error={props.errors.delivery_apartment.error}
                        name="delivery_apartment"
                        value={props.data.delivery_apartment}
                        onChange={props.onChange}
                    />
                    <Input 
                        label="Спрат" 
                        error={props.errors.delivery_building_floor.error}
                        name="delivery_building_floor"
                        value={props.data.delivery_building_floor}
                        onChange={props.onChange}
                    />
                </div>
                <TextArea 
                    label="Додатно објаснување за локацијата"
                    error={props.errors.delivery_additional_info.error}
                    name="delivery_additional_info"
                    value={props.data.delivery_additional_info}
                    onChange={props.onChange}
                />
            </div>
            <p className="workflow-step__info-container">Внимателно и точо внесете ги податоците за испорака, бидејќи истите се неопходни за брза и навремена испорака на Вашата нарачка.<br /><br />Обидете се што е можно подобро да ја ни опишете вашата локацијата, за нашите доставувачи да може полесно да ве пронајдат.<br /><br />За нарачките во другите градови, ќе бидете контактирани за точниот датум на испорака, кое е обично за 2-3 дена.</p>
        </div>
    );
};

export default CheckoutPopupStepDeliveryLocation;