import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// Component Imports
import  ProductImage  from "./ProductImage";
import Badge from "./Badge";
import ProductTitle from "./shared/ProductTitle";
import MetaMeasurment from "./shared/MetaMeasurment";

const Card = React.memo((props) => {
	const Price = (product) => {
		let data;
		if (product.promotion_price !== null) {
			data = <h2 className="raft-card__price">
				<span className="cross">
					<span className="main-price-data-discount">{Math.trunc(product.price)}</span>
					<span className="main-price-data-discount-new">
						-{Math.trunc(((product.price - product.promotion_price) / product.price) * 100)}%
					</span>
				</span>
				<span>
					<span className="main-price-data">{Math.trunc(product.promotion_price)}</span>
					<span className="price-currency"><FormattedMessage id={"MKD"} /></span>
				</span>
			</h2>
		} else {
			data = <h2 className="raft-card__price">
				<span>
					<span className="main-price-data">{Math.trunc(product.price)}</span>
					<span className="price-currency"><FormattedMessage id={"MKD"} /></span>
				</span>
			</h2>
		}

		return data;
	}

	const _addToCart = (e) => {
		e.preventDefault();
		props.addToCart(props.product);
	}

	const _handleFavorites = (e) => {
		e.preventDefault();
		props.handleFavorites(props.product.id);
	}

	const _dragCard = (e) => {
		props.dragCard(e, props.product);
	}

	return (
		<Link to={{ pathname: `/product/${props.product.id}`, state: { fromRaft: true } }} draggable="true" onDragStart={_dragCard} className="raft-card" >
			<div className="raft-card__block raft-badges">
				<div className="badges__container">
					{
						props.product.badges !== null ?
						props.product.badges !== undefined &&
						props.product.badges.map((badge, i) => {
							return (
								<Badge key={i} data={badge} />
							)
						}) :
						null
					}
				</div>
				{
					props.auth.authUser !== null ? 
					<button 
						aria-label="add-to-favorites" 
						onClick={_handleFavorites} 
						className={props.handleFavoritesStyle(props.product.id)}
					></button> : 
					null
				}
			</div>
			<div className="centered-data">
				<ProductImage image={props.product.images} />
				<MetaMeasurment type={props.product.type} meta={props.product.meta} />
			</div>
			<div className="raft-card__product-info">
				<span className="raft-card__sub-title">{props.product.description}</span>
				<ProductTitle
					class="raft-card__title"
					name={props.product.name}
					insertBreak={true}
					key={props.product.id}
				/>
			</div>
			<div className="raft-card__block raft-card__actions">
				<button aria-label="add-to-cart" disabled={props.product.quantity <= 0} onClick={_addToCart} className="raft-card__add-to-cart icon-button__textless--dark">
					<span></span>
				</button>
				{Price(props.product)}
			</div>
		</Link>
	)
}, (props, nextProps) => {
	const changeProps = JSON.stringify(nextProps.favorites) !== JSON.stringify(props.favorites) || JSON.stringify(nextProps.product) !== JSON.stringify(props.product);
	const hideFavorites = JSON.stringify(nextProps.auth) !== JSON.stringify(props.auth);
	return changeProps || hideFavorites;
});

export default Card;