const initialState = {
	filterRaft: [],
	filteredRaftId: "",
	filteredRaftIdProducts: []
}

const filterRaftReducer = (state = initialState, action) => {
	switch(action.type) {
		case "SET_MAIN_CATEGORY_AS_DEFAULT" :
			return {
				...state,
				filteredRaftId: action.payload
			}
		case "SET_MAIN_FILTERED_PRODUCTS" :
			return {
				...state,
				filteredRaftIdProducts: action.payload
			}
		case "FILTER_RAFT" :
			return {
				...state,
				filterRaft: action.payload,
				filteredRaftIdProducts: action.payload.products
			}
		default: return state;
	}
}

export default filterRaftReducer;