export const getAllProducts = (products) => {
	return {
		type: "GET_ALL_PRODUCTS",
		payload: products
	}
};

export const getProductById = (product) => {
	return {
		type: "GET_ALL_PRODUCTS",
		payload: product
	}
};

export const createProductCountMap = (products) => {
	return {
		type: "CREATE_PRODUCT_COUNT_MAP",
		payload: products
	}
};

export const incrementProductCount = (id) => {
	return {
		type: "INCREMENT_PRODUCT",
		payload: id
	}
};

export const decrementProductCount = (id) => {
	return {
		type: "DECREMENT_PRODUCT",
		payload: id
	}
};

export const SetCardWidth = (width) => {
	return {
		type: "SET_CARD_WIDTH",
		payload: width
	}
};

export const resetProductCount = () => {
	return {
		type: "RESET_PRODUCT_COUNT_MAP"
	}
};

export const setCountInProductMap = (id, count) => {
	return {
		type: "SET_COUNT_IN_PRODUCT_MAP",
		payload: {
			id: id,
			count: count,
		}
	}
};