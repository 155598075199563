export const slideDownTrack = () => {
	return {
		type: "SLIDE_DOWN"
	}
}

export const slideDownTrackMobile = () => {
	return {
		type: "SLIDE_DOWN--MOBILE"
	}
}