import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { ScrollToTop } from "./ScrollToTop";
import { FormattedMessage } from "react-intl";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Component imports
import Card from "./Card";
import  Footer  from "./shared/Footer";

import bc from "../callsetter";
import { Bookmark } from "@oblax/sdk-js";

// Actions
import { removeFavoritedItem } from "../actions/FavoritesAction";
import { addToCart } from "../actions/ShoppingCartAction";

const Favorites = (props) => {

	const auth =  useSelector(state => state.authPopup.auth);
	const user =  useSelector(state => state.userReducer);
	const pages = useSelector(state => state.pagesReducer.pages);
	const dispatch = useDispatch();


	/**
    * Drags a product
	*/

	const dragCard = (e, product) => {
		e.dataTransfer.setData("id", product.id);
	}

	const handleFavorites = (productId) => {
		let favId = props.favorites.filter((item) => { return item.record_id === productId });

		if (props.favorites.length <= 0) {
			addToFavorites(productId);
		} else {
			if (props.favorites.includes(favId[0])) {
				removeFromFavorites(favId[0].id, favId[0].record_id);
			} else {
				addToFavorites(productId);
			}
		}
	}

	/**
    * Add to favorites
	*/

	const addToFavorites = (productId) => {
		let b = new Bookmark();
		b.loadFromJSON({
			type: "product",
			record_id: productId,
			user_id: props.user.me.id
		});

		return bc.bookmarks.createBookmark(user.me.id, "product", b)
			.catch((err) => {
				console.error("Error creating bookmark", err);
			})
	}

	/**
    * Remove from favorites
	*/

	const removeFromFavorites = (favId, recordId) => { 
		return bc.bookmarks.removeBookmark(user.me.id, "product", favId)
			.then((params) => {
				if (params.ok) {
					dispatch(removeFavoritedItem(favId));
				}
			})
			.catch((err) => {
				console.error("Error removing bookmark", err);
			})
	}

	/**
    * Style favorite items with a filled red heart icon
	*/

	const handleFavoritesStyle = (productId) =>  {
		let classes = ['raft-card__favorite icon-button--naked'];
		if (props.favorites !== undefined) {
			if (props.favorites.length !== 0) {
				props.favorites.forEach((fav) => {
					if (fav.record_id === productId) {
						classes.push('favorite--active');
					}
				})
			}
		}

		return classes.join(' ');
	}

	const handleAddToCart = (product) => {
		dispatch(addToCart(product));
	}

		const FavoriteCard = () => {
			return (
				<div className="raft__content">
					{
						(ArrayIsEmpty(props.products) && !isEmpty(props.productsMap)) ?
						props.favorites.map(p => {
							return (
								props.products[props.productsMap[p.record_id]] !== undefined ?
								<div key={p.id} className="favorite">
									<Card
										product={props.products[props.productsMap[p.record_id]]}
										addToCart={handleAddToCart}
										dragCard={dragCard}
										favorites={props.favorites}
										handleFavorites={handleFavorites}
										handleFavoritesStyle={handleFavoritesStyle}
										auth={auth}
										user={user}
									/>
								</div> : null
							)
						}) : null
					}
				</div>
			)
		}

		const EmptyDataDisclaimer = () => {
			return (
				<div id="empty-state">
					<div className="empty-cart__container">
						<div className="empty-favorites-icon">
							<span></span>
						</div>
						<h2><FormattedMessage id={"EMPTY_FAVORITE_PRODUCTS_PH"} /></h2>
					</div>
				</div>
			)
		}

		return (
			<div id="favorites">

				<ScrollToTop element="app-container" />

				<div className="component__header">
					<div className="component__header--block">
						<div className="category-title">
							<a>
								<span className="chevron"></span>
								<h2><FormattedMessage id={"FAVORITE_PRODUCTS"} /></h2>
							</a>
						</div>
					</div>
				</div>

				<div className="component__content all-products">
					{
						!ArrayIsEmpty(props.favorites)

							?

							<EmptyDataDisclaimer />

							:

							<FavoriteCard />
					}
				</div>
				<Footer pages={pages} openTour={props.openTour} />
			</div>
		)
}

export default Favorites;
