import { useState } from 'react';

import Input from '../../ui/Input';

const CheckoutPopupStepRecieverInfo = (props) => {
    return (
        <div className="workflow-step__reciever-info">
            <div className="workflow-step__form-container">
                <Input 
                    label="Име и презиме на примателот"
                    error={props.errors.recipient_full_name.error}
                    name="recipient_full_name"
                    value={props.data.recipient_full_name}
                    onChange={props.onChange}
                    validate="string"
                />
                <Input
                    label="E-mail на примателот"
                    error={props.errors.recipient_email.error}
                    name="recipient_email"
                    value={props.data.recipient_email}
                    onChange={props.onChange}
                    validate="email"
                />
                <Input
                    label="Телефон на примателот"
                    error={props.errors.recipient_phone.error}
                    name="recipient_phone"
                    value={props.data.recipient_phone}
                    onChange={props.onChange}
                    validate="phone"
                    placeholder="070 123 456"
                />
            </div>
            <p className="workflow-step__info-container">Доколку купувате за друго лице и нарачката треба да биде подигната од нивна страна, Ве молиме внесете ги нивните податоци, за да можеме соодветно да ја извршиме доставата.<br/><br/>Внесете ја и e-mail адресата на примателот, за да добие потврда за испорака на нарачката. Потврда за нарачката ќе добиете и Вие, на Вашата e-mail адреса.</p>
        </div>
    );
};

export default CheckoutPopupStepRecieverInfo;