const initialState = {
	paymentPopup: false,
	cart: [],
}

const paymentReducer = (state = initialState, action) => {
	switch(action.type) {
		case "OPEN_PAYMENT_POPUP":
			return {
				...state,
				paymentPopup: true,
				cart: []
			}
		case "CLOSE_PAYMENT_POPUP":
			return {
				...state,
				paymentPopup: false,
			}
		default: return state;
	}
}

export default paymentReducer;