import React, { Fragment } from "react";

const ProductTitle = (props) => {

	let strARR = [];
	
	if (props.name.indexOf("|") > 0) {
		strARR = props.name.split("|");

		if(props.insertBreak) {
			strARR.splice(1, 0, <br />);
		} else {
			strARR.slice(1, 1);
		}
	}

	return (
		<h2 className={props.class}>{props.name.indexOf("|") < 0 ? `${props.name}` : strARR.length > 0 ? strARR.map((el, i) => { return <Fragment key={i}>{el}</Fragment> }) : <span class="text-loader"><span className="loader-slider"></span></span>}</h2>
	)
}

export default ProductTitle;