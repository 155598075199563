import { useEffect, useState } from 'react';
import DEAFULT_AVATAR from '../assets/images/user_avatar_placeholder.svg';

const Avatar = (props) => {

    const [image, setImage] = useState(null);

    useEffect(() => {
        let i = `${process.env.REACT_APP_MINIO_CDN}/avatars/${props.src}`;
        setImage(i);
    }, [props.src])

    const onError = () => {
        setImage(DEAFULT_AVATAR);
    }

    return (
        <img src={image} onError={onError} className={props.className} />
    )
};

export default Avatar;