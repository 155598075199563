const initialState = {
	opened: false,
}

const menuReducer = (state = initialState, action) => {
	switch(action.type) {
		case "OPEN_MENU":
			return {
				...state,
				opened: true,
			}
		case "CLOSE_MENU":
			return {
				...state,
				opened: false,
			}
		default: return state;
	}
}

export default menuReducer;