import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { Formik, Form, ErrorMessage } from 'formik';


const RecoverPassword = (props) => {

	const [email, setEmail] = useState("");
	const [showP, setShowP] = useState(true);
	
	const history = useHistory();

	/**
    * Closes the popup
	*/

	const _closePopup = () => {

		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);

	};

	/**
    * Handle e-mail input
	*/

	const emailOnChange = (e) => {

		setEmail(e.target.value);
	};

	/**
    * Send recovery mail
	*/

	const SendPasswordRecoveryMail = (data) => {
		props.sendPassordRecoveryMail(data.email);
	};

		const { intl } = props;
		const inputPlaceholder = defineMessages({
			e_mail: {
				id: "E_MAIL",
			}
		});
		
		return (
			<div className={"product__popup"} id="recover__popup">
					<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
						<div className="product__popup--overlay" onClick={_closePopup}></div>
					</Animated>
	
					<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP}>
						<div className="product__popup--full">
							<div className="section__favorite">
								<h2 className="auth__popup-title"><FormattedMessage id={"PASSWORD_RECOVERY"} /></h2>
							</div>
	
							<div className="section__close">
								<button aria-label="cancel" onClick={_closePopup} className="close__popup-button icon-button--naked"></button>
							</div>
						</div>
	
						<div className="product__popup-main">
							<div className="popup__block popup__inputs">
							<div className="text-info">
								<p>За обновување на вашата лозинка потребен ни е вашиот e-mail.</p>
							</div>
							<Formik
								initialValues={{
									email: ""
								}}
								validate={values => {
									let errors = {};
									
									if (!values.email) { errors.email = "Задолжително"; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) { errors.email = "Неправилна e-mail адреса" }

									return errors;
								}}
								onSubmit={(values) => {
									SendPasswordRecoveryMail(values);
								}}
								render={({ errors, touched, values, handleChange }) => (
									<Form name="recover-password">
										<p>
											<ErrorMessage name="email" component="span" className="invalid-feedback halfed" />
											<input 
												type="email"
												name="email"
												id="email"
												onChange={handleChange}
												value={values.email}
												className={"placeholder__input " + (props.badRecoveryEmail ? "bad-input" : "", (errors.email && touched.email && errors.email ? "invalid-input" : ""))} 
												placeholder={intl.formatMessage(inputPlaceholder.e_mail)}
											/>
										</p>
										{ props.badRecoveryEmail ?  <p className="bad-login">Не најдовме таква e-mail адреса. Дали сте сигурни дека со оваа адреса е креаирана вашата сметка?</p> : null }
			
										<p className="action-paragraph">
											<button aria-label="send" type="submit" className="full-width__button--primary">	
												{ props.buttonLoader ?
													<div className="button-loader">
														<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
													</div> : <FormattedMessage id={"SEND"} /> }
											</button>
										</p>
									</Form>
								)}
							/>
						</div>
					</div>
				</Animated>
			</div>
		)
}

export default injectIntl(RecoverPassword);
