import React from "react";

import  FoodTabs  from "./FoodTabs";
import  BeverageTabs  from "./BeverageTabs";
import  PersonalHygieneTabs  from "./PersonalHygieneTabs";
import  CosmeticsTabs  from "./CosmeticsTabs";
import  CleaningTabs  from "./CleaningTabs";

const tabsComponents = {
	food: FoodTabs,
	beverage: BeverageTabs,
	cosmetics: CosmeticsTabs,
	personal_hygiene: PersonalHygieneTabs,
	cleaning: CleaningTabs
}

export const ProductDetailsTabs = (props) => {
	const ProductTabs = tabsComponents[props.productData.type];
	return ProductTabs ? <ProductTabs productData={props.productData} /> : "Нема информации за овој производ.";	
}