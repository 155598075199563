import React from "react";
import { Animated } from "react-animated-css";
import { FormattedMessage } from "react-intl";
import { Formik, Form, ErrorMessage } from 'formik';
import { useHistory } from "react-router-dom";

const ChangePasswordPopup = (props) => {

	const history = useHistory();

	const _hidePopup = () => {
		history.push("/settings/basic-info");
	}

		return (
			<div className={"product__popup"} id="location__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--overlay" onClick={_hidePopup}></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--full">
						<div className="section__favorite">
							<h2><FormattedMessage id={"CHANGE_PASSWORD"} /></h2>
						</div>


						<div className="section__close">
							<button aria-label="hide-popup" onClick={_hidePopup} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div onScroll={props._hideGradientOnBottomScroll} className="inputs">
						<Formik
							initialValues={{
								old_password: "",
								new_password: "",
								confirm_password: ""
							}}
							validate={values => {
								let errors = {};

								if (!values.old_password) errors.old_password = "Задолжително";
								if (!values.new_password) errors.new_password = "Задолжително";
								if (!values.confirm_password) { errors.confirm_password = "Задолжително" } else if (values.new_password !== values.confirm_password) { errors.confirm_password = "Лозинката не се совпаѓа"; }

								return errors;
							}}
							onSubmit={values => {
								props.resetPassword(values);
							}}
							render={({ errors, touched, values, handleChange }) => (
								<Form name="change-password">
									<div className="inputs-container">
										<div className="input__holders--col">
											<div className="label-holder">
												<ErrorMessage name="old_password" component="span" className="invalid-feedback halfed" />
												<label htmlFor="basic-info-name"><FormattedMessage id={"OLD_PASSWORD"} /></label>
											</div>
											<input type="password" name="old_password" onChange={handleChange} value={values.old_password} className={"label__input " + (errors.old_password && touched.old_password && errors.old_password ? "invalid-input" : "")} id="basic-info-name" />
										</div>
										<div className="input__holders--col">
											<div className="label-holder">
												<ErrorMessage name="new_password" component="span" className="invalid-feedback halfed" />
												<label htmlFor="basic-info-name"><FormattedMessage id={"NEW_PASSWORD"} /></label>
											</div>
											<input type="password" name="new_password" onChange={handleChange} value={values.new_password} className={"label__input " + (errors.new_password && touched.new_password && errors.new_password ? "invalid-input" : "")} id="basic-info-name" />
										</div>
										<div className="input__holders--col">
											<div className="label-holder">
												<ErrorMessage name="confirm_password" component="span" className="invalid-feedback halfed" />
												<label htmlFor="basic-info-name"><FormattedMessage id={"CONFIRM_PASSWORD"} /></label>
											</div>
											<input type="password" name="confirm_password" onChange={handleChange} value={values.confirm_password} className={"label__input " + (errors.confirm_password && touched.confirm_password && errors.confirm_password ? "invalid-input" : "")} id="basic-info-name" />
										</div>
										<div className="settings__content-block--actions">
											<div className="popup--gradient" id="mobile-popup-gradient--location"></div>
											<div>
												<button aria-label="cancel" className="button__ghost--primary" onClick={_hidePopup}>Откажи</button>
												<button aria-label="change-password" type="submit" className="button--primary">
													{props.buttonLoader ?
														<div className="button-loader">
															<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
														</div> : <FormattedMessage id={"CHANGE_PASSWORD"} />}</button>
											</div>
										</div>
									</div>
								</Form>
							)}
						/>
					</div>
				</Animated>
			</div>
		)
}

export default ChangePasswordPopup;
