const initialState = {
	me: {},
	userById: {},
	userLocation: [],
	userProfiles: [],
	userPreferences: {},
	userStatistics: {},
	userOrders: [],
	statisticsFilter: {
		seasonType: "monthly",
		month: new Date().getMonth() + 1, 
		year: new Date().getFullYear()
	}
}

const userReducer = (state = initialState, action) => {
	switch(action.type) {
		case "FETCH_LOGGEDIN_USER":
			return {
				...state,
				me: action.payload
			}
		case "FETCH_USER_BY_ID" :
			return {
				...state,
				userById: action.payload
			}
		case "FETCH_USER_LOCATION" :
			return {
				...state,
				userLocation: action.payload
			}
		case "FETCH_USER_PROFILES" :
			return {
				...state,
				userProfiles: action.payload
			}
		case "FETCH_USER_PREFERENCES" :
			return {
				...state,
				userPreferences: action.payload
			}
		case "FETCH_USER_STATISTICS" :
			return {
				...state,
				userStatistics: action.payload
			}
		case "CLEAR_USER_STATISTICS" :
			return {
				...state,
				userStatistics: {
					...state.userStatistics,
					least_expensive: {},
					most_expensive: {},
					orders: {},
					products: [],
					total_products: null,
					total_spent: null
				}
			}
		case "FETCH_USER_ORDERS" :
			return {
				...state,
				userOrders: action.payload
			}
		case "SET_STATISTICS_FILTER_TYPE": 
			return {
				...state,
				statisticsFilter: {
					...state.statisticsFilter,
					seasonType: action.payload.seasonType,
					month: action.payload.month,
					year: action.payload.year,
				}
			}
		case "CLEAR_USER_OBJECT_ON_LOGOUT": 
			return {
				...state,
				me: {}
			}
		default: return state;
	}
}

export default userReducer;