import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import OverlayContainer from '../OverlayContainer';
import CheckoutProductsList from './CheckoutProductsList';
import CheckoutPopupStepMain from './CheckoutPopupStepMain';
import CheckoutPopupStepPersonalInfo from './CheckoutPopupStepPersonalInfo';
import CheckoutPopupStepDeliveryLocation from './CheckoutPopupStepDeliveryLocation';
import CheckoutPopupStepDeliveryDatetime from './CheckoutPopupStepDeliveryDatetime';
import CheckoutPopupStepRecieverInfo from './CheckoutPopupStepRecieverInfo';
import CheckoutPopupStepCompanyInfo from './CheckoutPopupStepCompanyInfo';
import CheckoutPopupStepPaymentMethod from './CheckoutPopupStepPaymentMethod';

import oblax from "../../../callsetter";
import { Order } from "@oblax/sdk-js";

import Cities from '../../../data/cities_mk';

import './style.css';
import './style.mobile.css';

import ICON_ARROW_LEFT from '../../../assets/images/arrow_left_lightgrey.svg';
import ICON_ARROW_RIGHT from '../../../assets/images/arrow_right_white.svg';

const CheckoutPopup = (props) => {

    const paths = {
        'self': [
            { name: 'Лични податоци', com: 'PERSONAL_INFO' }, // ime, prezime, email, telefon
            { name: 'Локација за испорака', com: 'DELIVERY_LOCATION' }, // grad, ulica i broj (zaedno)
            { name: 'Време на испорака', com: 'DELIVERY_DATE_TIME' },
        ],
        'self-no-datetime': [
            { name: 'Лични податоци', com: 'PERSONAL_INFO' }, // ime, prezime, email, telefon
            { name: 'Локација за испорака', com: 'DELIVERY_LOCATION' }, // grad, ulica i broj (zaedno)
        ],
        'other-person': [
            { name: 'Лични податоци', com: 'PERSONAL_INFO' },
            { name: 'Податоци за примателот', com: 'RECIEVER_INFO' },
            { name: 'Локација за испорака', com: 'DELIVERY_LOCATION' },
            { name: 'Време на испорака', com: 'DELIVERY_DATE_TIME' },
        ],
        'other-person-no-datetime': [
            { name: 'Лични податоци', com: 'PERSONAL_INFO' },
            { name: 'Податоци за примателот', com: 'RECIEVER_INFO' },
            { name: 'Локација за испорака', com: 'DELIVERY_LOCATION' },
        ],
        'company': [
            { name: 'Лични податоци', com: 'PERSONAL_INFO' },
            { name: 'Податоци за компанијата', com: 'COMPANY_INFO' }, // ЕДБ, ЕМБС, име на фирма, Ж-ска, Банка, интерна шифра
            { name: 'Локација за испорака', com: 'DELIVERY_LOCATION' }, 
            { name: 'Време на испорака', com: 'DELIVERY_DATE_TIME' }, // ден, временски слот
            { name: 'Начин на плаќање', com: 'PAYMENT_METHOD' }, // во готово, на фактура
        ],
    };

    const orderDataInit = {
        full_name: '',
        phone: '',
        email: '',
        recipient_full_name: '',
        recipient_phone: '',
        recipient_email: '',
        company_name: '',
        company_taxno: '',
        company_ucin: '',
        company_bank: '',
        company_bank_account: '',
        company_code: '',
        delivery_country: 'MK',
        delivery_city: '',
        delivery_zipcode: '',
        delivery_region: '',
        delivery_address: '',
        delivery_address_number: '',
        delivery_building_entry: '',
        delivery_apartment: '',
        delivery_building_floor: '',
        delivery_additional_info: '',
        delivery_latitude: 1,
        delivery_longitude: 2,
        delivery_date: null,
        delivery_timeslot: '',
    };

    const validationsInit = {
        PERSONAL_INFO: {
            full_name: { error: '', type: 'string', required: true },
            email: { error: '', type: 'email', required: true },
            phone: { error: '', type: 'phone', required: true }
        },
        DELIVERY_LOCATION: {
            delivery_city: { error: '', type: 'string', required: true },
            delivery_zipcode: { error: '', type: 'string', required: false },
            delivery_region: { error: '', type: 'string', required: false },
            delivery_address: { error: '', type: 'string', required: true },
            delivery_address_number: { error: '', type: 'string', required: false },
            delivery_building_entry: { error: '', type: 'string', required: false },
            delivery_apartment: { error: '', type: 'string', required: false },
            delivery_building_floor: { error: '', type: 'string', required: false },
            delivery_additional_info: { error: '', type: 'string', required: false },
        },
        DELIVERY_DATE_TIME: {
            delivery_date: { error: '', type: 'none', required: true },
            delivery_timeslot: { error: '', type: 'none', required: true },
        },
        RECIEVER_INFO: {
            recipient_full_name: { error: '', type: 'string', required: true },
            recipient_phone: { error: '', type: 'phone', required: true },
            recipient_email: { error: '', type: 'email', required: false },
        },
        COMPANY_INFO: {

        },
        PAYMENT_METHOD: {

        },
    };

    const buttonLabels = {
        NEXT: 'следно',
        PAYMENT: 'плати',
        MAKE_ORDER: 'порачај'
    };

    const history = useHistory();

    const order = useSelector(state => state.shoppingCartReducer.newOrder);
    
    const [totalPrice, setTotalPrice] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const [step, setStep] = useState(0);
    const [checkoutPath, setCheckoutPath] = useState('');
    const [activeComponent, setActiveComponent] = useState('');
    const [title, setTitle] = useState('Купувам за');
    const [orderData, setOrderData] = useState(orderDataInit);
    const [validations, setValidations] = useState(validationsInit);
    const [nextButtonLabel, setNextButtonLabel] = useState(buttonLabels.NEXT);

    const products = useSelector(state => state.productsReducer);
    const cart = useSelector(state => state.shoppingCartReducer);
    const orderDataRed = useSelector(state => state.shoppingCartReducer.newOrder);

    useEffect( () => {
        (async () => {
            try {
                // let orderData = await oblax.orders.getCurrentOrder();
                // console.log(order);
                // if(orderData.ok) {
                //     setOrder(orderData.data)
                // }
            } catch(err) {
                console.log(err);
            }
        })();
    }, []);

    useEffect(() => {
        if (order && order.items) {
            setProductCount(order.items.length);
            let tp = 0;
            for(let i = 0; i < order.items.length; i++) {
                console.log(order.items[i].total_price);
                tp += Number(order.items[i].total_price);
            }
            console.log(order.items);
            console.log(tp);
            setTotalPrice(tp);
        }
    }, [order]);

    useEffect(() => {
        // console.log('step or checkoutpath changed');
        if (paths[checkoutPath] && paths[checkoutPath][step]) {
            setActiveComponent(paths[checkoutPath][step].com);
            setTitle(paths[checkoutPath][step].name)
        }

        if (paths[checkoutPath] && paths[checkoutPath].length - 1 === step) {
            console.log('paths[checkoutPath].length - 1 === step)', paths[checkoutPath].length - 1 === step);
            if (
                checkoutPath === 'self' 
                || checkoutPath === 'self-no-datetime'
                || checkoutPath === 'other-person'
                || checkoutPath === 'other-person-no-datetime'
            ) {
                setNextButtonLabel(buttonLabels.PAYMENT);
            } else if (checkoutPath === 'company') {
                setNextButtonLabel(buttonLabels.MAKE_ORDER);
            }
        } else {
            setNextButtonLabel(buttonLabels.NEXT);
        }
        // console.log('step, checkoutPath', step, checkoutPath);
    }, [step, checkoutPath]);

    useEffect(() => {
        if (orderData.delivery_city === 'Skopje') {
            console.log('delivery in skopje');
            if (checkoutPath === 'self-no-datetime') setCheckoutPath('self');
            if (checkoutPath === 'other-person-no-datetime') setCheckoutPath('other-person');
            // setButtonLabel();
        } else {
            console.log('delivery in another city');
            if (checkoutPath === 'self') setCheckoutPath('self-no-datetime');
            if (checkoutPath === 'other-person') setCheckoutPath('other-person-no-datetime');
            // setButtonLabel();
        }
    }, [orderData.delivery_city]);

    const backToProducts = () => {
        history.push('/');
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const onBtnNextClick = async () => {
        // console.log('click next');
        // console.log('paths[checkoutPath].length', paths[checkoutPath].length);
        // console.log('step', step);

        if (!validate(activeComponent)) return;

        if (paths[checkoutPath] && paths[checkoutPath].length - 1 > step) {
            setStep(step + 1);
        }

        if (paths[checkoutPath].length - 1 === step) {
            // console.log('SUBMIT FORM!');
            console.log(orderData);
            // console.log(props);
            // console.log(products);
            // console.log(cart);
            console.log(orderDataRed);
            try {
                let order = new Order();
                order.loadFromJSON(orderData);
                let res = await oblax.orders.updateOrder(orderDataRed.id, order);
                if(res.ok) {
                    if (!props.user || (!props.auth.isAuthenticated && props.auth.authUser === null)) {
                        // pu [potential user]
                        let name = data.name.split(" ");
                        localStorage.setItem("pu", JSON.stringify({
                            first_name: name[0],
                            last_name: name[1],
                            email: data.email,
                            delivery_address: data.delivery_address,
                            delivery_address_number: data.delivery_address_number,
                            delivery_additional_info: data.delivery_additional_info
                        }));
                    }
                }
                window.location.href = `${process.env.REACT_APP_API_URL}/v1/payment/cpay/checkout/${orderDataRed.id}?api_key=${process.env.REACT_APP_API_KEY}`;
            } catch(err) {
                console.log(err);
                // 
            }
        }
    };
    
    const onBtnPreviousClick = () => {
        // console.log('click previous');
        if(step > 0) {
            setStep(step - 1);
        } else if(step === 0) {
            setActiveComponent('');
            setStep(0);
            setCheckoutPath('');
        }
    };

    const validate = (section) => {
        let count = 0;
        for(let f in validations[section]) {
            // console.log('required', validations[section][f].type, f, validations[section][f].required);
            let err = '';
            switch (validations[section][f].type) {
                case 'string':
                    if (validations[section][f].required && (!orderData[f] || orderData[f] === undefined || orderData[f] === null)) err = 'Empty';
                    if (err.length === 0 && typeof orderData[f] !== 'string') err = 'Wrong format';
                    setValidations(state => {
                        return { ...state, [section]: { ...state[section], [f]: { ...state[section][f], error: err } } };
                    });
                    if(err.length > 0) count++;
                break;
                case 'email':
                    if (validations[section][f].required && (!orderData[f] || orderData[f] === undefined || orderData[f] === null)) err = 'Empty';
                    if (err.length === 0 && orderData[f].length > 0 && !orderData[f].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) err = 'Wrong format';
                    setValidations(state => {
                        return { ...state, [section]: { ...state[section], [f]: { ...state[section][f], error: err } } };
                    });
                    if(err.length > 0) count++;
                break;
                case 'phone':
                    if (validations[section][f].required && (!orderData[f] || orderData[f] === undefined || orderData[f] === null)) err = 'Empty';
                    if (err.length === 0 && !orderData[f].toLowerCase().match(/^(070|071|072|073|074|075|076|077|078|079)[\s-/]*[0-9]{3}[\s-/]*[0-9]{3}$/)) err = 'Wrong format';
                    setValidations(state => {
                        return { ...state, [section]: { ...state[section], [f]: { ...state[section][f], error: err } } };
                    });
                    if(err.length > 0) count++;
                break;
                case 'number':
                    if (validations[section][f].required && (!orderData[f] || orderData[f] === undefined || orderData[f] === null)) err = 'Empty';
                    if (err.length === 0 && typeof orderData[f] !== 'number') err = 'Wrong format';
                    setValidations(state => {
                        return { ...state, [section]: { ...state[section], [f]: { ...state[section][f], error: err } } };
                    });
                    if(err.length > 0) count++;
                break;
                default:
                    if (validations[section][f].required && (!orderData[f] || orderData[f] === undefined || orderData[f] === null)) err = 'Empty';
                    // if (err.length === 0 && typeof orderData[f] !== 'string') err = 'Wrong format';
                    setValidations(state => {
                        return { ...state, [section]: { ...state[section], [f]: { ...state[section][f], error: err } } };
                    });
                    if (err.length > 0) count++;
                break;
            }
        }
        // ADDITIONAL VALIDATION
        let regionErr = '';
        if (orderData.delivery_city === 'Skopje' && !orderData.delivery_region) {
            regionErr = 'Empty';
            count++;
        }
        setValidations(state => {
            return { ...state, ['DELIVERY_LOCATION']: { ...state['DELIVERY_LOCATION'], delivery_region: { ...state['DELIVERY_LOCATION'].delivery_region, error: regionErr } } };
        });
        // console.log('count', count);
        // console.log(validations);
        return count === 0;
    };

    const onChange = (e) => {
        // console.log(e.target.name, e.target.value);
        // console.log(e.target.value);
        switch(e.target.name) {
            case 'delivery_city':
                // console.log('set city', e.target.value);
                let city = Cities.filter(c => c.name_latin === e.target.value);
                setOrderData({
                    ...orderData,
                    [e.target.name]: e.target.value,
                    delivery_zipcode: city.length > 0 ? `${city[0].zipcode}` : '1000',
                    delivery_region: e.target.value === 'Skopje' ? orderData.delivery_region : ''
                });
            break;
            case 'delivery_date':
                setOrderData({
                    ...orderData,
                    [e.target.name]: new Date(e.target.value)
                });
            break;
            default:
                setOrderData({
                    ...orderData,
                    [e.target.name]: e.target.value
                });
            break;
        }
    };

    return (
        <OverlayContainer onClick={backToProducts}>
                <div className="checkout-popup" onClick={stopPropagation}>
                    <div className="checkout-popup__left">
                        <header className="checkout-popup__left__header">
                            <h3 className="header-title">Производи</h3>
                            <span className="item-count">{productCount}</span>
                        </header>
                        <div className="checkout-popup__left__products-container">
                            <CheckoutProductsList orderItems={order.items}/>
                        </div>
                        <footer className="checkout-popup__left__total">
                            <span className="checkout-popup__left__total__label">Вупно</span>
                            <span className="checkout-popup__left__total__value">{totalPrice} ден.</span>
                        </footer>
                    </div>
                    <div className="checkout-popup__right">
                        <header className="checkout-popup__right__header">
                            <h3 className="header-title">{title}</h3>
                            <button className="close" onClick={backToProducts}></button>
                        </header>
                        <div className="checkout-popup__right__main-content">
                            {activeComponent === ''                     ? <CheckoutPopupStepMain data={orderData} onSetCheckoutPath={setCheckoutPath}/> : null}
                            {activeComponent === 'PERSONAL_INFO'        ? <CheckoutPopupStepPersonalInfo data={orderData} onChange={onChange} errors={validations.PERSONAL_INFO}/> : null}
                            {activeComponent === 'DELIVERY_LOCATION'    ? <CheckoutPopupStepDeliveryLocation data={orderData} onChange={onChange} errors={validations.DELIVERY_LOCATION}/> : null}
                            {activeComponent === 'DELIVERY_DATE_TIME'   ? <CheckoutPopupStepDeliveryDatetime data={orderData} onChange={onChange} errors={validations.DELIVERY_DATE_TIME}/> : null}
                            {activeComponent === 'RECIEVER_INFO'        ? <CheckoutPopupStepRecieverInfo data={orderData} onChange={onChange} errors={validations.RECIEVER_INFO}/> : null}
                            {activeComponent === 'COMPANY_INFO'         ? <CheckoutPopupStepCompanyInfo data={orderData} onChange={onChange} errors={validations.COMPANY_INFO}/> : null}
                            {activeComponent === 'PAYMENT_METHOD'       ? <CheckoutPopupStepPaymentMethod data={orderData} onChange={onChange} errors={validations.PAYMENT_METHOD}/> : null}
                        </div>
                        <footer className="checkout-popup__right__footer">
                            {
                                activeComponent !== ''
                                ? (
                                    <>
                                        <button className="checkout-popup__right__footer__btn-previous" onClick={onBtnPreviousClick}>
                                            <img src={ICON_ARROW_LEFT} />
                                            претходно
                                        </button>
                                        <ul className="checkout-popup__right__footer__steps">
                                            {paths[checkoutPath].map((v, i) => {
                                                let className = i === step ? 'active' : ''
                                                return <li key={i} className={className}></li>
                                            })}
                                        </ul>
                                        <button className="checkout-popup__right__footer__btn-next" onClick={onBtnNextClick}>
                                                {nextButtonLabel}
                                            <img src={ICON_ARROW_RIGHT} />
                                        </button>
                                    </>
                                )
                                : null
                            }
                        </footer>
                    </div>
                </div>
        </OverlayContainer>
    );
};

export default CheckoutPopup;