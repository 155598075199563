import React from "react";
import { FormattedMessage } from "react-intl";

const ListPaneRow = (props) => {
	return (
		<div className="tab__paragraph-container">
			<h2 className="tab-pane-title">{props.title}:</h2>
			<div className="ingridient-list">
				{Array.isArray(props.data) && props.data.length > 0 ?
					props.data.map((rowData, i) => {
						return (
							<div className="meta-item-group" key={i}>
								<h2>
									<FormattedMessage id={rowData.toUpperCase()} />
								</h2>
							</div>
						)
					}) : "Нема информации за овој производ"}
			</div>
		</div>
	)
}

export default ListPaneRow;