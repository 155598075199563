import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "../helper";

// Component Imports
import  ProductImage  from "./ProductImage";
import QuantityPicker from "./QuantityPicker";
import ProductTitle from "./shared/ProductTitle";


const CartItem = (props) => {


	/**
    * Checks if product is on promotion do display promotional price. If not it switches to regular price
	*/

	const PriceValue = (product) => {
		let data;
		if (product.promotion_price !== null) {
			data = Math.trunc(product.promotion_price);
		} else {
			data = Math.trunc(product.price);
		}
		return data;
	}

	/**
    * Checks if product is on promotion do display promotional price. If not it switches to regular price
	*/

	const ProductStatus = () => {
		let data;

		switch (props.availability) {
			case "UNAVAILABLE":
				data = "не е достапен";
				break;
			case "LIMITED":
				data = "лимитирана залиха";
				break;
			default: data = "грешка при прикажување"
		}

		return data;
	}

		const _incrementItem = (e) => {
			e.preventDefault();
			e.stopPropagation();
			props.increment(props.data);
		}

		const _decrementItem = (e) => {
			e.preventDefault();
			e.stopPropagation();
			props.decrement(props.data);
		}

		const _removeCartItem = (e) => {
			e.preventDefault();
			e.stopPropagation();
			props.removeProduct(props.countInfo);
		}

		return (

			!isEmpty(props.data)

				?

				<Link onClick={props.hideCart} to={{ pathname: `/product/${props.data.id}`, state: { fromCart: true } }} className="cart__item-container" draggable="false">
					<div className="remove__cart-item">
						{
							props.showIncrement

								?

								<button aria-label="remove-item" onClick={_removeCartItem} className="small-button--rounded">
									<span className="remove__icon"></span>
								</button>

								:

								null
						}
					</div>

					<div className="cart__product-img">
						<ProductImage image={props.data.images} />
					</div>

					<div className="cart__product-info">
						<div className="cart__product--top">
							<div className="title-status">
								<ProductTitle
									class="product__title"
									name={props.data.name}
									insertBreak={false}
								/>
								<span className="status status-disclaimer">{ProductStatus(props.data)}</span>
							</div>
							<h4 className="product__subtitle">{props.data.description}</h4>
						</div>
						<div className="cart__product--bottom">
							<div className="product__quantity">
								<QuantityPicker
									items={props.countInfo.item_count !== undefined ? props.countInfo.item_count : props.countInfo}
									price={PriceValue(props.data)}
									currency={"mkd"}
									changeItemNumber={props._changeItemNumber}
									addItem={_incrementItem}
									removeItem={_decrementItem}
									showIncrement={props.showIncrement}
								/>
							</div>
						</div>
					</div>
				</Link>

				:

				null
		)

}

export default CartItem;
