import React from 'react';
import { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Animated } from "react-animated-css";

const OrderError =  (props) =>{

    let[showP, setShowP] = useState(true); 
    let history = useHistory();

    useEffect(()=> {
        if(props.location.pathname === "/fail"){
            setTimeout(() => {
                setShowP(false);
            }, 5000);
        }
    },[])

    useEffect(() => {
        history.push("/");
    },[showP]);

    const HideErrorPopup =() =>{
        setShowP(false);
    }
    return(
        <div className={"product__popup trigger-resize"} id="processing__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP}>
					<div className="product__popup--overlay" onClick={HideErrorPopup}></div>
				</Animated>
				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" isVisible={showP}>
					<div className="product__popup-main payment">
						<div className="transaction-popup--block transaction-processing">
							<div className="transaction--fail">
								<div className="fail"></div>
								<h2>Нарачката е неуспешна. Обидете се повторно подоцна.</h2>
							</div>
						</div>
					</div>
				</Animated>
			</div >

    );
}

export default OrderError
