import React from "react";

export class ScrollToTop extends React.Component {
	
	componentDidMount() {
		document.querySelector("." + this.props.element).scrollTop = "0px";
	}

	render(){
		return null;
	}
}