const initialState = {
	slideDown: false
}

const trackOrder = (state = initialState, action) => {
	switch(action.type) {
		case "SLIDE_DOWN":
			return {
				...state,
				slideDown: !state.slideDown
			}
		case "SLIDE_DOWN--MOBILE":
			return {
				...state,
				slideDown: false
			}
		default: return state;
	}
}

export default trackOrder;