import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

// Component imports
import  UserDropdown  from "./shared/UserDropdown";
import Avatar from './Avatar';

// Actions
import { showDropdown, closeDropdown } from "../actions/DropdownsAction";
import { slideDownTrackMobile } from "../actions/TrackOrderAction";
// import NotificationsDropdown from "../components/shared/NotificationsDropdown";

const User = (props) => {

	const trackOrder = useSelector(state => state.trackOrder.slideDown);
	const openedDropdown = useSelector(state => state.dropdownReducer.opened);
	const dropdownType = useSelector(state => state.dropdownReducer.dropdownType);
	const dispatch = useDispatch();

	const scrollToTop = () => {

		window.scrollTo({top:0, behavior: 'smooth'});
	};

		return(
			<div className="user__corner">
				<div className="dropdowns">
					<ul>
						<li>
							<NavLink activeClassName="menu__item--active" to="/favorites" onClick={scrollToTop}>
								<span title="Вашите омилени производи" className="user__corner-favorites icon-button--naked user__corner-favorites-icon">favorites</span>
							</NavLink>

							<hr className="icon__divider" />
						</li>

						{/* <li onBlur={ () => dispatch(slideDownTrackMobile()) } tabIndex="0">
							<button aria-label="track-order" title="Пратете го текот на вашата нарачка" onClick={props.trackOrder} className="user__corner-track-order icon-button--naked">
								<span className="user__corner-track-order-icon">track order</span>
							</button>  

							<hr className="icon__divider" />
						</li> */}

						  {/* <li id="notifications-li" onBlur={ () => dispatch(closeDropdown("notifications") )} tabIndex="0">
							<button aria-label="notifications" onClick={ props.notificationsDropdown } title="Сите гласини и промоции" className="user__corner-notifications icon-button--naked" >
								{
									notifications.length <= 0 

									?

									null

									:

									<div className="icon__counter--round">{notifications.length}</div>
								}
								<span className="user__corner-notifications-icon">notifications</span>
							</button>
						</li>
						<NotificationsDropdown show={ openedDropdown } type={ dropdownType } />   */}
					</ul>
				</div>
				<div className="avatar" title="Вашето корисничко катче" onBlur={ () => dispatch(closeDropdown("settings")) } tabIndex="0" >
					<div onClick={ props.userSettingsDropdown } className="avatar__button">
						<figure className="avatar__image-holder">
							<Avatar src={props.user.picture} />
						</figure>
						<h2>{props.user.first_name} {props.user.last_name}</h2>
					</div>
					<UserDropdown logout={props.logout} show={ openedDropdown } type={ dropdownType } />
				</div>

			</div>
		)
}


export default User;