export const showAuthPopup = (data) => {
	return {
		type: "AUTH_OPEN_POPUP",
		values: data,
	}
}

export const hideAuthPopup = (data) => {
	return {
		type: "AUTH_CLOSE_POPUP",
		values: data,
	}
}

export const isLoggedIn = (data) => {
    return {
        type: 'IS_LOGGED_IN',  
        payload: data
    }
}