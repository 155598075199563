import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { ScrollToTop } from "./ScrollToTop";
import { ArrayIsEmpty } from "../helper";
import { useHistory } from "react-router";

import bc from "../callsetter";
import { CartItem } from "@oblax/sdk-js";

const ListPopup = (props) => {

	const [showP, setShowP] = useState(true);
	const [listName, setListName] = useState("");
	const [showListDropdown, setShowListDropdown] = useState(false);
	const [chosenListItem, setChosenListItem] = useState({ name: "Празна листа", id: 1 });
	const [emptyCart, setEmptyCart] = useState({ name: "Празна листа", id: 1 });
	const [defaultCart, setDefaultCart] = useState({ name: "Основна кошничка", id: 2 });
	const [continueDefaultCart, setContinueDefaultCart] = useState(true);

	const history = useHistory();

	/**
	* Handles value of name input
	*/

	const handleListNameChange = (e) => {
		setListName(e.target.value);
	};

	/**
	* Handles value of the checkbox for default cart
	*/

	const handleCheckbox = () => {
		setContinueDefaultCart(!continueDefaultCart);
	};

	/**
	* Close the list popup
	*/

	const closePopup = () => {
		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);
	};

	/**
	* Display the list dropdown
	*/

	const handleShowListDropdown = () => {
		setShowListDropdown(!showListDropdown);
	};

	/**
	* Hides the list dropdown
	*/

	const hideListDropdown = () => {
		setShowListDropdown(false);
	};

	/**
	* On click the item from the list is chosen as an option
	*/

	const chooseListItem = (item) => {
		setChosenListItem(item);
		handleShowListDropdown();
	};

	/**
	* Switch between emtpy list and default cart in the list dropdown
	*/

	const switchEmptyDefault = () => {
		setEmptyCart(!emptyCart);
		setDefaultCart(!defaultCart);
		handleShowListDropdown();
	};

	/**
	* Creates list based on users' choice (Default cart or list products)
	*/

	const createList = () => {
		return bc.cart.createList(listName)
			.then((params) => {
				if(params.ok) {
					return params;
				}
			})
			.catch((err) => {
				console.error("Error creating List", err);
				return false;
			})
	};

	/**
	* Add cart items to newly created list
	*/

	const addCartItemsToList = (arr, scid) => {
		var emptyArr = [];

		for(let i in arr) {
			let ci = new CartItem();
			ci.loadFromJSON({...arr[i], cart_id: scid});
			emptyArr.push(bc.cart.addCartItem(scid, ci));
		}

		return Promise.all(emptyArr)
			.then((res) => { 
				res.forEach(r => { 
					props.updateCartItemsList(r.data);
				});
			})
			.catch((err) => {
				console.error("Error", err);
			});
	};

	const createListMaster = () => {
		createList()
			.then((listData) => {
				if(chosenListItem.id === 1) {
					props.updateListItems(listData.data);
					addCartItemsToList([], listData.data.id);
					if(!continueDefaultCart){
						props.createCart(listData.data);
						props.chooseCart(listData.data);
						props.clearCart();
					}
				} else if (chosenListItem.id === 2) {
					getListItems()
						.then((listItems) => {
							props.updateListItems(listData.data);
							props.clearCart();
							addCartItemsToList(listItems, listData.data.id);
							if(!continueDefaultCart){
								props.createCart(listData.data);
								props.chooseCart(listData.data);
							} else {
								props.clearCart();
								props.createCart(props.defaultCart);
								props.chooseCart(props.defaultCart);
								props.getCartItems();
							}
						});
				} else {
					getListItems()
						.then((listItems) => {				
							props.updateListItems(listData.data);
							props.chooseCart(props.defaultCart);
							props.clearCart();
							addCartItemsToList(listItems, listData.data.id);
							if(!continueDefaultCart){
								props.createCart(listData.data);
								props.chooseCart(listData.data);
							} else {
								props.clearCart();
								props.getCartItems();
							}
						});
				}
				closePopup()
			 })
			 .catch((err) => {
				 console.error("Error Creating list (from master)", err);
			 })
	}

	const getListItems = () => {
		if(chosenListItem.id === 1) {
			return;
		} else if(chosenListItem.id === 2) {
			return bc.cart.getCartItems(props.defaultCart.id)
				.then((params) => {
					if(params.ok) {
						return params.data;
					}
				})
				.catch((err) => {
					console.error("Error fetching cart items from list!", err);
					return false;
				});
		} else {
			return bc.cart.getCartItems(chosenListItem.id)
				.then((params) => {
					if(params.ok) {
						return params.data;
					}
				})
				.catch((err) => {
					console.error("Error fetching cart items from list!", err);
					return false;
				});
		}
		
	};

		return(
			<div className="product__popup confirmation__popup" >
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
					<div onClick={closePopup} className="product__popup--overlay"></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className={"product__popup-container " + (showListDropdown ? "dropdown-overflow" : "")} animationIn="zoomIn" animationOut="fadeOut" isVisible={showP}>

					{ !showListDropdown ? <ScrollToTop element="product__popup-container" /> : null }
					
					<div className="popup__header">
						<div className="popup__header--block">
							<h2>Креирајте нова листа</h2>
						</div>

						<div className="popup__header--block">
							<button aria-label="cancel" onClick={closePopup} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div className="popup__content">
						<p className="input-holder">
							<label className="label__info" htmlFor="cart_name">име на листата</label>
							<input 
								type="text" 
								className="label__input" 
								id="cart_name"
								onChange={handleListNameChange}
								value={listName}
								placeholder="Пример: Лична хигиена"
							/>
						</p>

						<h2 className="popup__disclaimer">Сакате да ја креирате листата со:</h2>

						<div className="cart-choices">
							<div onBlur={hideListDropdown} tabIndex="0" className="single-choice dropdown-choice">
								<label className="label__info" htmlFor="exisitinglist">производи од основната кошничка, празна листа или постоечка листа</label>
								<div className="dropdown-main-src">
									<div onClick={handleShowListDropdown} className="dropdown__trigger">
										<h2>
											{
												(chosenListItem.id === 1 || chosenListItem.id === 2)

												?

												<span className={chosenListItem.id === 1 ? "empty-icon-dropdowned" || chosenListItem.id === 1 : "cart-icon-dropdowned"}></span>

												:

												null
											}
											{chosenListItem.name}
										</h2> 
									</div>
									<div className={"dropdown__content " + (showListDropdown ? "show--dropdown " : "hide--dropdown ")} id="exisitinglist">
										<ul>
											<h2 onClick={() => chooseListItem(emptyCart)} className="dropdown-disclaimer"><span className="empty-icon-dropdowned"></span>Празна листа</h2>
											<h2 onClick={() => chooseListItem(defaultCart)} className="dropdown-disclaimer spacing"><span className="cart-icon-dropdowned"></span>Основна кошничка</h2>
											<hr className="dropdown-divider" />
											{
												ArrayIsEmpty(props.lists)

												?
												
												props.lists.map(list => {
													return (
														<li onClick={() => chooseListItem(list)} key={list.id}>
															<h2>{list.name}</h2>											
														</li>																	
													)
												})

												:

												<h2 className="dropdown-empty">Немате креирано листи...</h2>
											}
										</ul>
									</div>
								</div>
							</div>

							<div className="single-choice">
								<h2 className="label__info">дали сакате да продолжите да работите со основната кошничка?</h2>
								<p className="input-holder">
									<input 
										type="checkbox"
										value={continueDefaultCart}
										checked={continueDefaultCart}
										onChange={handleCheckbox}
										className="checkbox default-cart"
										id="checkfordefaultcart"
									/>
									<label className="label__info" htmlFor="checkfordefaultcart">да</label>
								</p>
							</div>
						</div>
					</div>

					<div className="popup__buttons">
						<button aria-label="cancel" className="button__ghost--primary" onClick={closePopup}>Откажи</button>
						<button aria-label="create" disabled={listName === ""} className="button--primary" onClick={createListMaster}>Креирај</button>
					</div>
				</Animated>
			</div>
		)
}

export default ListPopup;
