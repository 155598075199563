import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { isEmpty } from "../../helper";

// Components
import  MobileNotifications  from "../MobileNotifications";
import  MobileUser  from "../MobileUser";
import  MobileTrackOrder  from "./MobileTrackOrder";
import  UserDropdownMobile  from "../UserDropdownMobile";

// Actions
import { openMenu, closeMenu } from "../../actions/MenuActions";
import { slideDownTrack } from "../../actions/TrackOrderAction";
import { showDropdown } from "../../actions/DropdownsAction";
import { showAuthPopup } from "../../actions/userAuthAction";
import { ScrollToTop } from "../ScrollToTop";

const Menu = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const dropdownsOpened = useSelector(state => state.dropdownReducer.opened);
	const dropdownsType = useSelector(state => state.dropdownReducer.dropdownType);
	const mobileTrackOrder = useSelector(state => state.trackOrder.slideDown);
	const menuOpened = useSelector(state => state.menuReducer.opened);
	const isAuthenticated = useSelector(state => state.authPopup.auth.isAuthenticated);
	const authUser = useSelector(state => state.authPopup.auth.authUser);
	const user = useSelector(state => state.userReducer.me);

	const handleShowNotifications = (type) => {
		dispatch(showDropdown(type));
	};

	const handleCloseMenu = () => {
		dispatch(closeMenu());
	};

	const _gotoFavorites = () => {
		handleCloseMenu();
		history.push("/favorites");
	};

	const _gotoTrackOrder = () => {
		dispatch(slideDownTrack());

		if (dropdownsOpened) {
			handleShowNotifications("mobile_notifications");
		} else {
			return;
		}
	};

	const _gotoNotifications = () => {
		handleShowNotifications("mobile_notifications");

		if (mobileTrackOrder) {
			dispatch(slideDownTrack());
		} else if (dropdownsOpened && dropdownsType === "mobile_usersettings") {
			handleShowNotifications("mobile_notifications");
		}
	};

	const _gotoUserSettings = () => {
		handleShowNotifications("mobile_usersettings");

		if (mobileTrackOrder) {
			dispatch(slideDownTrack());
		} else if (dropdownsOpened && dropdownsType === "mobile_notifications") {
			handleShowNotifications("mobile_usersettings");
		}
	};

	const _goToSettings = () => {
		history.push("/settings");
		handleCloseMenu();
	};

	const _goToOrderHistory = () => {
		history.push("/order-history");
		handleCloseMenu();
	};

	const _goToStatistics = () => {
		history.push("/statistics");
		handleCloseMenu();
	};

	const linearEase = (currentIteration, startValue, changeInValue, totalIterations) => {
		return changeInValue * currentIteration / totalIterations + startValue;
	}
	const easeOutCubic = (currentIteration, startValue, changeInValue, totalIterations) => {
		return changeInValue * (Math.pow(currentIteration / totalIterations - 1, 3) + 1) + startValue;
	}

	const _scrollToCategory = (id, excess) => {
		history.push("/");
		handleCloseMenu();

		if (location.pathname !== "/") {
			var selectObj = document.getElementById(id);
			let bbox = selectObj.getBoundingClientRect();
			setTimeout(() => {
				window.scrollTo({
					// top: document.documentElement.scrollTop + bbox.top,
					top: bbox.top - 90,
					behavior: 'smooth'
				});

			}, 100);
		} else {
			setTimeout(() => {
				var selectObj = document.getElementById(id);
				let bbox = selectObj.getBoundingClientRect();
				window.scrollTo({
					// top: document.documentElement.scrollTop + bbox.top,
					top: bbox.top - 90,
					behavior: 'smooth'
				});

			}, 100);
		}
	};


	const ScrollToSubCategory = () => {
		_scrollToCategory();
	};

	const _goToLogin = () => {
		history.push("/login");
		handleCloseMenu();
	};

	const _goToRegister = () => {
		history.push("/register");
		handleCloseMenu();
	};

	const CloseMenuHome = () => {
		handleCloseMenu();
		history.push("/");
	};

		return (
			<div className={"menu " + (menuOpened ? "menu--show" : "menu--hide")}>
				<div className="menu__header">
					<div onClick={handleCloseMenu} className="menu__header--item  close-menu">
						<button aria-label="close-menu" className="close__popup-button icon-button--naked"></button>
					</div>

					{
						isAuthenticated && authUser !== null ?

							<MobileView renderWithFragment>
								<div className="mobile__menu-corner">
									<MobileUser
										user={user}
										settings={_gotoUserSettings}
										notifications={_gotoNotifications}
										trackOrder={_gotoTrackOrder}
										favorites={_gotoFavorites}
									/>
								</div>
							</MobileView> :

							<MobileView viewClassName="menu-action-buttons">
								<div className="header__login">
									<button aria-label="register" className="button__ghost--primary register-button" onClick={_goToRegister}>
										<span className="header__register-button icon-button--naked">register регистрирај се</span>
										<FormattedMessage id={"REGISTER"} />
									</button>
								</div>

								<div className="header__login">
									<button aria-label="login" className="icon-button--primary" onClick={_goToLogin}>
										<span className="header__login-button icon-button--naked">log in логирај се</span>
										<FormattedMessage id={"LOGIN"} />
									</button>
								</div>
							</MobileView>
					}

					<BrowserView renderWithFragment>
						<div className="header__login">
							<button aria-label="log-in" className="icon-button--primary" onClick={props.openTour}>
								<span className="header__help-button icon-button--naked">log in</span>
								Помош
								</button>
						</div>
					</BrowserView>
				</div>

				{
					mobileTrackOrder && !dropdownsOpened

						?

						<div className="menu__user-action--content">
							<div className="mobile__track-order">
								<MobileView renderWithFragment>
									<MobileTrackOrder />
								</MobileView>
							</div>
						</div>

						:

						null
				}

				{
					(dropdownsOpened && dropdownsType === "mobile_notifications") && !mobileTrackOrder

					 	?

						<MobileView renderWithFragment>
							<div className="menu__user-action--content">
								<div className="mobile__notifications">
									<MobileNotifications showOnMobile={dropdownsOpened} dropdownType={dropdownsType} />
								</div>
							</div>
						</MobileView>
						:

						null
				}


				{
					(dropdownsOpened && dropdownsType === "mobile_usersettings") && !mobileTrackOrder

						?

						<MobileView renderWithFragment>
							<div className="menu__user-action--content">
								<div className="mobile__usersettings">
									<UserDropdownMobile
										logout={props.logout}
										goToSetings={_goToSettings}
										goToOrderHistory={_goToOrderHistory}
										goToStatistics={_goToStatistics}
										user={user}
									/>
								</div>
							</div>
						</MobileView>
						:

						null
				}

				{
					!mobileTrackOrder && !dropdownsOpened

						?

						<div className="menu__content">

							{menuOpened ? <ScrollToTop element={"menu__content"} /> : null}

							<div>
								<div className="menu__content--block intro">
									<h2><FormattedMessage id={"WELCOME_PH"} /></h2>
									<span><FormattedMessage id={"WELCOME_PH_SUBTEXT"} /></span>
								</div>
								<div className="menu__content--block categories">
									<div className="categories__header">
										<h2><FormattedMessage id={"CATEGORIES"} /></h2>
									</div>
									<BrowserView renderWithFragment>
										<ul className="web-categories">
											{
												!isEmpty(props.categories)

													?

													props.categories
														.sort((a, b) => a.order - b.order)
														.filter(cat => cat.name !== "" && cat.hasOwnProperty("products") && Array.isArray(cat.products) && cat.products.length > 0)
														.map(c => {
															return (
																<li onClick={() => _scrollToCategory(c.id, 85)} key={c.id}>
																	<a>{c.name}</a>
																</li>
															)
														})

													:

													null
											}
										</ul>
									</BrowserView>

									<MobileView renderWithFragment>
										<ul className="mobile-categories">
											{
												!isEmpty(props.categories)

													?


													props.categories
														.sort((a, b) => a.order - b.order)
														.filter(c => c.hasOwnProperty("products") && Array.isArray(c.products) && c.products.length > 0)
														.map(c => {
															return (
																<li onClick={() => _scrollToCategory(c.id, 61)} key={c.id}>
																	<a>{c.name}</a>
																</li>
															)
														})

													:

													null
											}
										</ul>
									</MobileView>
								</div>
							</div>
						</div>

						:

						null
				}
			</div>
		)
}

export default Menu;
