export const getAllMainCategories = (categories) => {
	return {
		type: "GET_ALL_MAIN_CATEGORIES",
		payload: categories
	}
};

export const getAllCategories = (categories) => {
	return {
		type: "GET_ALL_CATEGORIES",
		payload: categories
	}
};

export const getCategoryById = (category) => {
	return {
		type: "GET_CATEGORY_BY_ID",
		payload: category
	}
};