import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const UserDropdownMobile = (props) => {

		return (
			<div className={"dropdown-src user-dropdown "}>
				<div className="track-order__header">
					<h2>{props.user.first_name} {props.user.last_name}</h2>
				</div>
				<div className="notifications-container">
					<ul>
						<li onClick={ props.goToSetings } className="notifications__list--settings">
							<NavLink className="dropdown-item" to="/settings/basic-info">
								<span></span>
								<FormattedMessage id={"SETTINGS"} /> 
							</NavLink>
						</li>

						<li onClick={ props.goToStatistics }  className="notifications__list--statistics">
							<NavLink className="dropdown-item" to="/statistics">
								<span></span>
								<FormattedMessage id={"STATISTICS"} />
							</NavLink>
						</li>

						<li onClick={ props.goToOrderHistory } className="notifications__list--order-history">
							<NavLink className="dropdown-item" to="/order-history">
								<span></span>
								<FormattedMessage id={"ORDER_HISTORY"} />
							</NavLink>
						</li>

						<li onClick={ props.logout } className="notifications__list--logout">
							<NavLink className="dropdown-item" to="/">
								<span className="dropdown-item"></span>
								<FormattedMessage id={"LOG_OUT"} />
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		)
}

export default UserDropdownMobile;
