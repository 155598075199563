export const showSearch = () => {
	return {
		type: "SHOW_SEARCH"
	};
}

export const hideSearch = () => {
	return {
		type: "HIDE_SEARCH"
	};
}

export const fillSearchValue = (value) => {
	return {
		type: "FILL_SEARCH_VALUE",
		payload: value
	}
}

export const clearSearchValue = () => {
	return {
		type: "FILL_SEARCH_VALUE"
	}
}
