import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, NavLink, withRouter, useHistory, useLocation } from "react-router-dom";
import { Animated } from "react-animated-css";
import { FormattedMessage } from "react-intl";

// Component Imports
import  BasicInfo  from "./BasicInfo";
import { ScrollToTop } from "./ScrollToTop";
import Footer from "./shared/Footer";
import Locations from "./Locations";
import LinkedProfiles from "./LinkedProfiles";
import LocationPopup from "./LocationPopup";
import Toast from "./shared/Toast";
import  ChangePasswordPopup  from "./ChangePasswordPopup";
import BasicInfoPopup from "./BasicInfoPopup";
import Avatar from './Avatar';

import bc from "../callsetter";
import { Location, User } from "@oblax/sdk-js";

const Settings = (props) => {

	const history = useHistory();

	const initBasicInfo = {
		first_name: "",
		last_name: "",
		phone: ""
	};

	const initChangePasswordData = {
		old_password: "",
		new_password: "",
		confirm_password: ""
	};

	const [theLocation, setTheLocation] = useState({});
	const [showP, setShowP] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastCode, setToastCode] = useState("");
	const [toastType, setToastType] = useState("");
	const [basicInfo, setBasicInfo] = useState(initBasicInfo);
	const [changePasswordData, setChangePasswordData] = useState(initChangePasswordData);
	const [buttonLoader, setButtonLoader] = useState(false);

	let location = useLocation();
	let previousLocation = location;

	/**
	* Show chosen location in LOCATION popup
	*/

	const _showMap = (location) => {
		setTheLocation(location);
		setShowP(true);
	};

	/**
	* Handle onChange event on location inputs
	*/

	const _handleLocationChange = (e) => {
		setTheLocation({...theLocation, [e.target.name]: e.target.value});
	};

	/**
	* Show location popup
	*/

	const _showEmptyPopup = () => {
		history.push("/settings/locations/new");
		setTheLocation({});
		setShowP(true);
	};

	/**
	* Add new location from LOCATION popup
	*/

	const _addLocation = (newLocation) => {
		let loc = new Location();

		loc.loadFromJSON({
			account_id: props.user.id,
			label: newLocation.label,
			continent: "EU",
			country: "MK",
			state: "",
			zipcode: "1000",
			city: "Skopje",
			region: newLocation.region,
			address: newLocation.address,
			address_number: newLocation.address_number,
			longitude: 0,
			latitude: 1
		});

		bc.users.addUserLocation(props.user.id, loc)
			.then((params) => {
				if (params.ok) {
					setToastMessage("Успешно внесување на нова локација.");
					setShowToast(true);
					setToastCode("green");
					setToastType("newlocationsuccess");
					setTheLocation({});
					setShowP(false);
					props.getLocations();
				}
			})
			.catch((err) => {
				setToastMessage("Грешка при внесување на нова локација. Пополнете ги сите полиња и обидете се повторно.");
				setShowToast(true);
				setToastCode("red");
				setToastType("newlocationerror");
			});
	};

	/**
	* Remove location from LOCATION popup
	*/

	const _removeLocation = (location) => {
		bc.users.deleteUserLocation(props.user.id, location.id)
			.then((params) => {
				if (params.ok) {
					props.getLocations();
					setShowP(false);
					setToastMessage("Успешно бришење на одбраната локација.");
					setShowToast(true);
					setToastCode("green");
					setToastType("locationdeletesuccess");
				}
			})
			.catch((err) => {
				setShowP(false);
				setToastMessage("Грешка при бришење на одбраната локација.");
				setShowToast(true);
				setToastCode("red");
				setToastType("locationdeleteerror");
			})
	};

	/**
	* Update location from LOCATION popup
	*/

	const _updateLocation = (location, locationId) => {
		let loc = new Location();

		loc.loadFromJSON({
			id: locationId,
			continent: "EU",
			country: "MK",
			zipcode: "1000",
			city: "Skopje",
			label: "DEFAULT_LOCATION",
			region: location.region,
			address: location.address,
			address_number: location.address_number
		});

		return bc.users.updateUserLocation(props.user.id, locationId, loc)
			.then((params) => {
				if (params.ok) {
					setToastMessage("Успешна промена на избраната локација.");
					setShowToast(true);
					setToastCode("green");
					setToastType("newlocationsuccess");
					setTheLocation({});
					setShowP(false);
					props.getLocations();
				}
			})
			.catch((err) => {
				console.error("Error location save", err);
				setToastMessage("Грешка при внесување на нова локација. Пополнете ги сите полиња и обидете се повторно.");
				setShowToast(true);
				setToastCode("red");
				setToastType("newlocationerror");
				setShowP(false);
			});
	};

	const UpdateDeliveryLocation = (location, locationId) => {
		let loc = new Location();

		loc.loadFromJSON({
			id: location.id,
			account_id: props.user.id,
			label: location.label,
			continent: "EU",
			country: "MK",
			state: location.state,
			zipcode: "1000",
			city: "Skopje",
			region: location.region,
			address: location.address,
			address_number: location.address_number,
			longitude: location.longitude,
			latitude: location.latitude
		});

		return bc.users.updateUserLocation(props.user.id, locationId, loc)
			.then((params) => {
				if (params.ok) {
					setToastMessage("Успешна промена на избраната локација.");
					setShowToast(true);
					setToastCode("green");
					setToastType("newlocationsuccess");
					setTheLocation({});
					setShowP(false);
					props.getLocations();
				}
			})
			.catch((err) => {
				console.error("Error location save", err);
				setToastMessage("Грешка при внесување на нова локација. Пополнете ги сите полиња и обидете се повторно.");
				setShowToast(true);
				setToastCode("red");
				setToastType("newlocationerror");
				setShowP(false);
			});
	};


	/**
	* Hide location popup
	*/

	const hideMap = (location) => {
		setShowP(false);
		setTimeout(() => {
			history.push(location);
		}, 100);
	};

	/**
    *  Hide toast after finished render of message
    */

	const hideToast = () => {
		setTimeout(() => {
			setShowToast(false);
		}, 4000);
	};

	/**
    *  Upload Avatar
    */

	const uploadAvatar = (e) => {
		e.persist();
		bc.users.uploadImage(e.target.files[0])
			.then((params) => {
				if (params.ok) {
					setToastMessage("Успешно прикачување на аватар.");
					setShowToast(true);
					setToastCode("green");
					setToastType("avataruploadsuccess");
				}
				updateUserData(e);
			})
			.catch((err) => {
				console.error(err);
				setToastMessage( "Грешка при прикачување на аватар. Обидете се повторно.");
				setShowToast(true);
				setToastType("avataruploaderror");
			})
	};

	/**
    *  Update user data
    */

	const updateUserData = (e) => {
		let user = new User();
		user.loadFromJSON({
			id: props.user.id,
			first_name: props.user.first_name,
			last_name: props.user.last_name,
			email: props.user.email,
			gender: props.user.gender,
			phone: props.user.phone,
			picture: e.target.files[0].name
		});

		bc.users.updateUserData(props.user.id, user)
			.then((params) => {
				if (params.ok) {
					props.getUser();
					setToastMessage("Успешна промена на вашите информации.");
					setShowToast(true);
					setToastCode("green");
					setToastType("basicinfosavesuccess");
					setShowP(false);
				}
			})
			.catch((err) => {
				console.error("Error", err);
			})
	};

	const updateUserDataBasic = (data) => {
		let user = new User();

		user.loadFromJSON({
			id: props.user.id,
			first_name: data.first_name,
			last_name: data.last_name,
			email: props.user.email,
			gender: props.user.gender,
			phone: data.phone
		});

		bc.users.updateUserData(props.user.id, user)
			.then((params) => {
				if (params.ok) {
					props.getUser();
					setToastMessage("Успешна промена на вашите информации.");
					setShowToast(true);
					setToastCode("green");
					setToastType("basicinfosavesuccess");
					setShowP(false);
				}
			})
			.catch((err) => {
				console.error("Error", err);
				setToastMessage("Грешка при промена на вашите информации. Обидете се повторно.");
				setShowToast(true);
				setToastCode("green");
				setToastType("basicinfosaveerror");
			})
	};

	/**
    *  Show a prefilled popup to save users' basic info
    */

	const showBasicInfoPopup = (e) => {
		e.persist();
		history.push("/settings/basic-info/edit");
		setShowP(true);
		setBasicInfo({
			...basicInfo,
			first_name: props.user.first_name,
			last_name: props.user.last_name,
			phone: props.user.phone
		});
		// console.error("SETTINGS_STATE", this.state); 
	};

	/**
    *  Show a prefilled popup to save users' basic info
    */

	const changePassword = () => {
		history.push("/settings/basic-info/change-password");
	};

	/**
    *  Reset Password
    */

	const ResetPassword = (data) => {
		setButtonLoader(true);

		let password_data = {
			old_password: data.old_password,
			new_password: data.new_password,
			confirm_password: data.confirm_password
		}

		return bc.users.changePassword(props.user.id, password_data)
			.then((res) => {
				if (res.ok) {
					history.push("/settings/basic-info/");
					setToastMessage("Успешна промена на вашата лозинка.");
					setShowToast(true);
					setToastCode("green");
					setToastType("loginchangesuccess");
					setShowP(false);
					setButtonLoader(false);
				}
			})
			.catch((err) => {
            	console.error("TCL: Settings -> ResetPassword -> err", err);
				history.push("/settings/basic-info/");
				if (err.data.type === "AUTH_WRONG_CREDENTIALS") {
					setToastMessage("Внесовте погрешна стара лозинка. Обидете се повторно.");
					setShowToast(true);
					setToastCode("red");
					setToastType("loginchangeerror");
					setShowP(false);
					setButtonLoader(false);
				} else {
					setToastMessage("Грешка при промена на вашата лозинка. Обидете се повторно.");
					setShowToast(true);
					setToastCode("red");
					setToastType("basicinfosaveerror");
					setShowP(false);
					setButtonLoader(false);	
				}

			});
	};

	/**
    *  Handle the input fields of change password form
    */

	const handleChangePassword = (e) => {
		e.persist();
		setChangePasswordData({
			...changePasswordData,
			[e.target.name]: e.target.value
		});
		DisabledChangePasswordInput();
	};

	const DisabledChangePasswordInput = () => {
		if (changePasswordData.new_password.length <= 7 || changePasswordData.old_password === "" || changePasswordData.confirm_password === "") {
			return true;
		} else {
			return false;
		}
	};

	const HidePopupAfterLocationSuccess = () => {
		hideMap("/settings/locations");
	};

	const HidePopupAfterInfoSuccess = () => {
		hideMap("/settings/basic-info");
	};

		const isModal = !!(
			location.state &&
			location.state.modal &&
			previousLocation !== location
		);

		return (
			<div id="settings">

				<ScrollToTop element="app-container" />

				<Animated className="single-page-wrapper" animationIn="fadeIn faster">
					<div className="info__navigation--left">
						<div className="settings__avatar">
							<figure className="settings__avatar__image-holder">
								<Avatar src={props.user.picture} />
							</figure>
							<h2>{props.user.first_name} {props.user.last_name}</h2>
							<a href={`mailto:${props.user.email}`}>{props.user.email}</a>
						</div>

						<div className="subpage-nav">
							<ul>
								<li className={location.pathname.indexOf("/settings/basic-info") === 0 ? "active-settings-chevron" : ""}>
									<NavLink activeClassName="active-settings" to="/settings/basic-info"><FormattedMessage id={"BASIC_INFO"} /></NavLink>
								</li>
								<li className={location.pathname.indexOf("/settings/locations") === 0 ? "active-settings-chevron" : ""}>
									<NavLink activeClassName="active-settings" to="/settings/locations"><FormattedMessage id={"DELIVERY_LOCATIONS"} /></NavLink>
								</li>
								{/* <li className={location.pathname.indexOf("/settings/notifications") === 0 ? "active-settings-chevron" : ""}>
									<NavLink activeClassName="active-settings" to="/settings/notifications"><FormattedMessage id={"NOTIFICATIONS"} /></NavLink>
								</li> */}
								<li className={location.pathname.indexOf("/settings/linked-profiles") === 0 ? "active-settings-chevron" : ""}>
									<NavLink activeClassName="active-settings" to="/settings/linked-profiles"><FormattedMessage id={"LINKED_PROFILES"} /></NavLink>
								</li>
							</ul>
						</div>
					</div>
					<div className="settings__content--right">
						<Switch>
							<Route path="/settings/locations" render={(routeProps) => {
								return (
									<div>
										{
											routeProps.match.path === "/settings" || isModal

												?

												null :

												<Locations
													showEmptyPopup={_showEmptyPopup}
													showMap={(item) => _showMap(item)}
													data={props.userLocation}
													userLocation={props.userLocation}
													{...routeProps}
												/>
										}

										{
											routeProps.location.pathname !== "/settings/locations/new"

												?

												<Route path="/settings/locations/:id" render={(routeProps) => {
													return (
														<LocationPopup
															{...routeProps}
															data={theLocation}
															handleChange={_handleLocationChange}
															removeLocation={(location) => _removeLocation(location)}
															updateLocation={UpdateDeliveryLocation}
															showPopupBool={showP}
															hideMap={HidePopupAfterLocationSuccess}
															userLocation={props.userLocation}
														/>
													)
												}} />

												:

												null
										}

										<Route path="/settings/locations/new" render={(routeProps) => {
											return (
												<LocationPopup
													{...routeProps}
													data={theLocation}
													handleChange={_handleLocationChange}
													addNewLocation={_addLocation}
													showPopupBool={showP}
													hideMap={HidePopupAfterLocationSuccess}
													userLocation={props.userLocation}
												/>
											)
										}} />
									</div>
								)
							}} />
						</Switch>

						<Switch>
							<Route path="/settings/basic-info" render={(routeProps) => {
								return (
									<div>
										{
											routeProps.match.path === "/settings" || isModal

												?

												null

												:

												<BasicInfo
													{...routeProps}
													user={props.user}
													userLocation={props.userLocation}
													uploadAvatar={uploadAvatar}
													showBasicInfoPopup={showBasicInfoPopup}
													changePassword={changePassword}
												/>
										}

										<Route path="/settings/basic-info/change-password" render={() => {
											return (
												<ChangePasswordPopup
													{...props}
													userId={props.user.id}
													changePasswordData={changePasswordData}
													resetPassword={ResetPassword}
													handleChangePassword={handleChangePassword}
													buttonLoader={buttonLoader}
													DisabledChangePasswordInput={DisabledChangePasswordInput}
												/>
											)
										}} />

										<Route path="/settings/basic-info/edit" render={(routeProps) => {
											return (
												<BasicInfoPopup
													{...routeProps}
													data={props.userLocation.filter(l => { return l.label === "DEFAULT_LOCATION" })}
													basicInfo={basicInfo}
													handleChange={_handleLocationChange}
													showPopupBool={showP}
													hideMap={HidePopupAfterInfoSuccess}
													updateLocation={_updateLocation}
													updateUserData={updateUserDataBasic}
												/>
											)
										}} />
									</div>
								)
							}} />
						</Switch>

						{/* <Route exact path="/settings/notifications" render={(routeProps) => {
							return (
								<Notifications
									{...routeProps}
									user={props.user}
									auth={props.auth}
									userPreferences={props.userPreferences}
									getUserPreferences={props.getUserPreferences}
								/>
							)
						}} /> */}
						<Route exact path="/settings/linked-profiles" render={(routeProps) => {
							return (
								<LinkedProfiles
									{...routeProps}
									user={props.user}
									userProfiles={props.userProfiles}
								/>
							)
						}} />
					</div>

					<Footer pages={props.pages} openTour={props.openTour} />
				</Animated>

				{
					showToast

						?

						<Toast
							show={showToast}
							type={toastType}
							message={toastMessage}
							code={toastCode}
							hideToast={hideToast}
						/>

						:

						null
				}
			</div>
		)
}

export default Settings;