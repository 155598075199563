import React from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// Component Imports
import SinglePage from "./SinglePage";
import Contact from "./Contact";
import { ScrollToTop } from "./ScrollToTop";

import { ArrayIsEmpty } from "../helper";

const Help = (props) => {

	const history = useHistory();

	/**
    * Animating the Menu to scroll to respective Raft
	*/

	const scrollToTop = () => {

		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	/**
    * Gets user to Contact form in the Help Section
	*/
	const GoToContact = () => {

		scrollToTop();
		history.push("/help/contact");
	}

		return (
			<div id="help">
				{
					ArrayIsEmpty(props.pages)
					?
					<div className="single-page-wrapper">
						<ScrollToTop element="app-container" />

						<div className="help-nav">
							<ul>
								{
									props.pages.map(page => {
										return (
											<li key={page.id} onClick={ scrollToTop }>
												<NavLink activeClassName="active-helper" to={"/help/" + page.permalink}>{page.title}</NavLink>
											</li>
										)
									})
								}
								<button aria-label="contact" className="icon-button--primary contact-button" onClick={GoToContact}>
									<span className="contact-button-icon icon-button--naked"></span>
									<FormattedMessage id={"CONTACT"} />
								</button>
								{/* <li onClick={ this.scrollToTop }>
									
									<NavLink activeClassName="active-helper" to="/help/contact">Контакт</NavLink>
								</li> */}
							</ul>
						</div>

						<div className="help-container-switcher">
							<Switch>
								{
									props.pages.map(page => {
										return (
											<Route key={page.id} path={"/help/" + page.permalink} render={ () => {
												return (
													<SinglePage data={page} />
												)
											} } />
										)
									})
								}
								<Route path="/help/contact" component={ Contact } />
							</Switch>
						</div>
					</div>

					:

					<div className="spinner-container">
						<div className="centered-spinner">
							<div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
							<h2>Ја вчитуваме содржината</h2>
						</div>
				</div>
				}
			</div>
		)
}

export default Help;
