import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { isEmpty, ArrayIsEmpty } from "../helper";
import { withRouter } from "react-router-dom";

// Actions
import { hideSavedCartsDropdown, chooseCart } from "../actions/SaveCartAction";


const SavedCarts = (props) => {

	const showSavedCartsDropdown = useSelector(state => state.saveCartReducer.showSavedCartsDropdown);
	const lists = useSelector(state => state.shoppingCartReducer.lists);
	const chosenCart = useSelector(state => state.saveCartReducer.chosenCart);

	return (
		<div id="saved-carts" onBlur={hideSavedCartsDropdown} tabIndex="0">
			<div className="dropdown__container">
				<div className="trigger-content">
					<div onClick={ props.showSavedCarts } className="dropdown__trigger">
						{ 
							isEmpty(chosenCart)
							
							? 
								
							<h2>Вашите листи</h2>
							
							: 
							
							<h2>
								{chosenCart.name === "Основна кошничка" ? <span className="cart-icon-dropdowned"></span> : null}
								{chosenCart.name}
							</h2> 
						}
					</div>

					<div className="trigger-buttons">
						<button aria-label="create-list" onClick={props.goToCreateList} className="create-list icon-button--naked">
							<span></span>
						</button>
					</div>
				</div>

				<div className={"dropdown__content " + (showSavedCartsDropdown ? "show--dropdown" : "hide--dropdown")}>
					<ul>
						<h2 onClick={props.goToDefaultCart} className="dropdown-disclaimer"><span className="cart-icon-dropdowned"></span>Основна кошничка</h2>

						{
							ArrayIsEmpty(lists)

							?

							lists.map(list => {
								return (
									<li onClick={ () => props.chooseList(list) } key={list.id}>  
										<h2>{list.name}</h2>
										<button aria-label="delete" onClick={(event) => props.askDeleteListConfirmation(event, list)} className="remove-list icon-button--naked"><span></span></button>
									</li>
								)
							})

							:

							<h2 className="dropdown-empty">Немате креирано листи...</h2>
						}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default SavedCarts;
