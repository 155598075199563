import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { withRouter , useHistory } from "react-router-dom";

// Actions
import { hideAuthPopup, showAuthPopup } from "../actions/userAuthAction";

const LoginPopup = (props) => {

	const[email, setEmail] = useState("");
	const[password, setPassword] = useState("");
	const[showP, setShowP] = useState(true);
	const[user, setUser] = useState({
		email: "", 
		password: "",
	});
	const[showToast, setShowToast] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const _registerPopup = () => {
		dispatch(showAuthPopup("register"));
		history.push("/register");
	};

	const _closePopup = () => {
		
		setShowP(false);
		setTimeout(() => {
			dispatch(hideAuthPopup("login"));
			history.push("/");
		}, 100)
	};

	// Handle onChange values on login inputs
	const loginOnChange = (e) => {
		setUser({...user, [e.target.name] : e.target.value });
	};

	// login

	const login = (e) => {
		e.preventDefault();
		props.login(user.email, user.password);
	};

	const openRecoverPasswordPopup = () => {
		setShowP(false);
		props.openRecoverPasswordPopup();
	};

		const { intl } = props;
		const inputPlaceholder = defineMessages({
			e_mail: {
				id: "E_MAIL",
			},
			password: {
				id: "PASSWORD",
			}
		});

	return (
		<div className={"product__popup"} id="login__popup">
			<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
				<div className="product__popup--overlay" onClick={() => _closePopup()}></div>
			</Animated>

			<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP} >
				<div className="product__popup--full">
					<div className="section__favorite">
						<h2 className="auth__popup-title"><FormattedMessage id={"LOGIN"} /></h2>
					</div>

					<div className="section__close">
						<button aria-label="cancel" onClick={() => _closePopup()} className="close__popup-button icon-button--naked"></button>
					</div>
				</div>

				<div className="product__popup-main">
					<div className="popup__block social-login">
						<a href={`${process.env.REACT_APP_API_URL}/v1/auth/link/facebook/${process.env.REACT_APP_API_KEY}`} className="social-login__button slb__facebook">
							<span></span>
							<FormattedMessage id={"FACEBOOK_LOGIN"} />
						</a>
						<a href={`${process.env.REACT_APP_API_URL}/v1/auth/link/google/${process.env.REACT_APP_API_KEY}`} className="social-login__button slb__google">
							<span></span>
							<FormattedMessage id={"GOOGLE_LOGIN"} />
						</a>
					</div>

					<hr className="popup__section-divider" />

					<div className="popup__block popup__inputs">
					<form onSubmit={ login }>
						<p>
							<input 
								type="email"
								name="email"
								className={"placeholder__input " + (props.badLogin ? "bad-input" : "")} 
								placeholder={intl.formatMessage(inputPlaceholder.e_mail)}
								onChange={loginOnChange}
								value={user.email}
							/>
						</p>
						<p>
							<input 
								type="password" 
								name="password"
								className={"placeholder__input " + (props.badLogin ? "bad-input" : "")} 
								placeholder={intl.formatMessage(inputPlaceholder.password)}
								onChange={loginOnChange}
								value={user.password}
							/>
						</p>

						{
							props.badLogin 

							?

							<p className="bad-login">
								Грешка при најава. Неточен или непостоечки e-mail и password. Обидете се повторно.
							</p>

							:

							null
						}

						<div className="checkbox__label recovery">
							<div className="input-entry">
								<input type="checkbox" className="checkbox" id="remember" />
								<label htmlFor="remember"><FormattedMessage id={"REMEMBER_ME"} /></label>
							</div>

							<div className="input-entry">
								<button aria-label="recover-password" onClick={props.openRecoverPasswordPopup} type="button" className="recovery-link">Ја заборавивте вашата лозинка?</button>
							</div>
						</div>
						<p>
							<button 
								type="submit" 
								className="full-width__button--primary" 
								onClick={ (event) => login(event, email, password) }
								name="log-in"
							>	
								{
									props.buttonLoader

									?

									<div className="button-loader">
										<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div>

									:

									<FormattedMessage id={"LOGIN"} />

								}
							</button>
						</p>
						<p>
							<button aria-label="register" onClick={ () => _registerPopup() } className="full-width__button--ghost-primary"><FormattedMessage id={"REGISTER"} /></button>
						</p>
						</form>
					</div>
				</div>
			</Animated>
		</div>
	)

}

export default injectIntl(LoginPopup);
