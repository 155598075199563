import React, { useState, useEffect, useRef} from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { NavLink, withRouter, useHistory, useLocation } from 'react-router-dom';
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

// Components
import  User  from "../User";
import  MobileSearch  from "./MobileSearch";

// Actions
import { openMenu } from "../../actions/MenuActions";
import { slideDownTrack, slideDownTrackMobile } from "../../actions/TrackOrderAction";
import { showDropdown, closeDropdownMobile } from "../../actions/DropdownsAction";
import { showCart, hideCart } from "../../actions/ShowCartAction";
import { hidePaymentPopup } from "../../actions/PaymentAction";
import { showSearch, hideSearch } from "../../actions/SearchAction";
import { Animated } from "react-animated-css";

const Header = (props) => {

	const inputRef = useRef();
	const searchShow = useSelector(state => state.searchReducer.show);
	const cartData =  useSelector(state => state.shoppingCartReducer.cartItems);
	const isAuthenticated = useSelector(state => state.authPopup.auth.isAuthenticated);
	const authUser = useSelector(state => state.authPopup.auth.authUser);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if(inputRef) {
			if(searchShow) {
				inputRef.current.focus();
			}
			// } else {
			// 	inputRef.current.blur();
			// }
		}
	})

	/**
    * Displays the search in the mobile version on magnifyng class icon click
	*/

	const handleShowSearch = (e) => {
		e.stopPropagation();
		dispatch(showSearch());
		dispatch(hideCart());
	}

	/**
    * Hides the search in the mobile version on magnifyng class icon click
	*/

	const handleHideSearch = (e) => {
		dispatch(hideSearch());
	}

	/**
    * Hides all open popups and returns home
	*/

	const goHome = () => {
		dispatch(hideCart());
		dispatch(hidePaymentPopup());
	}

	/**
    * Hides all popups and slides the menu
	*/

	const _openMenu = (e) => {
		e.preventDefault();
		dispatch(hideCart());
		dispatch(hidePaymentPopup());
		dispatch(closeDropdownMobile());
		dispatch(slideDownTrackMobile());
		dispatch(openMenu());
	}

	/**
    * Returns the user home
	*/

	const backHome = () => {
		history.push("/");
		window.scrollTo({top:0, behavior: 'smooth'});
	}

	/**
    * Opens the register popup
	*/

	const _registerPopup = () => {
		props.openLoginPopup("register"); 
		history.push("/register");
	}

	const handleOpenMenu = () => {
		dispatch(openMenu());
	}

	const handleShowCart = () => {
		dispatch(showCart());
	}

	const handleSlideDownTrack = () => {
		dispatch(slideDownTrack());
	}

	const handleShowDropdownSettings = (data) => {
		dispatch(showDropdown("settings"));
	}

	const handleShowDropdownNotifications = (data) => {
		dispatch(showDropdown("notifications"));
	}

		const { intl } = props;
		const inputPlaceholder = defineMessages({
			search: {
				id: "SEARCH_RAFT_PH",
			},
		});

		return (
			<header className="header">

				<BrowserView renderWithFragment>
					<div className="header-container">
						<div className="header__section-left">
							<div onClick={handleOpenMenu} className="header__item header__menu"> 
								<div>
									<span className="header__menu-icon">...</span>
									<h2>мени</h2>
								</div>
							</div>

							<div className="header__item header__logo">
								<NavLink to="/">
									raft рафт raft prodavnica
								</NavLink>

								{location.pathname === "/settings" ||
									location.pathname === "/favorites" ||
									location.pathname === "/statistics" ||
									location.pathname === "/success" ||
									location.pathname === "/fail" ||
									location.pathname.indexOf("/help") === 0 ||
									location.pathname.indexOf("/settings") === 0 ||
									location.pathname.indexOf("/order-history") === 0 ? <div onClick={backHome} className="home"></div> : null}
							</div>

							{location.pathname === "/" || location.pathname === "/favorites" || location.pathname.indexOf("/product") === 0 || location.pathname.indexOf("/reset-password") === 0 || location.pathname.indexOf("/save-cart") === 0 || location.pathname === "/login" || location.pathname === "/register" || location.pathname.indexOf("/cart") === 0 || location.pathname.indexOf("/delete-list") === 0 || location.pathname === "/create-list" || location.pathname === "/session-expired" || location.pathname === "/success" || location.pathname === "/fail" ?

								<div className="header__item header__search">
									<input
										value={props.searchValue}
										onChange={(event) => props.handleSearch(event)}
										type="search"
										placeholder={intl.formatMessage(inputPlaceholder.search)}
									/>
									<button aria-label="search" title="Пребарај" className="header__search-button icon-button--naked"></button>
								</div> : null}
						</div>

						<div className="header__section-right">

							{isAuthenticated && authUser !== null ?
								<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeIn fast">
									<User
										trackOrder={handleSlideDownTrack}
										userSettingsDropdown={handleShowDropdownSettings}
										notificationsDropdown={handleShowDropdownNotifications}
										logout={props.logout}
										user={props.user}
									/>
								</Animated> :
								<Animated style={{animationDuration:"0.2s"}} className="header__login" animationIn="fadeIn" animationOut="fadeIn fast">
									<button aria-label="register" className="button__ghost--primary register-button" onClick={_registerPopup}>
										<span className="header__register-button icon-button--naked">register регистрирај се</span>
										<FormattedMessage id={"REGISTER"} />
									</button>
									<button aria-label="log-in" className="icon-button--primary" onClick={props.openLoginPopup}>
										<span className="header__login-button icon-button--naked">log in логирај се</span>
										<FormattedMessage id={"LOGIN"} />
									</button>
								</Animated>}

						</div>
					</div>
				</BrowserView>

				<MobileView renderWithFragment>
					<div className="mobile__header">
						<div className="menu__home">
							<div onClick={_openMenu} className="header__item header__menu">
								<div>
									<span className="header__menu-icon">...</span>
									<h2><FormattedMessage id={"MENU"} /></h2>
								</div>
							</div>

							{location.pathname === "/order-history" ||
								location.pathname === "/settings" ||
								location.pathname === "/favorites" ||
								location.pathname === "/statistics" ||
								location.pathname.indexOf("/settings") === 0 ? <div onClick={backHome} className="home"></div> : null}
						</div>

						<div onClick={goHome} className="header__item header__logo">
							<NavLink to="/">
								raft рафт raft prodavnica
							</NavLink>
						</div>

						<div className="header__section-right">

							{location.pathname === "/" || location.pathname === "/favorites" || location.pathname === "/create-list" ?
								<span className={"search-button-container " + (props.searchedItems ? "filtered-data" : "")}>
									<button aria-label="show-search" onClick={handleShowSearch} className={"mobile__header-search icon-button--naked " + (searchShow ? "active" : "")}></button>
									{props.searchedItems ? <span className="dot"></span> : null}
								</span> : null}

							<div onClick={handleShowCart} className="cart--number-icon">
								<button aria-label="show-cart" className="mobile__header-cart icon-button--naked"></button>
								{cartData.length <= 0 ? null : <span>{cartData.length}</span>}
							</div>
						</div>
					</div>
				</MobileView>

				<MobileView renderWithFragment>
					<MobileSearch
						clear={props.clearSearchField}
						searchValue={props.searchValue}
						handleSearchValue={(event) => props.handleSearch(event)}
						hideSearch={handleHideSearch}
						inputRef={inputRef}
					/>
				</MobileView>
			</header>
		)
}

export default injectIntl(Header);
