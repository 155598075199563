import { useState } from 'react';

import './style.css';

const Input = (props) => {
    let error = props.error ? 'error' : '';
    let className = props.className ? props.className : '';
    return (
        <label className={`form-input ${error} ${className}`}>
            <span className="form-input__input-label">{props.label}</span>
            <input 
                className="form-input__input-field" 
                type="text" 
                name={props.name} 
                onChange={props.onChange} 
                data-validate={props.validate}
                value={props.value}
                placeholder={props.placeholder}
            />
        </label>
    );
};

export default Input;