export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const ArrayIsEmpty = (arr) => {
	if(arr === undefined || arr.length === 0) {
		return false;
	}
	return true;
}