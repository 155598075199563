const initialState = {
	showCart: false
}

const showCartReducer = (state = initialState, action) => {
	switch(action.type) {
		case "SHOW_CART":
			return {
				...state,
				showCart: !state.showCart,
			}
		case "HIDE_CART":
			return {
				...state,
				showCart: false
			}
		default: return state;
	}
}

export default showCartReducer;