import { useSelector } from 'react-redux';

import ProductImage from "../../ProductImage";

const CheckoutProductsList = (props) => {

    const products = useSelector(state => state.productsReducer.products);
    const productsMap = useSelector(state => state.productsReducer.productsMap);

    return (
        <ul className="checkout-products-list">
            {props.orderItems && props.orderItems.map(i => {
                let product = products[productsMap[i.product_id]];
                return (
                    <li className="checkout-products-list__item" key={i.product_id}>
                        <div className="checkout-products-list__item__image">
                            <ProductImage image={product.images} />
                        </div>
                        <div className="checkout-products-list__item__text">
                            <h5 className="checkout-products-list__item__text__name">{product.name}</h5>
                            <span className="checkout-products-list__item__text__description">{product.description}</span>
                            <span className="checkout-products-list__item__text__quantity-price">{i.price}ден. &times; {i.quantity} = <span className="orange">{i.total_price} ден.</span></span>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default CheckoutProductsList;