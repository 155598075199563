const initialState = {
	favorites: [],
}

const favoritesReducer = (state = initialState, action) => {
	switch(action.type) {	
		case "FETCH_FAVORITES" : 
			return {
				...state,
				favorites: action.payload
			}
		case "REMOVE_FAVORITED_ITEM" : 
			return {
				...state,
				favorites: state.favorites.filter((favorite) => {
					return favorite.id !== action.payload
				})
			}
		default: return state;
	}
}

export default favoritesReducer;