import React from "react";
import { connect, useSelector } from "react-redux";

import { showDropdown } from "../actions/DropdownsAction";

const MobileNotifications = (props) => {

		const NOTIFICATIONS = [];
		const showNotifications = useSelector(state => state.dropdownReducer.opened);
		const typeNotifications = useSelector(state => state.dropdownReducer.dropdownType);
		

		return (
			<div className={"dropdown-src notifications-dropdown" + ( (props.showOnMobile && props.dropdownType === "mobile_notifications") ? "show--dropdown" : "hide--dropdown")} id="notifications">
				<div className="dropdown__header">
					<div className="dropdown__header-item">
						<h2>Нотификации</h2>
						{
							NOTIFICATIONS.length <= 0

							?

							null

							:

							<span className="notification__count">{ NOTIFICATIONS.length }</span>
						}
					</div>

					<div className="dropdown__header-item">
						{
							NOTIFICATIONS.length <= 0

							?

							null

							:

							<button aria-label="clear" className="clear__button">исчисти</button>
						}
					</div>
				</div>
				<div className="notifications-container">
					{
						NOTIFICATIONS.length === 0

						?

						<div id="empty-state">
							<div className="empty-cart__container">
								<div className="empty-state-icon">
									<span></span>
								</div>
								<h2>Вашите нотификации ќе се појават тука</h2>
							</div>
						</div>

						:

						<ul>
							{
								NOTIFICATIONS.map((notification) => {
									return (
										<li key={ notification.id }>
											<h2>{ notification.title }</h2>
											<span>{ notification.date }</span>
										</li>
									)
								})
							}
						</ul>
					}
				</div>
			</div>
		)

}


export default MobileNotifications;
