import React from "react";
import { FormattedMessage } from "react-intl";
import placeholder from "../assets/images/user_avatar_placeholder.svg";
import { isEmpty } from "../helper";

import Avatar from './Avatar';

const BasicInfo = (props) => {
		return(
			<div className="settings-container settings-children-container" id="settings--basic-info">
				<div>
					<div className="settings__content--block">
						<div className="settings__content--header">
							<div className="category-title">
								<a>
									<span className="chevron"></span>
									<h2><FormattedMessage id={"BASIC_INFO"} /></h2>
								</a>
							</div> 

							<div className="settings__content-block--actions">
								<button aria-label="edit" onClick={props.showBasicInfoPopup} className="button__ghost--primary full-width-mobile-button">
									<span className="edit-basic-info--button icon-button--naked">raft kasa</span>
									<FormattedMessage id={"EDIT"} />
								</button>
							</div>
						</div>

						<div className="settings__content--main">
							<p>
								<label className="label__info" htmlFor="settings__email"><FormattedMessage id={"NAME_AND_LASTNAME"} /></label>
								<span className="label__info-src">{props.user.first_name} {props.user.last_name}</span>
							</p>
							<p className="button-holder">
								<label className="label__info" htmlFor="settings__password"><FormattedMessage id={"CHANGE_PASSWORD"} /></label>
								<button aria-label="change-password" onClick={props.changePassword} className="button__ghost--primary">
									<span className="edit-basic-info--button icon-button--naked">raft kasa</span>
									<FormattedMessage id={"CHANGE_PASSWORD"} />
								</button>
							</p>

							<p>
								<label className="label__info" htmlFor="settings__password"><FormattedMessage id={"TELEPHONE"} /></label>
								<span className="label__info-src">{props.user.phone !== "" ? props.user.phone : "/"}</span>
							</p>

							<p>
								<label className="label__info" htmlFor="address"><FormattedMessage id={"ADDRESS"} /></label>
								<span className="label__info-src">{
									!isEmpty(props.userLocation) 
									
									?

									props.userLocation.filter(location => location.label === "DEFAULT_LOCATION").map(l => {
										return (
											l.address !== "" ? l.address + (" ") + l.address_number : "/"
										)
									})

									:

									null
								}</span>
							</p>

							<p>
								<label className="label__info" htmlFor="settings__email"><FormattedMessage id={"CITY"} /></label>
								<span className="label__info-src">{
									!isEmpty(props.userLocation) 
									
									?

									props.userLocation.filter(location => location.label === "DEFAULT_LOCATION").map(l => {
										return (
											l.city
										)
									})

									:

									null
								}</span>
							</p>

							<p>
								<label className="label__info" htmlFor="settings__password"><FormattedMessage id={"REGION"} /></label>
								<span className="label__info-src">{
									!isEmpty(props.userLocation) 
									
									?

									props.userLocation.filter(location => location.label === "DEFAULT_LOCATION").map(l => {
										return (
											l.region !== "" ? l.region : "/"
										)
									})

									:

									null
								}</span>
							</p>

							<div className="file-upload__holder">
								<label className="label__info" htmlFor="settings__avatar"><FormattedMessage id={"AVATAR"} /></label>
								<span className="file-upload__button-container">
									<span className="inside__avatar--holder">
										<figure className="settings__avatar__image-holder">
											<Avatar src={props.user.picture} />
										</figure>
									</span>
									<input onChange={props.uploadAvatar} type="file" className="file__input" id="settings__avatar"/>
									<label className="label__info" htmlFor="settings__avatar"><FormattedMessage id={"CHOOSE_PHOTO"} /></label>
								</span>
							</div>
						</div>		
					</div>
				</div>
			</div>
		)
}

export default BasicInfo;
