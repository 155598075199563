import React from "react";

const MetaMeasurment = (props) => {
	const mappings = {
		'food': 'food_measurement',
		'beverage': 'beverage_measurement',
		'cosmetics': 'cosmetics_measurement',
		'personal_hygiene': 'personal_hygiene_measurement',
		'cleaning': 'cleaning_measurement'
	};

	const measurements = {
		'g': 'g',
		'kg': 'kg',
		'l': 'L',
		'ml': 'ml',
		'pieces': 'парч.',
	};

	const getMeasurement = () => {
		let unit = props.meta[mappings[props.type]].unit.toLowerCase().trim();
		let value = props.meta[mappings[props.type]].value;
		return (
			<span className={unit === 'l' ? 'litars' : ''}>{value} {measurements[unit]}</span>
		)
	}

	return (
		<div className="meta-measurment">
			{getMeasurement()}
		</div>
	)
}

export default MetaMeasurment;