import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { MONTHS } from "../constants/index";
import { FormattedMessage } from "react-intl";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Component Imports
import ReactEcharts from 'echarts-for-react';
import  Footer  from "./shared/Footer";
import { ScrollToTop } from "./ScrollToTop";
import  ProductImage  from "./ProductImage";

import "moment/locale/mk";

// Actions
import { setStatisticsFilter } from "../actions/UserAction";
import { Animated } from "react-animated-css";


const Statistics = (props) => {

	const statisticsFilterMonth = useSelector(state => state.userReducer.statisticsFilter.month);
	const statisticsFilterYear = useSelector(state => state.userReducer.statisticsFilter.year);
	const seasonType = useSelector(state => state.userReducer.statisticsFilter.seasonType);
	const pages = useSelector(state => state.pagesReducer.pages);
	const userData = useSelector(state => state.userReducer.me);

	const dispatch = useDispatch();

	const handleSetStatisticsFilter = (seasonType, month, year) => {
		dispatch(setStatisticsFilter(seasonType, month, year));
	};

	/**
    * Toggle between Monthly and Yearly statistics
 	*/

	const toggleStatsTab = (filterType) => {
		if(filterType === "monthly") {
			handleSetStatisticsFilter("monthly", parseInt(statisticsFilterMonth, 0), parseInt(statisticsFilterYear, 0));
			props.fetchStatistics("month", statisticsFilterMonth, statisticsFilterYear);
		} else if(filterType === "yearly") {
			handleSetStatisticsFilter("yearly", parseInt(statisticsFilterMonth, 0), parseInt(statisticsFilterYear, 0));
			props.fetchStatistics("year", statisticsFilterMonth, statisticsFilterYear);
		}
	};

	/**
    * Month onChange
	*/
	 
	const monthFilterOnChange = (e) => {	
		handleSetStatisticsFilter("monthly", e.target.value, statisticsFilterYear);
		props.fetchStatistics("month", parseInt(e.target.value, 0), statisticsFilterYear);
	};

	/**
    * Year onChange
	*/
	 
	const yearFilterOnChange = (e) => {
		if(seasonType === "monthly") {
			handleSetStatisticsFilter("monthly", statisticsFilterMonth, parseInt(e.target.value, 0));
			props.fetchStatistics("month", parseInt(statisticsFilterMonth, 0), parseInt(e.target.value, 0));
		} else {
			handleSetStatisticsFilter("yearly", parseInt(statisticsFilterMonth, 0), parseInt(e.target.value, 0));
			props.fetchStatistics("year", null, parseInt(e.target.value, 0));
		}
	};

	/**
    * Format array into a drawable form for the MAIN chart [BAR]
	*/
	 
	const getDataTotalValue = () => {
		if (!isEmpty(props.statistics)) {
			var result = Object.keys(props.statistics.orders).map(function(key) {
				return props.statistics.orders[key];
			});

			var longest_key = result.reduce(function(maxI,el,i,arr) {return el.length>arr[maxI].length ? i : maxI;}, 0);
			var d = [];
			let colors = ['#f3a683','#f7d794','#778beb','#e77f67','#cf6a87','#786fa6','#f8a5c2','#63cdda','#303952', '#546de5','#c44569'];

			for(var i = 0; i < result[longest_key].length; i++) {
				var order = {
					name: "",
					type: "bar",
					stack: "asdasd",
					areaStyle: {normal: {}},
					data: [],
					color: colors[i],
				};
				for (var j = 0; j < result.length; j++) {
					if (result[j][i] !== undefined && !isEmpty(props.statistics.orders)) {
						order.data.push(result[j][i].total);
					} else {
						order.data.push(0);
					}
				}
				d.push(order);
			} 
			return d;
		}
	};

	const getDataDates = () => {
		if (!isEmpty(props.statistics) && !isEmpty(props.statistics.orders)) {
			var result = Object.keys(props.statistics.orders).map(function(key) {
				return props.statistics.orders[key];
			});
			var dates = [];
			for (var j = 0; j < result.length; j++) {
				dates.push(j+1);
			}
			return dates;
		}
	};

	const getEmptyDataDates = (month, year) => {
		let num = new Date(year, month, 0).getDate();
		let days = [];

		for(var i = 1; i <= num; i++) {
			days.push(i);
		}
		return days;
	};

	const BarChartOptions = () => {
		return {
			tooltip : {
				trigger: 'axis'
			},         
			grid: {
				left: '0%',
				right: '0%',
				bottom: '20%',
				containLabel: true
		  	},
			xAxis : [
				{
					type : 'category',
					boundaryGap : true,
					data: getDataDates()
				}
			],
			yAxis : [
				{
					type : 'value'
				}
			],
			series : !isEmpty(props.statistics.orders) ? getDataTotalValue() : []
		};
	};

	const EmptyBarChartOptions = () => {
		return {
			tooltip : {
				trigger: 'axis'
			},         
			grid: {
				left: '0%',
				right: '0%',
				bottom: '20%',
				containLabel: true
		  	},
			xAxis : [
				{
					type : 'category',
					boundaryGap : true,
					data: getEmptyDataDates(statisticsFilterMonth, statisticsFilterYear)
				}
			],
			yAxis : [
				{
					type : 'value'
				}
			],
			series : []
		};
	};

	/**
    * Format array into a drawable form for the MAIN chart [PIE]
	*/

	const getPieValuesTotalSpent = () => {
		if (!isEmpty(props.statistics)) {
			var piedata = [];
			var data = props.statistics.products.sort((a, b) => {
				if (a.total_spent > b.total_spent) {
					return -1;
				} 
				
				if (a.total_spent < b.total_spent) {
					return 1;
				}

				return 0;
			});

			if(data.length <= 10) {
				piedata = data.map(item => { return [item.name, item.total_spent]});
			} else {
				piedata = data.slice(0, 9).map(item => { return [item.name, item.total_spent]});
				var total = 0;
				for(var i = 9; i < data.length; i++) {
					total += data[i].total_spent;
				}
				piedata.push(["Останати", total]);
			}
			
			return piedata;
		}
	};

	/**
    * Format array into a drawable form for the MAIN chart [PIE]
	*/

	const getPieValuesCount = () => {
		if (!isEmpty(props.statistics)) {
			var piedata = [];
			var data = props.statistics.products.sort((a, b) => {
				if (a.count > b.count) {
					return -1;
				} 
				
				if (a.count < b.count) {
					return 1;
				}

				return 0;
			});

			if(data.length <= 10) {
				piedata = data.map(item => { return [item.name, item.count]});
			} else {
				piedata = data.slice(0, 9).map(item => { return [item.name, item.count]});
				var total = 0;
				for(var i = 9; i < data.length; i++) {
					total += data[i].count;
				}
				piedata.push(["Останати", total]);
			}
			
			return piedata;
		}
	};

	const PieChartOptions = () => {
		return {
			legend: {
				show: true,
				itemGap: 12
			},
			tooltip: {},
			dataset: {
				source: getPieValuesTotalSpent()
			},
			series: [
				{
					type: 'pie',
					radius: "60%",
					color: ['#55efc4','#81ecec','#74b9ff','#a29bfe','#dfe6e9','#ffeaa7','#fab1a0','#fd79a8','#e17055', '#2d3436','#f49f42'],
					center: ["50%", "65%"]
				}
			]
		};
	};

	const PieChartOptionsSecond = () => {
		return {
			legend: {
				show: true,
				itemGap: 12
			},
			tooltip: {},
			dataset: {
				source: getPieValuesCount()
			},
			series: [
				{
					type: 'pie',
					radius: "60%",
					color: ['#f3a683','#f7d794','#778beb','#e77f67','#cf6a87','#786fa6','#f8a5c2','#63cdda','#303952', '#546de5','#c44569'],
					center: ["50%", "65%"]
				}
			]
		};
	};


	/*-----------------------------------------------------------------------------*/
	// EMPTY CHARTS
	/*-----------------------------------------------------------------------------*/
	

	const EmptyPieChartOptions = () => {
		return {
			legend: {
				show: false
			},
			tooltip: {
				show: false
			},
			dataset: {
				source: [[1]]
			},
			series: [
				{
					type: 'pie',
					radius: "70%",
					color: ['#55efc4','#81ecec','#74b9ff','#a29bfe','#dfe6e9','#ffeaa7','#fab1a0','#fd79a8','#e17055', '#2d3436','#f49f42'],
					center: ["50%", "45%"]
				}
			]
		};
	};

	const EmptyPieChartOptionsSecond = () => {
		return {
			legend: {
				show: false
			},
			tooltip: {
				show: false
			},
			dataset: {
				source: [[1]]
			},
			series: [
				{
					type: 'pie',
					radius: "70%",
					color: ['#55efc4','#81ecec','#74b9ff','#a29bfe','#dfe6e9','#ffeaa7','#fab1a0','#fd79a8','#e17055', '#2d3436','#f49f42'],
					center: ["50%", "45%"]
				}
			]
		};
	};

		return(
			<div id="statistics">

				<ScrollToTop element="app-container" />

				<div className="single-page-wrapper order-history__wrapper">
					<div className="component__content all-products">
						<div className="component-header">
							<div className="statistics-tab">
								<MobileView renderWithFragment>
									<div className="category-title category-title-mobile">
										<a>
											<span className="chevron"></span>
											<h2><FormattedMessage id={"STATISTICS"} /></h2>
										</a>
									</div> 
								</MobileView>
								<div onClick={() => toggleStatsTab("monthly")} className={"single-tab " + (seasonType === "monthly" ? "active-stats-tab" : "")}>
									<h2><FormattedMessage id={"MONTHLY_STATS"} /></h2>
								</div>

								<div onClick={() => toggleStatsTab("yearly")} className={"single-tab " + (seasonType === "yearly" ? "active-stats-tab" : "")}>
									<h2><FormattedMessage id={"YEARLY_STATS"} /></h2>
								</div>
							</div>
						</div>

						<div className={"settings__content--block statistics " + (seasonType)}>
							<div className="settings__content--header">
								<BrowserView renderWithFragment>
									<div className="category-title">
										<a>
											<span className="chevron"></span>
											<h2><FormattedMessage id={"STATISTICS"} /></h2>
										</a>
									</div> 
								</BrowserView>

								<div className="stats-picker">
									<div className="single-stat-picker">
										<select value={statisticsFilterYear} onChange={yearFilterOnChange}>
											<option value="" disabled><FormattedMessage id={"CHOOSE_YEAR"} /></option>
											{
												userData.hasOwnProperty('_created')
													?
													Array.from(
														Array(new Date().getFullYear() - new Date(userData._created).getFullYear() + 1).keys() // check here userData
													).map(i => {
														let year = i + new Date(userData._created).getFullYear();
														return <option key={i} value={year}>{year}</option>
													})
													:
													<option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
											}
										</select>
									</div>

									{
										seasonType === "monthly"

										?

										<div className="single-stat-picker">
											<select value={statisticsFilterMonth} onChange={monthFilterOnChange}>
												<option value="" disabled><FormattedMessage id={"CHOOSE_MONTH"} /></option>
												{
													MONTHS.map((month, i) => {
														return (
															<option key={i+1} value={i+1}>{month}</option>
														)
													})
												}
											</select>
										</div>

										:

										null
									}
								</div>
							</div>

							<div className={"settings__content--main " + (isEmpty(props.statistics) ? "no-data" : "")}> 

								{
									!isEmpty(props.statistics)

									?
									
									<div className="switcher">
									
										<div className="chart monthly-chart">
											<div className="chart-disclaimer">
												<h2>Број на нарачки според месечен/годишен приказ</h2>
												<span>Овде можете да пратите колку нарачки имате направено во даден период.</span>
											</div>

											<div className="chart-src">

												{
													isEmpty(props.statistics.orders)

													?

													<Animated style={{animationDuration:"0.2s"}} className="lower-opacity" animationIn="fadeIn" animationOut="fadeOut">
														<ReactEcharts
															className="react_for_echarts"
															option={EmptyBarChartOptions()}
															style={{height: 350}}
															notMerge={true}
															lazyUpdate={true}
														/>
													</Animated>

													:

													null
												}

												{
													!isEmpty(props.statistics.orders)

													?

													<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut">
														<ReactEcharts
															className="react_for_echarts"
															option={BarChartOptions()}
															style={{height: 350}}
															notMerge={true}
															lazyUpdate={true}
														/>
													</Animated>

													:

													<div className="overlayed-statistics-disclaimer empty-data-container">
														<div className="empty-data-centered">
															<span>empty icon</span>
															<h2>Немате нарачки за овој датум.</h2>
														</div>
													</div>
												}
											</div>
										</div>

										<div className="content--two-type">
											<div className="charts">
												<div className="chart--pie first-chart">
													<div className="chart-disclaimer">
														<h2>Потрошувачка според цена</h2>
														<span>Овде можете да пратите на кој производ имате најголема потрошувачка.</span>
													</div>
													{
														!isEmpty(props.statistics.orders)

														?

														<ReactEcharts
															className="react_for_echarts_pie"
															option={PieChartOptions()}
															style={{height: 380}}
														/>

														:

														<ReactEcharts
															className="react_for_echarts_pie lower-opacity"
															option={EmptyPieChartOptions()}
															style={{height: 290}}
														/>
													}
												</div>

												<div className="chart--pie second-chart">
													<div className="chart-disclaimer">
														<h2>Потрошувачка според број</h2>
														<span>Овде можете да пратите кој производ најмногу го пазарите.</span>
													</div>
													{
														!isEmpty(props.statistics.orders)

														?

														<ReactEcharts
															className="react_for_echarts_pie"
															option={PieChartOptionsSecond()}
															style={{height: 380}}
														/>
														
														:

														<ReactEcharts
															className="react_for_echarts_pie lower-opacity"
															option={EmptyPieChartOptionsSecond()}
															style={{height: 290}}
														/>

													}
												</div>
											</div>

											<div className={"stats-grouped " + (isEmpty(props.statistics.orders) ? "trim-margin" : "")}>
												<div className="stats-grouped-item static">
													<h2 className="stat-disclaimer"><FormattedMessage id={"MOST_EXPENSIVE_PRODUCT"} /></h2>
													<div className="single-stat--block">
														{	
															!isEmpty(props.statistics.orders) && (ArrayIsEmpty(props.products) && !isEmpty(props.productsMap)) && (props.products[props.productsMap[props.statistics.most_expensive.product_id]] !== undefined)

															?
															
															<div className="visual">
																<ProductImage image={ props.products[props.productsMap[props.statistics.most_expensive.product_id]].images } />
															</div>

															:

															<div className={"if-not-loaded show-image"}>
																<span className="placeholder"></span>
															</div>
														}

														<div className="textual">
															<h2>{Math.trunc(props.statistics.most_expensive.avg_price)} <FormattedMessage id={"MKD"} /></h2>
															<h4>{props.statistics.most_expensive.name}</h4>
														</div>
													</div>
												</div>

												<div className="stats-grouped-item static top--item">
													<h2 className="stat-disclaimer"><FormattedMessage id={"CHEAPEST_PRODUCT"} /></h2>
													<div className="single-stat--block">
														{	
															!isEmpty(props.statistics.orders) && (ArrayIsEmpty(props.products) && !isEmpty(props.productsMap)) && (props.products[props.productsMap[props.statistics.least_expensive.product_id]] !== undefined)

															?

															<div className="visual">
																<ProductImage image={ props.products[props.productsMap[props.statistics.least_expensive.product_id]].images } />
															</div>

															:

															<div className={"if-not-loaded show-image"}>
																<span className="placeholder"></span>
															</div>
														}

														<div className="textual">
															<h2>{Math.trunc(props.statistics.least_expensive.avg_price)} <FormattedMessage id={"MKD"} /></h2>
															<h4>{props.statistics.least_expensive.name}</h4>
														</div>
													</div>
												</div>

												<div className="stats-grouped-item single-stat--block iconed top--item">
													<div className="visual">
														<span className="cart--icon"></span>
													</div>

													<div className="textual">
														<h2>{props.statistics.total_products}</h2>
														<h4>Производи</h4>
													</div>
												</div>

												<div className="stats-grouped-item single-stat--block iconed">
													<div className="visual">
														<span className="money--icon"></span>
													</div>

													<div className="textual">
														<h2>{Math.trunc(props.statistics.total_spent)}</h2>
														<h4><FormattedMessage id={"MKD"} /></h4>
													</div>
												</div>
											</div>
										</div>
									</div>

									:

									<div className="spinner-container">
										<div className="centered-spinner">
											<div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
											<h2>Ги вчитуваме статистиките</h2>
										</div>
									</div>
								}
							</div>
						</div>
					</div>

					<Footer pages={pages} openTour={props.openTour} />
				</div>
			</div>
		)
}

export default Statistics;
