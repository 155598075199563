import bc from "../callsetter";
import {
	CartItem
} from "@oblax/sdk-js";

/**
 * Actions Calling the ShoppingCartReducer
 */

export const createCartStart = (cart) => {
	return {
		type: "CREATE_CART",
		payload: cart
	}
}

export const getCartItemsStart = (items) => {
	return {
		type: "GET_CART_ITEMS",
		payload: items
	}
}

export const addToCartStart = (product) => {
	return {
		type: "ADD_TO_CART",
		payload: product
	}
}

export const updateCartItemStart = (product) => {
	return {
		type: "UPDATE_CART_ITEM",
		payload: product
	}
}

export const clearCartStart = () => {
	return {
		type: "CLEAR_CART"
	}
}

export const deleteCartItemStart = (cartItem) => {
	return {
		type: "DELETE_CART_ITEM",
		payload: cartItem
	}
}

export const dropCard = (card) => {
	return {
		type: "DROP_ITEM",
		values: card
	}
}

export const getProductById = (product) => {
	return {
		type: "GET_PRODUCT_BY_ID",
		payload: product
	}
}

export const increment = (product) => {
	return {
		type: "INCREMENT",
		payload: product
	}
}

export const decrementStart = (product) => {
	return {
		type: "DECREMENT",
		payload: product
	}
}

export const clearCartOnLogout = () => {
	return {
		type: "CLEAR_CART_ON_LOGOUT",
	}
}

export const getLists = (lists) => {
	return {
		type: "FETCH_LISTS",
		payload: lists
	}
}

export const defaultCart = (cart) => {
	return {
		type: "DEFAULT_CART",
		payload: cart
	}
}

export const updateCartItemsList = (newItems) => {
	return {
		type: "UPDATE_CART_ITEMS_LIST",
		payload: newItems
	}
}

export const updateListItems = (newListItem) => {
	return {
		type: "UPDATE_LIST_ITEMS",
		payload: newListItem
	}
}

export const removeListItems = (listItem) => {
	return {
		type: "REMOVE_LIST_ITEMS",
		payload: listItem
	}
}

export const getAllCarts = (carts) => {
	return {
		type: "GET_ALL_CARTS",
		payload: carts
	}
}

export const fillSingleOrder = (order) => {
	return {
		type: "SINGLE_ORDER",
		payload: order
	}
}

export const createOrder = (order) => {
	return {
		type: "CREATE_ORDER",
		payload: order
	}
}


/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/**
 * Calls a function that adds a product to the shopping cart, while checking if a cart is created and whether or not that product is already in the cart
 * @param {String} product Id of product
 */

export const addToCart = (product, count = 1) => {
	return (dispatch, getState) => {
		let duplicate = checkArrayForItem(getState().shoppingCartReducer.cartItems, product.id);
		if (!duplicate) {
			return addCartItem(product, getState().shoppingCartReducer.cart, count)
				.then((res) => {
					dispatch(addToCartStart(res));
				})
		} else {
			let p = {
				cart_id: duplicate.cart_id,
				id: duplicate.id,
				// item_count: duplicate.item_count + count,
				item_count: product.buy_max_limit === 0 ? (duplicate.item_count + count) : (duplicate.item_count + count) <= product.buy_max_limit ?
					duplicate.item_count + count : product.buy_max_limit,
				product_id: duplicate.product_id
			}

			if ((duplicate.item_count + count) <= product.buy_max_limit || product.buy_max_limit === 0) {
				dispatch(updateCartItemStart(p));
				return updateCartItem(p);
			}
		}
	}

}

/**
 * Calls a function that fills the state with the cart data and fetches the cart items belonging in that cart
 * @param {String} product Id of product
 */

export const getCartItems = () => {
	return (dispatch, getState) => {
		return getCartItemsBC(getState().shoppingCartReducer.cart.id)
			.then((res) => {
				dispatch(getCartItemsStart(res));
			})
			.catch((err) => {
				console.error("Error fetching cart items", err);
			})
	}
}

export const getCartItemsList = (id) => {
	return (dispatch) => {
		return getCartItemsBC(id)
			.then((res) => {
				dispatch(getCartItemsStart(res));
			})
			.catch((err) => {
				console.error("Error fetching cart items", err);
			})
	}
}

/**
 * Decrements the CartItem and returns its new state
 * @param {String} product Id of product
 */

export const decrement = (product) => {
	return (dispatch, getState) => {
		let duplicate = checkArrayForItem(getState().shoppingCartReducer.cartItems, product.id);
		if (duplicate) {
			let p = {
				cart_id: duplicate.cart_id,
				id: duplicate.id,
				item_count: duplicate.item_count - 1,
				product_id: duplicate.product_id
			}
			if (p.item_count >= 1) {
				dispatch(updateCartItemStart(p));
				return updateCartItem(p);
			}
		}
	}
}

/**
 * Removes a cart item onclick
 * @param {Object} cartItem Product object
 */

export const deleteCartItem = (cartItem) => {
	return dispatch => {
		dispatch(deleteCartItemStart(cartItem));
		return bcDeleteCartItem(cartItem.cart_id, cartItem.id);
	}
}

/**
 * Initiates a function that removes a cart item
 * @param {String} spid Shopping cart id
 * @param {String} ciid Cart item id
 */

function bcDeleteCartItem(spid, ciid) {
	return bc.cart.deleteCartItem(spid, ciid)
		.then((params) => {
			if (params.ok) {
				return params.data;
			}
			return true;
		})
		.catch((err) => {
			console.error("Error deleting cart item", err);
			return true;
		})
}

/**
 * Checks if cartitem is already in array
 * @param {Array} item Array of cart items
 * @param {String} productId id of product
 */

function checkArrayForItem(items, productId) {
	let index = items.find(item => item.product_id === productId);
	return index;
}

/**
 * Initial creation of a cart upon adding an item in the cart for the first time
 */



/**
 * After cart creation, add cart item to newly creation cart
 * @param {String} product Product id
 * @param {String} cart Shopping cart id
 */

function addCartItem(product, cart, count) {
	let ci = new CartItem();

	ci.loadFromJSON({
		cart_id: cart.id,
		product_id: product.id,
		item_count: count
	});

	return bc.cart.addCartItem(cart.id, ci)
		.then((params) => {
			if (params.ok) {
				return params.data;
			}
		})
		.catch((err) => {
			console.error(err);
		})
}

/**
 * Updates the item if there are changes
 * @param {Object} res Product object
 */

function updateCartItem(res) {

	let ci = new CartItem();
	ci.loadFromJSON({
		id: res.id,
		cart_id: res.cart_id,
		product_id: res.product_id,
		item_count: res.item_count
	});

	return bc.cart.updateCartItem(res.cart_id, res.id, ci)
		.then((params) => {
			if (params.ok) {
				return params.data;
			}
			return true;
		})
		.catch((err) => {
			console.error("Error updating cart item", err);
			return true;
		})
}

function getCartItemsBC(id) {
	return bc.cart.getCartItems(id)
		.then((params) => {
			if (params.ok) {
				return params.data;
			}
		})
		.catch((err) => {
			console.error("Error updating cart item", err);
			return true;
		})
}