import React from "react";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Import of Components and Directives
import Raft from "./Raft";
import  Footer  from "./shared/Footer";
import { Animated } from "react-animated-css";

const Home = (props) => {

		return (
			<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={ArrayIsEmpty(props.mainCategories) && ArrayIsEmpty(props.products) && !isEmpty(props.productsMap)}>
				{
					ArrayIsEmpty(props.mainCategories) && ArrayIsEmpty(props.products) && !isEmpty(props.productsMap) ?
						props.mainCategories
							.sort((a, b) => a.order - b.order)
							.filter(c => c.hasOwnProperty("products") &&
								Array.isArray(c.products) &&
								c.products.length > 0 &&
								c.products.some(el => props.products[props.productsMap[el]] !== undefined ? c.products.includes(props.products[props.productsMap[el]].id) && props.products[props.productsMap[el]].quantity > 0 && props.products[props.productsMap[el]].active : null)
							)
							.map((c) => {
								return (
									<Raft
										key={c.id}
										category={c}
									/>
								)
							}) : null
				}

				<Footer pages={props.pages} openTour={props.openTour}/>

			</Animated>
		)
}

export default Home;
