import React, { Fragment, useEffect} from "react";
import { Animated } from "react-animated-css";

const CookiesDisclaimer = (props) => { 

   useEffect(() => {
      setTimeout(() => {
        localStorage.setItem("usr-cks-dsclmr", true);
        }, 5000);
   }, []);

    return ( 
       <Fragment>
			<Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration="fast" className="disclaimer-overlay"></Animated>
			<Animated animationIn="slideInUp" animationInDuration="fast" className="cookies-container">
				<span className="cookies-icon"></span>
				<h2>Оваа страна користи колачиња за да го подобри вашето интернет искуство. Со читање на пораката, се согласувате на овие услови.</h2>
				<button aria-label="continue" onClick={props.hideCookiesDisclaimer} className="small-button--primary">Во ред</button>
			</Animated>
		</Fragment>
   );
};
 
 export default CookiesDisclaimer;
