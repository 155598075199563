import { useState } from 'react';

import Input from '../../ui/Input';

const CheckoutPopupStepPersonalInfo = (props) => {
    return (
        <div className="workflow-step__personal-info">
            <div className="workflow-step__form-container">
                <Input 
                    label="Име и презиме" 
                    error={props.errors.full_name.error} 
                    name="full_name" 
                    value={props.data.full_name}
                    onChange={props.onChange}
                    validate="string"
                />
                <Input 
                    label="E-mail" 
                    error={props.errors.email.error} 
                    name="email" 
                    value={props.data.email}
                    onChange={props.onChange}
                    validate="email"
                />
                <Input 
                    label="Телефон" 
                    error={props.errors.phone.error} 
                    name="phone" 
                    value={props.data.phone}
                    onChange={props.onChange}
                    validate="phone"
                    placeholder="070 123 456"
                />
            </div>
            <p className="workflow-step__info-container">Внесете ги Вашите податоци. Истите ги чуваме со големо внимание и никогаш не ги откриваме на трети страни.<br /><br />На Вашата e-mail адреса ќе испратиме потврда за успешната нарачка. Доколку не добиете потврда, известете не преку контакт формата.</p>
        </div>
    );
};

export default CheckoutPopupStepPersonalInfo;