import React, { useState, useEffect, useMemo } from "react";
import { Animated } from "react-animated-css";
import { NavLink, withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { isEmpty, ArrayIsEmpty } from "../helper";
import { Formik, Form, ErrorMessage } from 'formik';

const BasicInfoPopup = React.memo((props) => {

	const[theLocation, setTheLocation] = useState(props.data);
	const[basicInfo, setBasicInfo] = useState(props.basicInfo);

	const location = useLocation();

	const { id } = useParams();

	useEffect(() => {
		if(location.pathname === "/settings/locations/new") {
			setTheLocation({});
		} else {
			if(!isEmpty(props.data) && (location.pathname !== "/settings/locations/" + id)) {
				setTheLocation(props.data[0]);
			}
		}
	},[]);

	/**
    *  After user scrolls to the end of the content the gradient dissapears to reveal the slightly hidden content
    */

	const _hideGradientOnBottomScroll = () => {
		var element = document.querySelector(".inputs-container");
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			document.getElementById("mobile-popup-gradient--location").style.opacity = "0";
		} else {
			document.getElementById("mobile-popup-gradient--location").style.opacity = "1";
		};
	};

	/**
    *  Updates user data
    */

	const _updateUserData = (data) => {
		let basic_data = {
			first_name: data.first_name,
			last_name: data.last_name,
			phone: data.phone
		}

		let location_data = {
			address: data.address,
			address_number: data.address_number,
			region: data.region
		}

		let props_location_data = {
			address: props.data[0].address,
			address_number: props.data[0].address_number,
			region: props.data[0].region
		}

		if (JSON.stringify(location_data) === JSON.stringify(props_location_data) && JSON.stringify(basic_data) !== JSON.stringify(props.basicInfo)) {
			props.updateUserData(basic_data);
		} else if (JSON.stringify(basic_data) === JSON.stringify(props.basicInfo) && JSON.stringify(location_data) !== JSON.stringify(props_location_data)) {
			props.updateLocation(location_data, props.data[0].id);
		} else if (JSON.stringify(basic_data) !== JSON.stringify(props.basicInfo) && JSON.stringify(location_data) !== JSON.stringify(props_location_data)) {
			props.updateLocation(location_data, props.data[0].id);
			props.updateUserData(basic_data);
		}

		props.hideMap();
	};

	const isObject = (val) => {
		return val instanceof Object;
	};

		return (
			<div className={"product__popup"} id="location__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--overlay" onClick={props.hideMap}></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--full">
						{theLocation !== undefined || isObject(theLocation) ?
							location.pathname === "/settings/locations/new" || location.pathname === ("/settings/locations/" + theLocation.id) ?
								<div className="section__favorite">
									<h2><FormattedMessage id={"LOCATION"} /></h2>
								</div> :
								props.newAddress ?
									<div className="section__favorite">
										<h2><FormattedMessage id={"BASIC_INFO"} /></h2>
									</div> :
									<div className="section__favorite">
										<h2><FormattedMessage id={"LOCATION"} /></h2>
									</div> : null}

						<div className="section__close">
							<button aria-label="hide-map" onClick={props.hideMap} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div onScroll={_hideGradientOnBottomScroll} className="inputs">

						{theLocation !== undefined && ArrayIsEmpty(props.data) ?
							location.pathname === "/settings/basic-info/edit" ?
								<Formik
									initialValues={{
										first_name: basicInfo.first_name,
										last_name: basicInfo.last_name,
										phone: basicInfo.phone,
										address: props.data[0].address,
										address_number: props.data[0].address_number,
										region: props.data[0].region,
									}}
									validate={values => {
										let errors = {};

										if (!values.first_name) errors.first_name = "Задолжително";
										if (!values.last_name) errors.last_name = "Задолжително";
										if (!values.phone) { errors.phone = "Задолжително"; } else if (!/^07[0-9]{7}$/.test(values.phone)) { errors.phone = "Неправилен број" }
										if (!values.address) errors.address = "Задолжително";
										if (!values.address_number) errors.address_number = "Задолжително";
										if (!values.region) errors.region = "Задолжително";

										return errors;
									}}
									onSubmit={values => {
										_updateUserData(values);
									}}
									render={({ errors, touched, values, handleChange }) => (
										<Form name="basic-info-form">
											<div className="inputs-container">
												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="first_name" component="span" className="invalid-feedback halfed" />
														<label htmlFor="basic-info-name"><FormattedMessage id={"NAME"} /></label>
													</div>
													<input type="text" name="first_name" onChange={handleChange} value={values.first_name || ""} className={"label__input " + (errors.first_name && touched.first_name && errors.first_name ? "invalid-input" : "")} id="basic-info-name" />
												</div>

												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="last_name" component="span" className="invalid-feedback halfed" />
														<label htmlFor="basic-info-lastname"><FormattedMessage id={"LAST_NAME"} /></label>
													</div>
													<input type="text" name="last_name" onChange={handleChange} value={values.last_name || ""} className={"label__input " + (errors.last_name && touched.last_name && errors.last_name ? "invalid-input" : "")} id="basic-info-lastname" />
												</div>

												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="phone" component="span" className="invalid-feedback halfed" />
														<label htmlFor="basic-info-telephone"><FormattedMessage id={"TELEPHONE"} /></label>
													</div>
													<input type="text" name="phone" onChange={handleChange} value={values.phone || ""} className={"label__input " + (errors.phone && touched.phone && errors.phone ? "invalid-input" : "")} id="basic-info-telephone" />
												</div>

												<div className="input-grid">
													<div className="input__holders--col input__holders--col--half input__holders--col--sts">
														<div className="label-holder">
															<ErrorMessage name="address" component="span" className="invalid-feedback halfed" />
															<label htmlFor="address"><FormattedMessage id={"ADDRESS"} /></label>
														</div>
														<input type="text" name="address" onChange={handleChange} value={values.address} className={"label__input " + (errors.address && touched.address && errors.address ? "invalid-input" : "")} id="address" />
													</div>
													<div className="input__holders--col input__holders--col--half input__holders--col--stt">
														<div className="label-holder">
															<ErrorMessage name="address_number" component="span" className="invalid-feedback halfed" />
															<label htmlFor="address_number"><FormattedMessage id={"NUMBER"} /></label>
														</div>
														<input type="text" name="address_number" onChange={handleChange} value={values.address_number} className={"label__input " + (errors.address_number && touched.address_number && errors.address_number ? "invalid-input" : "")} id="address_number" />
													</div>
												</div>

												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="region" component="span" className="invalid-feedback halfed" />
														<label htmlFor="basic-info-region"><FormattedMessage id={"REGION"} /></label>
													</div>
													<input type="text" name="region" onChange={handleChange} value={values.region} className={"label__input " + (errors.region && touched.region && errors.region ? "invalid-input" : "")} id="basic-info-region" />
												</div>

												<div className="settings__content-block--actions">
													<div className="popup--gradient" id="mobile-popup-gradient--location"></div>
													<div>
														<button aria-label="hide-map" type="button" className="button__ghost--primary" onClick={props.hideMap}>Откажи</button>
														<button aria-label="save" type="submit" className="button--primary">Зачувај</button>
													</div>
												</div>
											</div>
										</Form>
									)}
								/>
								: null : null}
					</div>
				</Animated>
			</div>
		)
}, (props, nextProps) => {
	return JSON.stringify(nextProps.data) !== JSON.stringify(props.data);
});

export default BasicInfoPopup;
