const initialState = {
	products: [],
	productsMap: {},
	countProductMap: {},
	product: {}
}

const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_ALL_PRODUCTS":
			return {
				...state,
				products: action.payload.products,
				productsMap: action.payload.productsMap
			};
		case "GET_PRODUCT_BY_ID":
			return {
				...state,
				product: action.payload
			};
		case "CREATE_PRODUCT_COUNT_MAP":
			return {
				...state,
				countProductMap: action.payload
			};
		case "RESET_PRODUCT_COUNT_MAP":
			var resetedMap = {};
			for (var i in state.countProductMap) {
				resetedMap[i] = 1;
			}
			return {
				...state,
				countProductMap: resetedMap
			}
		case "SET_COUNT_IN_PRODUCT_MAP":
			return {
				...state,
				countProductMap: {
					...state.countProductMap,
					[action.payload.id]: action.payload.count
				}
			}
		case "INCREMENT_PRODUCT":
			return {
				...state,
				countProductMap: {
					...state.countProductMap,
					[action.payload]: state.countProductMap[action.payload] + 1
				}
			}
		case "DECREMENT_PRODUCT":
			return {
				...state,
				countProductMap: {
					...state.countProductMap,
					[action.payload]: state.countProductMap[action.payload] > 1 ? state.countProductMap[action.payload] - 1 : 1
				}
			}
		case "SET_CARD_WIDTH" :
			return {
				...state,
				cardWidth: action.payload
			}
		default: return state;
	}
};

export default productsReducer;