import React from "react";

const SinglePage = (props) => {

	const createMarkUp = () => {
		return {__html: props.data.content};
	}
		return (
			<div className="sub-page" id="privacy-policy">
				<div className="text-block">
					<div className="text-block-header text-block-header-main">
						<span className="chevron"></span>
						<h2>{props.data.title}</h2>
					</div>
				</div>

				<div dangerouslySetInnerHTML={createMarkUp()} className="sub-content iconless"></div>
			</div>
		)
}

export default SinglePage;
