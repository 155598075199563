const initialState = {
	dropdownType: "",
	opened: false
}

const dropdownReducer = (state = initialState, action) => {
	switch(action.type) {
		case "OPEN_DROPDOWN":
			return {
				...state, 
				dropdownType: action.values,
				opened: !state.opened
			}
		case "CLOSE_DROPDOWN":
			return {
				...state, 
				dropdownType: action.values,
				opened: false
			}
		case "CLOSE_DROPDOWN--MOBILE":
			return {
				...state, 
				dropdownType: action.values,
				opened: false
			}
		default: return state;
	}
}

export default dropdownReducer;