import React from "react";
import { connect, useSelector } from "react-redux";

const MobileSearch = (props) => {
	const showSearch = useSelector(state => state.searchReducer.show);

		return(
			<div className={"search--mobile " + (showSearch ? "show--search" : "")} tabIndex="0" onBlur={props.hideSearch}> 
				<input ref={props.inputRef} autoComplete="off" onChange={ props.handleSearchValue } value={ props.searchValue } id="search--input" type="text" placeholder="Пребарај низ рафтовите... Пр: Зимница" /> 
				<button aria-label="search" onClick={ props.clear } className={"mobile__header-search--delete icon-button--naked " + (props.searchValue !== "" ? "show--delete" : "")}></button>
			</div>
		)
}

export default MobileSearch;
