import React from "react";
import { ArrayIsEmpty } from "../../helper";
import { BrowserView, MobileView } from "react-device-detect";

export class Tabs extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			currentSubSlider: 0
		}

		this.Slide = this.Slide.bind(this);

		this.subCategories = [];
		this.mobileSubCategories = [];
		this.subCategoryList = React.createRef();
		this.mainCategory = React.createRef();
		this.tabs = React.createRef();
		this.mobileTabs = React.createRef();
		this.divider = React.createRef();

		this.slideable = true;
	}

	componentDidMount() {
		this.tabs.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth + 74}px)`;
		this.mobileTabs.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth}px)`;
		this.divider.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth + 15}px)`;
	}

	shouldComponentUpdate(prevProps, prevState) {
		if (prevProps.expandAllProducts.expanded.indexOf(this.props.categoryId) > -1) {
			this.tabs.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth + 20}px)`;
		} else {
			this.tabs.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth + 74}px)`;
		}

		this.mobileTabs.current.style.width = `calc(100% - ${this.mainCategory.current.clientWidth}px)`;
		return true;
	}


	/**
	* Slide to the next or previous category based on direction
	*/
	Slide(dir) {
		if (this.slideable) {
			this.slideable = false;
			if (dir === "next") {
				this.setState(state => {
					return {
						currentSubSlider: state.currentSubSlider < this.subCategories.length ? state.currentSubSlider + 1 : state.currentSubSlider
					}
				}, () => {
					for (var i in this.subCategories) {
						if (this.state.currentSubSlider < this.subCategories.length) {
							this.subCategories[i].style.transform = `translate3d(${(window.getComputedStyle(this.subCategories[this.state.currentSubSlider]).getPropertyValue("transform").match(/(-?[0-9]+)/g)[4] - this.subCategories[this.state.currentSubSlider - 1].getBoundingClientRect().width) + "px"}, 0px, 0px)`;
						}
					}
				})
			} else if (dir === "prev") {
				this.setState(state => {
					return {
						currentSubSlider: state.currentSubSlider - 1
					}
				}, () => {
					for (var i in this.subCategories) {
						let negval = window.getComputedStyle(this.subCategories[this.state.currentSubSlider]).getPropertyValue("transform").match(/(-?[0-9]+)/g)[4].split('-').join('');
						this.subCategories[i].style.transform = `translate3d(${(parseInt(-negval, 0) + this.subCategories[this.state.currentSubSlider].getBoundingClientRect().width) + "px"}, 0px, 0px)`;
					}
				})
			}
			setTimeout(() => {
				this.slideable = true;
			}, 300);
		}
	}

	render() {
		return (
			<div className={"category-content-container " + (!ArrayIsEmpty(this.props.data.children) ? "no-children" : "")} key={this.props.data.id}>
				<div className="category-title">
					<a ref={this.mainCategory} className={this.props.data.id === this.props.filteredRaftId ? "main--active" : ""} onClick={() => this.props.filterRaft(this.props.data)}>
						<span className="chevron"></span>
						<h2>{this.props.data.name}</h2>
					</a>
				</div>

				<div className="category-src tabs web" ref={this.tabs}>
					<BrowserView renderWithFragment>
						<div className={"tabs-container-right " + (!this.props.data.hasOwnProperty("children") ? "no-children" : "")}>
							<div className="tabs-container-left">
								<div className={"tabs-buttons-list " + (this.props.hideButton ? "full-width-tabs" : "")}>
									<button aria-label="prev" onClick={() => this.Slide("prev")} className={"prev-button " + (this.state.currentSubSlider === 0 ? "disabled" : "")}><span className="prev-icon"></span></button>
									<ul ref={this.subCategoryList}>
										{
											this.props.data.hasOwnProperty("children") &&
											this.props.data.children
												.filter(sub => {
													return sub.hasOwnProperty("products") &&
														Array.isArray(sub.products) &&
														sub.products.length > 0 &&
														sub.products.some(el => this.props.products[this.props.productsMap[el]] !== undefined ? sub.products.includes(this.props.products[this.props.productsMap[el]].id) && this.props.products[this.props.productsMap[el]].quantity > 0 && this.props.products[this.props.productsMap[el]].active : null)
												})
												.sort((a, b) => { return a.order - b.order })
												.map((sub, i) => {
													return (
														<li order={sub.order - 1} ref={subCategories => this.subCategories[i] = subCategories} onClick={() => this.props.filterRaft(sub)} className={this.props.activeRaftTab(sub)} key={sub.id}>
															<h3 title={sub.name}>{sub.name}</h3>
															<span className="line"></span>
														</li>
													)
												})
										}
									</ul>
									<button aria-label="next" onClick={() => this.Slide("next")} className={"next-button " + (this.state.currentSubSlider === this.subCategories.length - 1 ? "disabled" : "")}><span className="next-icon"></span></button>
								</div>
								<div className={"raft__categories--right "}>
									<div className="raft__categories--expander"></div>

									<button disabled={this.props.productsRealCount.length <= 4} aria-label="expand" onClick={() => this.props.handleRaftToggle(this.props.categoryId)} className={"small-button--primary web__more-button " + (this.props.hideButton ? "disabled" : "")}>
										{!this.props.hideButton ?
											this.props.expandAllProducts.expanded.includes(this.props.categoryId) ? <span><span className="undo"></span> врати</span> : "сите производи" : "сите производи"}
									</button>
								</div>
							</div>
						</div>
					</BrowserView>
				</div>

				<div className="category-src tabs mobile" ref={this.mobileTabs}>
					<MobileView renderWithFragment>
						<div className="tabs-container-right" >
							<div className="tabs-container-left">
								<div className={"tabs-buttons-list " + (this.props.hideButton ? "full-width-tabs" : "")}>
									<ul ref={this.subCategoryList}>
										{
											this.props.data.children
												.filter(sub => { return sub.hasOwnProperty("products") && Array.isArray(sub.products) && sub.products.length > 0 })
												.sort((a, b) => { return a.order - b.order })
												.map((sub, i) => {
													return (
														<li order={sub.order - 1} ref={mobileSubCategories => this.mobileSubCategories[i] = mobileSubCategories} onClick={() => this.props.filterRaft(sub)} className={this.props.activeRaftTab(sub)} key={sub.id}>
															<h3 title={sub.name}>{sub.name}</h3>
															<span className="line"></span>
														</li>
													)
												})
										}
									</ul>
								</div>
								<div className={"raft__categories--right "}>
									<div className="raft__categories--expander"></div>
									<button aria-label="expand" onClick={() => this.props.handleRaftToggle(this.props.categoryId)} className="small-button--primary mobile__more-button" disabled={this.props.hideButton}>
										{!this.props.hideButton ?
											this.props.expandAllProducts.expanded.includes(this.props.categoryId) ? <span className="undo"></span> : <span></span> : <span></span>}
									</button>
								</div>
							</div>
						</div>
					</MobileView>
				</div>
				<hr ref={this.divider} className="raft-category-divider" />
			</div>
		)
	}
}