const initialState = {
	popupType: "",
	opened: false,
	auth: {
		isAuthenticated: false,
		authUser: null
	},
}

const userAuthPopup = (state = initialState, action) => {
	switch(action.type) {
		case "AUTH_OPEN_POPUP":
			return {
				...state,
				popupType: action.values,
				opened: true
			}
		case "AUTH_CLOSE_POPUP":
			return {
				...state,
				popupType: "",
				opened: false
			}
		case "IS_LOGGED_IN":  
			return Object.assign({}, state, {
				...state,
				auth: {
					isAuthenticated: true,
					authUser: action.payload,       
				}
			});
		default: return state;
	}
}

export default userAuthPopup;