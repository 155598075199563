import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";

const Toast = (props) => {

	const[show, setShow] = useState(props.show);

	useEffect(() =>{
		props.hideToast();
		return () => {
			setShow(false);
		}
	},[]);

	return (
		<Animated style={{animationDuration:"0.2s"}} isVisible={show} animationIn="slideInUp" animationOut="fadeOut" className={"toast code-" + props.code}>
			<div className="toast-content">
				<div className="toast-icon"></div>
				<div className="toast-message">
					<p>{props.message}</p>
				</div>
			</div>
		</Animated>
	)

};

export default Toast;
