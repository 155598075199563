export const fetchFavorites = (favorites) => {
	return {
		type: "FETCH_FAVORITES",
		payload: favorites
	}
}

export const removeFavoritedItem = (favorite) => {
	return {
		type: "REMOVE_FAVORITED_ITEM",
		payload: favorite
	}
}