import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { IntlProvider } from "react-intl";
import { BrowserView, MobileView } from "react-device-detect";
import bc from "../callsetter";
// Import of Components
import Home from "./Home";
import Header from "./shared/Header";
import Menu from "./shared/Menu";
import TrackOrder  from "./shared/TrackOrder";
import MobileCart from "./MobileCart";
import Cart from "./Cart";
import { OrderHistory } from "./OrderHistory";
import  LoginPopup  from "./LoginPopup";
import Settings from "./Settings";
import Favorites  from "./Favorites";
import Statistics from "./Statistics";
import Help from "./Help";
import RegisterPopup from "./RegisterPopup";
import CheckoutPopup from "./widgets/CheckoutPopup";
import ProductPopup from "./ProductPopup";
import MobileProductPopup from "./MobileProductPopup";
import OrderSuccess from "./OrderSuccess";
import ConfirmationPopup from "./ConfirmationPopup";
import RecoverPassword from "./RecoverPassword";
import ResetPasswordForm  from "./ResetPasswordForm";
import ListPopup from "./ListPopup";
import DisclaimerPopup from "./DisclaimerPopup";
import SessionReminderPopup from "./SessionReminderPopup";
import CookiesDisclaimer from "./CookiesDisclaimer";
import Walkthrough from "./Walkthrough";
import OrderError from "./OrderError";
import Confirm from "./Confirm";
import Tour from "reactour";
// import { Cart } from "./Cart";
// import { MobileCart } from "./MobileCart";
// import { OrderHistory } from "./OrderHistory";
// import { Settings } from "./Settings";
// import { Statistics } from "./Statistics";
import Toast from "./shared/Toast";
// ** WALKTHROUGH STEPS **//
import { STEP_ONE, STEP_TWO, STEP_THREE, STEP_FOUR, STEP_FIVE } from "../constants/index";
// Routes
import { Route, withRouter, Switch } from "react-router-dom";
// Actions
import { closeMenu } from "../actions/MenuActions";
import { isLoggedIn, showAuthPopup } from "../actions/userAuthAction";
import { closeDropdown } from "../actions/DropdownsAction";
import { getMe, fetchUserLocation, fetchUserProfiles, fetchUserPreferences, fetchUserStatistics, clearUserObject, fetchUserOrders, clearUserStatistics } from "../actions/UserAction";
import { fetchFavorites } from "../actions/FavoritesAction";
import { getAllProducts, createProductCountMap } from "../actions/ProductsAction";
import { getAllMainCategories, getAllCategories } from "../actions/CategoriesAction";
import { createCartStart, getCartItems, clearCartOnLogout, getLists, defaultCart, updateCartItemsList, updateListItems, getCartItemsList, removeListItems, getAllCarts, getCartItemsStart } from "../actions/ShoppingCartAction";
import { chooseCart } from "../actions/SaveCartAction";
import { getPages } from "../actions/PagesAction";
import { hideSearch, fillSearchValue, clearSearchValue } from "../actions/SearchAction";
// Translated strings
import strings from "../strings";
import SearchedProducts from "./SearchedProducts";
import { isEmpty } from "../helper";
import AddToHomescreen from 'react-add-to-homescreen';

export class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			toastMessage: "",
			showToast: false,
			toastCode: "",
			toastType: "",
			badLogin: false,
			logoutSuccess: false,
			badRegister: false,
			user: {},
			renderHome: false,
			showLoader: true,
			headerSearchValue: "",
			serching: false,
			searchedItems: [],
			showSearchedItems: false,
			buttonClickLoader: false,
			badRecoveryEmail: false,
			badPasswordReset: false,
			badPasswordResetMessage: "",
			successfullRecoveryMail: false,
			accountExists: false,
			currentSubSlider: 0,

			/* State variables for preventing zoom on mobile devices via double tap (used in touchstart and touchend events) */
			preLastTouchStartAt: 0, // timestamp
			lastTouchStartAt: 0, // timestamp
			delay: 500, // milliseconds

			showCookiesDisclaimer: false,
			showUserWalkthrough: false,
			addToHomescreen: false
		}

		this.headerSearchTimeout = null;

		this.setLoginVerification = this.setLoginVerification.bind(this);
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.hideToast = this.hideToast.bind(this);
		this.getUserLoggedInData = this.getUserLoggedInData.bind(this);
		this.getUserLocations = this.getUserLocations.bind(this);
		this.getUserProfiles = this.getUserProfiles.bind(this);
		this.syncData = this.syncData.bind(this);
		this.getCategories = this.getCategories.bind(this);
		this.getAllCategories = this.getAllCategories.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.handleSearchValue = this.handleSearchValue.bind(this);
		this.openRecoverPasswordPopup = this.openRecoverPasswordPopup.bind(this);
		this.sendPassordRecoveryMail = this.sendPassordRecoveryMail.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.getUserPreferences = this.getUserPreferences.bind(this);
		this.createVisitorId = this.createVisitorId.bind(this);
		this.fetchUserStatistics = this.fetchUserStatistics.bind(this);
		this.fetchStatisticsParams = this.fetchStatisticsParams.bind(this);
		this.fetchFavorites = this.fetchFavorites.bind(this);
		this.fetchCartData = this.fetchCartData.bind(this);
		this.getCartItems = this.props.getCartItems.bind(this);
		this.fetchLists = this.fetchLists.bind(this);
		this.chooseCart = this.props.chooseCart.bind(this);
		this.clearCartOnLogout = this.props.clearCartOnLogout.bind(this);
		this.createCart = this.props.createCartStart.bind(this);
		this.updateCartItemsList = this.props.updateCartItemsList.bind(this);
		this.updateListItems = this.props.updateListItems.bind(this);
		this.deleteList = this.deleteList.bind(this);
		this.goToDefaultCart = this.goToDefaultCart.bind(this);
		this.removeListItems = this.props.removeListItems.bind(this);
		this.getPages = this.getPages.bind(this);
		this.clearUserObject = this.props.clearUserObject.bind(this);
		this.fetchUserOrders = this.fetchUserOrders.bind(this);
		this.getAllCarts = this.getAllCarts.bind(this);
		this.clearSearchField = this.clearSearchField.bind(this);
		this.informOnLeave = this.informOnLeave.bind(this);
		this.sessionExpiredLoggedOff = this.sessionExpiredLoggedOff.bind(this);
		this.sessionExpiredLoggedIn = this.sessionExpiredLoggedIn.bind(this);
		this.HideSearch = this.HideSearch.bind(this);
		this.ClearSearchValue = this.ClearSearchValue.bind(this);
		this.HideCookiesDisclaimer = this.HideCookiesDisclaimer.bind(this);
		this.CloseTour = this.CloseTour.bind(this);
		this.OpenTour = this.OpenTour.bind(this);
		this.OpenLoginPopup = this.OpenLoginPopup.bind(this);
		this.handleAddToHomescreenClick = this.handleAddToHomescreenClick.bind(this);
	}

	componentDidMount() {

		/**
		 * Set login data and get data from logged in user
		*/

		this.createVisitorId()
			.then(() => {
				/**
		 		* Fetching data needed upon app render [PRODUCTS, CATEGORIES, CART ITEMS(if full)]
				*/
				this.syncData()
					.then(() => { this.getCategories() })
					.then(() => { return this.getProducts() })
					.then(() => { return this.getPages() })
					.then(() => {
						return this.fetchCartData()
							.then(() => {
								if (!isEmpty(this.props.cartData)) {
									this.props.getCartItems();
								}
							})
					})
					.then(() => this.setState({ showLoader: !this.state.showLoader, addToHomescreen: true }))
					.then(() => {
						let usr_cks_dsclmr = JSON.parse(localStorage.getItem("usr-cks-dsclmr"));
						this.setState({
							showCookiesDisclaimer: usr_cks_dsclmr ? false : true
						})
					})
					.then(() => {
						setTimeout(() => {
							localStorage.setItem("usr-wlkthrgh", true);
						}, 1000);
						let usr_wlkthrgh = JSON.parse(localStorage.getItem("usr-wlkthrgh"));
						this.setState({
							showUserWalkthrough: usr_wlkthrgh ? false : true
						});
					})
					.then(() => this.sessionExpiredLoggedOff());
			});

		bc.auth.onAuthStateChanged(this.setLoginVerification);

		/**
		 * Prevent zoom on mobile devices via double tap
		*/
		document.addEventListener('touchstart', this.handleTouchStartEvent);
		document.addEventListener('touchend', this.handleTouchEndEvent);
	}

	handleTouchStartEvent = () => {
		this.setState((state, props) => {
			return {
				preLastTouchStartAt: state.lastTouchStartAt,
				lastTouchStartAt: +new Date()
			}
		});
	}

	handleTouchEndEvent = (event) => {
		let { preLastTouchStartAt, delay } = this.state;
		const touchEndAt = +new Date();
		if (touchEndAt - preLastTouchStartAt < delay) {
			event.preventDefault();
			event.target.click();
		}
	}

	componentWillUnmount() {
		// remove all initialized events
		document.removeEventListener('touchstart', this.handleTouchStartEvent);
		document.removeEventListener('touchend', this.handleTouchEndEvent);
	}

	previousLocation = this.props.location;

	componentWillUpdate(nextProps) {
		const { location } = this.props;
		if (
			nextProps.history.action !== "POP" &&
			(!location.state || !location.state.modal)
		) {
			this.previousLocation = this.props.location;
		}
	}

	/**
    * After user tries to leave/refresh the site, a confirm window appears
    */

	informOnLeave() {
		window.onbeforeunload = function () {
			return "Сакате да ја напуштите или освежите страната. Можно е некој работи да не бидат зачувани.";
		};
	}

	/**
    * Sets the user data and fetches the logged in user and his location
    */

	createVisitorId() {
		return bc.auth.createVisitorId()
			.then((params) => {
				return true;
			})
			.catch(err => {
				return false;
			})
	}

	/**
    * Sets the user data and fetches the logged in user and his location
    */

	setLoginVerification(data) {
		// IS AUTHENTICATED... check if needed.
		if (!data || data === null) {
			this.props.isLoggedIn(data);
			return;
		};

		if (data.data && data.data.status === "ACCOUNT_EXISTS") {
			this.props.history.push("/disclaimer");
		} else {
			this.props.isLoggedIn(data);

			this.getUserLoggedInData()
				.then(this.sessionExpiredLoggedIn)
				.then(this.getUserLocations)
				.then(this.getUserProfiles)
				.then(this.getUserPreferences)
				.then(this.fetchUserStatistics)
				.then(this.fetchFavorites)
				.then(this.fetchLists)
				.then(this.fetchUserOrders)
				.then(this.getAllCarts)
				.then(() => {
					return this.fetchCartData()
						.then(() => {
							if (!isEmpty(this.props.cartData)) {
								this.props.getCartItems();
							}
						});
				});
		}
	}

	/**
    * If Users' [LoggedOf] session expires, a popup informs him to refresh the page to safe his progress
    */

	sessionExpiredLoggedOff() {
		if (this.props.auth.authUser === null) {
			setTimeout(() => {
				this.props.history.push("/session-expired");
			}, 3300000);
		}
	}

	/**
    * If Users' [LoggedIn] session expires, the VID is automaticly renewed
    */

	sessionExpiredLoggedIn() {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			this.createVisitorId();
			setTimeout(() => { this.sessionExpiredLoggedIn() }, 3300000);
		}
	}

	/**
    * Fetch cart data based on visitor id or user id
    */

	fetchCartData() {
		return bc.cart.getCurrentCart()
			.then((params) => {
				if (params.ok) {
					this.props.createCartStart(params.data);
					this.props.defaultCart(params.data);
					return params;
				}
			})
			.catch((err) => {
				console.error("CART FETCH ERROR", err);
				// if(err.data.type === "AUTH_CANNOT_GET_VISITOR_ID") {
				// 	this.props.history.push("/session-expired");
				// }
				return false;
			});
	}

	/**
    * User Logs in on click
    */

	login(email, password) {
		this.setState({
			buttonClickLoader: true
		});

		bc.auth.login(email, password)
			.then((params) => {
				if (params.ok) {
					this.props.history.push("/");
					this.setState({
						toastMessage: "Успешна најава",
						showToast: true,
						badLogin: false,
						toastCode: "green",
						toastType: "loginSuccess",
						buttonClickLoader: false
					});
					this.props.closeDropdown("settings");
				}
			})
			.catch((err) => {
				console.error("Error logging in: ", err);
				this.setState({
					badLogin: true,
					buttonClickLoader: false
				})
			})
	}

	/**
    * Open Password Recovery Popup
    */

	openRecoverPasswordPopup() {
		this.props.history.push("/recover-password");
		this.setState({
			badRecoveryEmail: false
		});
	}

	/**
    * Send instructional email for password recovery
    */

	sendPassordRecoveryMail(email) {
		this.setState({
			buttonClickLoader: true
		})
		bc.auth.resetPasswordLink(email)
			.then((res) => {
				if (res.ok) {
					this.setState({
						toastMessage: "Линк за обнова на вашата лозинка е испратен на " + email,
						showToast: true,
						badLogin: false,
						toastCode: "green",
						toastType: "passRecoveryMailSuccess",
						buttonClickLoader: false
					});
					this.props.history.push("/");
				}
			})
			.catch((err) => {
				this.setState({
					badRecoveryEmail: true,
					buttonClickLoader: false
				})
			})
	}

	/**
    * Send hash and new password so it can be reset
	*/

	resetPassword(hash, password) {
		this.setState({
			buttonClickLoader: true
		});

		return bc.auth.resetPassword(hash, password)
			.then((res) => {
				if (res.ok) {
					this.setState({
						toastMessage: "Успешна промена на вашата лозинка",
						showToast: true,
						toastCode: "green",
						toastType: "passResetSuccess",
						buttonClickLoader: false
					});
					this.props.history.push("/");
				}
			})
			.catch((err) => {
				if (err.body.type === "VALIDATION_FAILED") {
					this.setState({
						badPasswordReset: true,
						buttonClickLoader: false,
						badPasswordResetMessage: "Вашaта лозинка е премногу кратка. Користете повеќе од 8 карактери."
					});
				} else if (err.body.type === "AUTH_BAD_RESET_HASH") {
					this.setState({
						badPasswordReset: true,
						buttonClickLoader: false,
						badPasswordResetMessage: "Времето за промена на вашата лозинка истече. Побарајте нова промена и обидете се повторно."
					});
				}
			})
	}

	/**
    * User logs out on click
 	*/

	logout() {
		bc.auth.logout()
			.then((params) => {
				if (params.ok) {
					this.props.closeDropdown("settings");
					this.props.clearCartOnLogout();
					this.fetchCartData();
					this.clearUserObject();
					this.props.closeMenu();
					this.setState({
						toastMessage: "Успешна одјава",
						showToast: true,
						toastCode: "green",
						toastType: "logoutSuccess"
					});
				}
			})
			.catch((err) => {
				console.error("Error logging out", err);
			});
	}

	/**
    *  Hide toast after finished render of message
    */

	hideToast() {
		setTimeout(() => {
			this.setState({
				showToast: false
			});
		}, 4000);
	}

	/**
    * Fetches the logged in users' data
 	*/

	getUserLoggedInData() {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			return bc.users.getLoggedInUserData()
				.then((params) => {
					if (params.data !== null) {
						this.props.getMe(params.data);
					}
				})
				.catch((err) => {
					console.error(err);
				})
		}
	}

	/**
    * Fetches the locations of the user
	*/

	getUserLocations() {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			return bc.users.getUserLocations(this.props.user.me.id)
				.then((params) => {
					if (params.data !== null) {
						this.props.fetchUserLocation(params.data);
					}
				})
				.catch((err) => {
					console.error("Error while fetching locations", err);
				});
		}
	}

	/**
    * Fetches the users' linked profiles
	*/

	getUserProfiles() {
		return bc.users.getUserProfiles(this.props.user.me.id)
			.then((params) => {
				if (params.data !== null) {
					this.props.fetchUserProfiles(params.data);
				}
			})
			.catch((err) => {
				console.error("Error while fetching locations", err);
			});
	}

	/**
    *  Fetches the users' preferences
    */

	getUserPreferences() {
		bc.users.getUserPreferences(this.props.user.me.id)
			.then((params) => {
				if (params.data !== null) {
					this.props.fetchUserPreferences(params.data);
				}
			})
			.catch((err) => {
				console.error("Error fetching preferences", err);
			})
	}

	/**
    * Syncing data into indexedDB
	*/

	syncData() {
		return bc.syncData()
			.catch(err => console.error(err));
	}

	/**
    * Get synced tree of categories from indexedDB
	*/

	getCategories() {
		return bc.categories.getCategoriesTreeSynced()
			.then((params) => {
				if (params !== null) {
					this.props.fetchMainCategories(params);
				}
			})
			.catch((err) => {
				console.error("ERROR FETCHING CATEGORIES", err)
			})
	}

	/**
    * Get synced tree of categories from indexedDB
	*/

	getAllCategories() {
		return bc.categories.getAllCategoriesSynced()
			.then((params) => {
				if (params !== null) {
					this.props.fetchAllCategories(params);
				}
			})
			.catch((err) => {
				console.error("ERROR FETCHING ALL CATEGORIES", err)
			})
	}

	/**
    * Get synced tree of products from indexedDB
	*/

	getProducts() {
		return bc.products.getAllSynced()
			.then((params) => {
				if (params !== null) {
					let productsMap = {};
					let countProductMap = {};

					for (let i in params) {
						productsMap[params[i].id] = parseInt(i, 0);
						countProductMap[params[i].id] = 1;
					}

					this.props.fetchProducts({ products: params, productsMap });
					this.props.createProductCountMap(countProductMap);
				}
			})
			.catch((err) => {
				console.error("ERROR FETCHING PRODUCTS", err)
			})
	}

	/**
    * Splits string by whitespace and sends the values[array] in searchProductsByTagsSynced which returns an array (every 500ms) of products matching the tags
	*/

	handleSearchValue(e) {
		this.setState({ showLoader: true })
		let arrayOfStrings = [];
		const target = e.target.value;

		this.setState({
			headerSearchValue: target
		});

		this.clearSearchTimeout();

		this.headerSearchTimeout = setTimeout(() => {
			arrayOfStrings = this.state.headerSearchValue.length > 0 ? this.state.headerSearchValue.match(/\S+/g) : [];
			if (arrayOfStrings.length > 0) {
				return bc.products.searchProductsByTagsSynced(arrayOfStrings)
					.then((params) => {
						if (params !== null || this.state.headerSearchValue !== "") {
							this.setState({
								showLoader: false,
								searching: false,
								showSearchedItems: true,
								searchedItems: params.filter(item => item.quantity > 0)
							});

							if (this.props.slideSearchBox) {
								this.props.fillSearchValue(target);
							}
						}
					})
					.catch((err) => {
						if (this.state.headerSearchValue === "") {
							this.setState({
								showLoader: false,
								searching: false,
								showSearchedItems: false,
								searchedItems: []
							})
						}
						console.error("Error fetching products searched by tags", err);
					});
			} else {
				this.setState({
					showLoader: false,
					searching: false,
					showSearchedItems: false,
					searchedItems: []
				})
			}
		}, 100, true);
	}

	/**
    * Clears any value left in the search field
	*/

	clearSearchField() {
		this.setState({ headerSearchValue: "", showLoader: true });

		let arrayOfStrings = [];

		this.clearSearchTimeout();

		this.headerSearchTimeout = setTimeout(() => {
			arrayOfStrings = this.state.headerSearchValue.length > 0 ? this.state.headerSearchValue.match(/\S+/g) : [];
			if (arrayOfStrings.length > 0) {
				return bc.products.searchProductsByTagsSynced(arrayOfStrings)
					.then((params) => {
						if (params !== null || this.state.headerSearchValue !== "") {
							this.setState({
								showLoader: false,
								searching: false,
								showSearchedItems: true,
								searchedItems: params.filter(item => item.quantity > 0)
							});
						}
					})
					.catch((err) => {
						if (this.state.headerSearchValue === "") {
							this.setState({
								showLoader: false,
								searching: false,
								showSearchedItems: false,
								searchedItems: []
							})
						}
						console.error("Error fetching products searched by tags", err);
					});
			} else {
				this.setState({
					showLoader: false,
					searching: false,
					showSearchedItems: false,
					searchedItems: []
				})
			}
		}, 100, true);
	}

	/**
    * Clears search timeout
	*/

	clearSearchTimeout() {
		clearTimeout(this.headerSearchTimeout);
	}

	/**
    * Fetching user statistics
	*/

	fetchUserStatistics() {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			return bc.stats.userOrders(this.props.user.me.id, "month", this.props.statisticsFilter.month, this.props.statisticsFilter.year)
				.then((params) => {
					if (params.ok) {
						this.props.fetchUserStatistics(params.data);
					}
				})
				.catch((err) => {
					console.error("Error fetching Statistics [Orders]", err);
				})
		}
	}

	/**
    * Fetching user statistics [PARAMS]
	*/

	fetchStatisticsParams(type, month, year) {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			return bc.stats.userOrders(this.props.user.me.id, type, month, year)
				.then((params) => {
					if (params.ok) {
						this.props.fetchUserStatistics(params.data);
					}
				})
				.catch((err) => {
					console.error("Error fetching Statistics [Orders]", err);
				})
		}
	}

	/**
    * Fetch list of favorited items
	*/

	fetchFavorites() {
		if (this.props.auth.isAuthenticated && this.props.auth.authUser !== null) {
			return bc.bookmarks.getCategoryBookmarks(this.props.user.me.id, "product")
				.then((params) => {
					if (params.data !== null) {
						this.props.fetchFavorites(params.data);
						return true;
					}
				})
				.catch((err) => {
					console.error("Error fetching Bookmarks", err);
					return false;
				})
		}
	}

	/**
    * Fetch lists
	*/

	fetchLists() {
		return bc.cart.getLists(this.props.user.me.id, "product")
			.then((params) => {
				if (params.ok) {
					this.props.getLists(params.data);
					return true;
				}
			})
			.catch((err) => {
				console.error("Error fetching Bookmarks", err);
				return false;
			});
	}

	/**
    * Remove lists
	*/

	deleteList(id) {
		return bc.cart.deleteList(id)
			.then((res) => {
				if (res.ok) {
					this.goToDefaultCart();
					this.removeListItems(this.props.listItemToDelete);
				}
			})
			.catch((err) => {
				console.error("Error removing list!", err);
			})
	}

	/**
	* Pick Default cart from Lists dropdown
	*/

	goToDefaultCart() {
		this.props.chooseCart(this.props.defaultCartData);
		this.props.createCartStart(this.props.defaultCartData);
		this.props.getCartItemsList(this.props.defaultCartData.id);
	}

	/**
	* Get Pages (...such as: FAQ, Privacy Policty etc.)
	*/

	getPages() {
		return bc.pages.getAll()
			.then((res) => {
				if (res.ok) {
					this.props.getPages(res.data);
				}
			})
			.catch((err) => console.error(err));
	}

	/**
	* Fetch users' orders
	*/

	fetchUserOrders() {
		return bc.orders.getMyOrders()
			.then((res) => {
				if (res.ok) {
					this.props.fetchUserOrders(res.data);
				}
			})
			.catch((err) => console.error(err));
	}

	/**
	* Get All Carts
	*/

	getAllCarts() {
		return bc.cart.getAllCarts()
			.then((res) => {
				if (res.ok) {
					this.props.getAllCarts(res.data);
				}
			})
			.catch((err) => console.error(err));
	}

	/**
	* If Search Box is visible, it should slide up on user scroll
	*/

	HideSearch(e) {
		e.stopPropagation();
		e.preventDefault();
		if (this.props.slideSearchBox) {
			this.props.hideSearch();
		}
	}

	/**
	* Clear search value and hide searched items data to regular Raft Products
	*/

	ClearSearchValue() {
		this.clearSearchField();
		this.props.clearSearchValue();
	}

	/**
	* Cookies diclaimer is hidden on button click
	*/

	HideCookiesDisclaimer() {
		localStorage.setItem("usr-cks-dsclmr", true);
		this.setState({
			showCookiesDisclaimer: false
		})
	}

	/**
	* Close Tour
	*/

	CloseTour() {
		localStorage.setItem("usr-wlkthrgh", true);
		this.setState({
			showUserWalkthrough: false
		});
	}

	/**
	* Open Tour
	*/

	OpenTour() {
		this.props.closeMenu();
		this.props.history.push("/");
		localStorage.removeItem("usr-wlkthrgh");
		setTimeout(() => {
			this.setState({
				showUserWalkthrough: true
			});
		}, 1000)
	}

	/**
    * Opens the login popup
	*/

	OpenLoginPopup() {
		this.props.openLoginPopup("login");
		this.props.history.push("/login");
		this.setState({
			badLogin: false
		});
	}

	handleAddToHomescreenClick() {
		alert(`
		  1. Кликнете на Share копчето
		  2. Потоа кликнете на "Add to Home Screen" копчето`);
	};

	render() {

		const { location } = this.props;
		const { lang } = this.props;
		const isModal = !!(
			location.state &&
			location.state.modal &&
			this.previousLocation !== location
		);

		const web_steps = [
			{
				selector: '',
				content: <Walkthrough
					title={STEP_ONE.title}
					content={STEP_ONE.content}
					media={STEP_ONE.media}
				/>,
				position: 'center',
			},
			{
				selector: '.raft-card',
				content: <Walkthrough
					title={STEP_TWO.title}
					content={STEP_TWO.content}
					media={STEP_TWO.media}
				/>,
				position: 'center',
			},
			{
				selector: '.raft-card__add-to-cart',
				content: <Walkthrough
					title={STEP_THREE.title}
					content={STEP_THREE.content}
					media={STEP_THREE.media}
				/>,
				// position: 'center',
			},
			{
				selector: '.raft-card',
				content: <Walkthrough
					title={STEP_FOUR.title}
					content={STEP_FOUR.content}
					media={STEP_FOUR.media}
				/>,
				position: 'center',
			},
			{
				selector: '.cart__payments',
				content: <Walkthrough
					title={STEP_FIVE.title}
					content={STEP_FIVE.content}
					media={STEP_FIVE.media}
				/>
			},
		]

		// const mobile_steps = [
		// 	{
		// 		selector: '',
		// 		content: <Walkthrough
		// 			title={MOBILE_STEP_ONE.title}
		// 			content={MOBILE_STEP_ONE.content}
		// 			media={MOBILE_STEP_ONE.media}
		// 		/>,
		// 		position: 'center'
		// 	},
		// 	{
		// 		selector: '.raft-card',
		// 		content: <Walkthrough
		// 			title={MOBILE_STEP_TWO.title}
		// 			content={MOBILE_STEP_TWO.content}
		// 			media={MOBILE_STEP_TWO.media}
		// 		/>,
		// 		position: 'center'
		// 	},
		// 	{
		// 		selector: '.raft-card__add-to-cart',
		// 		content: <Walkthrough
		// 			title={MOBILE_STEP_THREE.title}
		// 			content={MOBILE_STEP_THREE.content}
		// 			media={MOBILE_STEP_THREE.media}
		// 		/>,
		// 		position: 'center'
		// 	},
		// 	{
		// 		selector: '.mobile__header-cart',
		// 		content: <Walkthrough
		// 			title={MOBILE_STEP_FIVE.title}
		// 			content={MOBILE_STEP_FIVE.content}
		// 			media={MOBILE_STEP_FIVE.media}
		// 		/>,
		// 		position: 'top'
		// 	},
		// ]

		return (
			<IntlProvider locale={lang} messages={strings[lang]} textComponent={React.Fragment}>
				<div id="app" onScroll={this.HideSearch}>
					{this.state.addToHomescreen ?
						<div id="safari-home-screen">
							<AddToHomescreen onAddToHomescreenClick={this.handleAddToHomescreenClick} />
						</div> : null}
					<BrowserView renderWithFragment>
						<Tour
							steps={web_steps}
							isOpen={this.state.showUserWalkthrough && !this.state.showCookiesDisclaimer}
							disableInteraction={true}
							className="tour-component"
							maskClassName="tour-component-overlay"
							onRequestClose={this.CloseTour}
						/>
					</BrowserView>

					{/* <MobileView renderWithFragment>
						<Tour
							steps={mobile_steps}
							isOpen={this.state.showUserWalkthrough && !this.state.showCookiesDisclaimer}
							disableInteraction={true}
							className="tour-component-mobile"
							maskClassName="tour-component-overlay"
							onRequestClose={this.CloseTour}
						/>
					</MobileView> */}

					{this.state.showCookiesDisclaimer ? <Animated style={{ animationDuration: "0.2s" }} className="cookies-disclaimer" animationIn="fadeIn" animationOut="fadeOut" animationInDuration="fast" isVisible={this.state.showCookiesDisclaimer}><CookiesDisclaimer hideCookiesDisclaimer={this.HideCookiesDisclaimer} /></Animated> : null}

					{/* "POINTER EVENTS" div is shown as an overlay above every element in the DOM to prevent buggy user behaviour on search */}
					<MobileView renderWithFragment>
						{this.props.slideSearchBox ? <div onTouchMove={this.HideSearch} onClick={this.HideSearch} className="pointer-events"></div> : null}
					</MobileView>

					<MobileView renderWithFragment>
						<div className={"mobile__cart " + (this.props.showCart ? "show--cart " : "hide--cart ") + (this.props.savedCarts.length !== 0 ? "expand__cart" : "")}>
							<MobileCart
								productsMap={this.props.products.productsMap}
								products={this.props.products.products}
								cartItems={this.props.cartItems}
							/>
						</div>
					</MobileView>

					<div className={"app__container " + (this.props.menu ? "slide__menu--on " : "slide__menu--off ") + (this.props.showCart ? "cart__overflow" : "") + (this.props.popupOpened ? "popup--noscroll" : "")}>
						<Menu
							categories={this.props.categories.mainCategories}
							auth={this.props.auth}
							user={this.props.user.me}
							logout={this.logout}
							openTour={this.OpenTour}
						/>
						<div className={"app-container " + ((this.props.popupOpened || this.props.menu) ? "popup--opened " : "popup--hidden ") + (this.props.history.location.pathname === "/settings" ? "mobile-route--overflow" : "")}>
							<div className="menu_oppened--overlay" onClick={() => this.props.closeMenu()}></div>
							{
								this.props.location.pathname.indexOf("/confirm") === 0 ?
									null :
									<Header
										auth={this.props.auth}
										user={this.props.user.me}
										logout={this.logout}
										searchValue={this.state.headerSearchValue}
										clearSearchField={this.clearSearchField}
										handleSearch={this.handleSearchValue}
										searchedItems={this.state.showSearchedItems}
										openLoginPopup={this.OpenLoginPopup}
									/>
							}
							<BrowserView renderWithFragment>
								<TrackOrder orders={this.props.user.userOrders} />
							</BrowserView>
							<div className={"main-content-component wrapper " + (this.props.paymentPopup ? "pointer__events" : "")}>
								<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={this.props.location.pathname === "/order-history"}>
									<Route exact path="/order-history" render={(props) => {
										return (
											<OrderHistory
												products={this.props.products.products}
												productsMap={this.props.products.productsMap}
												orders={this.props.user.userOrders}
												openTour={this.OpenTour}
											/>
										)
									}} />
								</Animated>
								<Route path="/settings" render={() => {
									return (
										<Settings
											user={this.props.user.me}
											auth={this.props.auth}
											userLocation={this.props.user.userLocation}
											userPreferences={this.props.user.userPreferences}
											getLocations={this.getUserLocations}
											userProfiles={this.props.user.userProfiles}
											getUser={this.getUserLoggedInData}
											getUserPreferences={this.getUserPreferences}
											pages={this.props.pages}
											openTour={this.OpenTour}
										/>
									)
								}} />
								<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={this.props.location.pathname === "/statistics"}>
									<Route exact path="/statistics" render={(props) => {
										return (
											<Statistics
												{...props}
												products={this.props.products.products}
												productsMap={this.props.products.productsMap}
												statistics={this.props.user.userStatistics}
												fetchStatistics={this.fetchStatisticsParams}
												openTour={this.OpenTour}
											/>
										)
									}} />
								</Animated>
								<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={this.props.location.pathname.indexOf("/help") === 0}>
									<Route path="/help" render={(props) => {
										return (
											<Help {...props} pages={this.props.pages} />
										)
									}} />
								</Animated>
								<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={this.props.location.pathname.indexOf("/confirm") === 0}>
									<Route path="/confirm/:id" component={Confirm} />
								</Animated>
								<div className={"raft--cart "}>
									<div className="columns">
										<Switch>
											<Route path="/" render={() => (
												<div>
													{
														this.props.location.pathname === "/order-history" ||
															this.props.location.pathname === "/favorites" ||
															this.props.location.pathname === "/settings" ||
															this.props.location.pathname === "/statistics" ||
															this.props.location.pathname.indexOf("/settings") === 0 ||
															this.props.location.pathname.indexOf("/help") === 0 ||
															this.props.location.pathname.indexOf("/confirm") === 0 ||
															this.props.location.pathname.indexOf("/order-history") === 0 ||
															isModal ?
															null :
															this.state.showLoader || this.state.searching ?
																<div className="spinner-container">
																	<div className="centered-spinner">
																		<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
																		<h2>Ги вчитуваме рафтовите</h2>
																	</div>
																</div> :
																<Fragment>
																	{
																		!this.state.showSearchedItems ?
																			<Home
																				mainCategories={this.props.categories.mainCategories}
																				products={this.props.products.products}
																				productsMap={this.props.products.productsMap}
																				pages={this.props.pages}
																				currentSubSlider={this.state.currentSubSlider}
																				openTour={this.OpenTour}
																			/> :
																			this.state.searchedItems.length <= 0 ?
																				<div className="spinner-container empty-search">
																					<div className="centered-spinner">
																						<div className="empty-icon"></div>
																						<h2>Не најдовме таков производ на нашите рафтови</h2>
																					</div>
																				</div> :
																				<SearchedProducts
																					products={this.state.searchedItems}
																					pages={this.props.pages}
																					searchedTerm={this.state.headerSearchValue}
																					clearSearchMobile={this.ClearSearchValue}
																					openTour={this.OpenTour}
																				/>
																	}
																</Fragment>
													}
													<Route path="/product/:id" render={(props) => {
														return (
															<Fragment>
																<BrowserView renderWithFragment>
																	<ProductPopup
																		{...props}
																		productsMap={this.props.products.productsMap}
																		products={this.props.products.products}
																		favorites={this.props.favorites}
																		user={this.props.user.me}
																		auth={this.props.auth}
																		fetchFavorites={this.fetchFavorites}
																	/>
																</BrowserView>
																<MobileView renderWithFragment>
																	<div id="product--mobile">
																		<MobileProductPopup
																			{...props}
																			productsMap={this.props.products.productsMap}
																			products={this.props.products.products}
																			favorites={this.props.favorites}
																			user={this.props.user.me}
																			auth={this.props.auth}
																			fetchFavorites={this.fetchFavorites}
																		/>
																	</div>
																</MobileView>
															</Fragment>
														)
													}} />
													<Route path="/login" render={(props) => {
														return (
															<LoginPopup
																{...props}
																login={this.login}
																badLogin={this.state.badLogin}
																buttonLoader={this.state.buttonClickLoader}
																openRecoverPasswordPopup={this.openRecoverPasswordPopup}
															/>
														)
													}} />
													<Route path="/recover-password" render={(props) => {
														return (
															<RecoverPassword
																{...props}
																badRecoveryEmail={this.state.badRecoveryEmail}
																buttonLoader={this.state.buttonClickLoader}
																sendPassordRecoveryMail={this.sendPassordRecoveryMail}
															/>
														)
													}} />
													<Route path="/reset-password/:hash" render={(props) => {
														return (
															<ResetPasswordForm
																{...props}
																resetPassword={this.resetPassword}
																badPasswordReset={this.state.badPasswordReset}
																badPasswordResetMessage={this.state.badPasswordResetMessage}
																buttonLoader={this.state.buttonClickLoader}
															/>
														)
													}} />
													<Route path="/register" render={(props) => {
														return (
															<RegisterPopup
																{...props}
																badRegister={this.state.badRegister}
																buttonLoader={this.state.buttonClickLoader}
															/>
														)
													}} />
													{/* <Route path="/cart/:id" render={(props) => {
														return (
															<div>
																<BrowserView>
																	<div id="web__payment-container">
																		<PaymentPopup
																			{...props}
																			auth={this.props.auth}
																			user={this.props.user.me}
																			userLocation={this.props.user.userLocation}
																			getLocations={this.getUserLocations}
																			productsMap={this.props.products.productsMap}
																		/>
																	</div>
																</BrowserView>
																<MobileView>
																	<div id="mobile__payment-container">
																		<PaymentPopup
																			{...props}
																			auth={this.props.auth}
																			user={this.props.user.me}
																			userLocation={this.props.user.userLocation}
																			getLocations={this.getUserLocations}
																			productsMap={this.props.products.productsMap}
																		/>
																	</div>
																</MobileView>
															</div>
														)
													}} /> */}
													{/* <Route 
														path="/cart/:id" 
														component={CheckoutPopup} 

														auth={this.props.auth}
														user={this.props.user.me}
														userLocation={this.props.user.userLocation}
														getLocations={this.getUserLocations}
														productsMap={this.props.products.productsMap}
													/> */}
													<Route
														path="/cart/:id"
														render={props => {
															return <CheckoutPopup 
																auth={this.props.auth}
																user={this.props.user.me}
																userLocation={this.props.user.userLocation}
																getLocations={this.getUserLocations}
																productsMap={this.props.products.productsMap}
															/>
														}}
													/>
													<div id="list__popup-container">
														<Route path="/create-list/" render={(props) => {
															return (
																<ListPopup
																	{...props}
																	auth={this.props.auth}
																	user={this.props.user.me}
																	lists={this.props.lists}
																	cartItems={this.props.cartItems}
																	cartId={this.props.cartData.id}
																	chooseCart={this.chooseCart}
																	clearCart={this.clearCartOnLogout}
																	createCart={this.createCart}
																	updateCartItemsList={this.updateCartItemsList}
																	updateListItems={this.updateListItems}
																	defaultCart={this.props.defaultCartData}
																	getCartItems={this.getCartItems}
																/>
															)
														}} />
													</div>
													<div id="disclaimer-container">
														<Route path="/disclaimer" component={DisclaimerPopup} />
													</div>
													<Route path="/session-expired" component={SessionReminderPopup} />
													{/* <Route path="/save-cart/:id" component={SaveCartPopup} /> */}
													{/* <Route path="/edit-cart/:id" component={EditSavedCartPopup} /> */}
													<Route path="/success" component={OrderSuccess} />
													<Route path="/fail" component={OrderError} />
													<Route path="/delete-list/:id" render={(props) => {
														return (
															<ConfirmationPopup
																{...props}
																removeList={this.deleteList}
															/>
														)
													}} />
												</div>
											)} />
										</Switch>
										<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" isVisible={this.props.location.pathname === "/favorites"}>
											<Route exact path="/favorites" render={(props) => {
												return (
													<Favorites
														{...props}
														favorites={this.props.favorites}
														fetchFavorites={this.fetchFavorites}
														productsMap={this.props.products.productsMap}
														products={this.props.products.products}
														openTour={this.OpenTour}
													/>
												)
											}} />
										</Animated>
									</div>
									{
										(this.props.location.pathname !== "/order-history" && this.props.location.pathname.indexOf("/settings") < 0 && this.props.location.pathname.indexOf("/help") < 0 && this.props.location.pathname !== "/statistics" && this.props.location.pathname.indexOf("/confirm") < 0 && this.props.location.pathname !== "/product/:id") && this.props.location.pathname.indexOf("/order-history") < 0 ?
											<BrowserView viewClassName="cart">
												<Cart
													productsMap={this.props.products.productsMap}
													products={this.props.products.products}
													cartItems={this.props.cartItems}
												/>
											</BrowserView> : null
									}
								</div>
							</div>
						</div>
						{
							this.state.showToast ?
								<Toast
									show={this.state.showToast}
									type={this.state.toastType}
									message={this.state.toastMessage}
									code={this.state.toastCode}
									hideToast={this.hideToast}
								/> :
								null
						}
					</div>
				</div>
			</IntlProvider>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		popupProductData: state.productPopup.popupProps,
		popupOpened: state.productPopup.opened,
		auth: state.authPopup.auth,
		menu: state.menuReducer.opened,
		showCart: state.showCartReducer.showCart,
		paymentPopup: state.paymentReducer.paymentPopup,
		savedCarts: state.saveCartReducer.savedCarts,
		lang: state.locale.lang,
		user: state.userReducer,
		categories: state.categoriesReducer,
		products: state.productsReducer,
		favorites: state.favoritesReducer.favorites,
		cartData: state.shoppingCartReducer.cart,
		allCarts: state.shoppingCartReducer.allCarts,
		defaultCartData: state.shoppingCartReducer.defaultCart,
		cartItems: state.shoppingCartReducer.cartItems,
		lists: state.shoppingCartReducer.lists,
		listItemToDelete: state.saveCartReducer.deleteItemReady,
		pages: state.pagesReducer.pages,
		statisticsFilter: state.userReducer.statisticsFilter,
		slideSearchBox: state.searchReducer.show,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		closeMenu: () => {
			dispatch(closeMenu())
		},
		isLoggedIn: (data) => {
			dispatch(isLoggedIn(data))
		},
		closeDropdown: (data) => {
			dispatch(closeDropdown(data))
		},
		getMe: (user) => {
			dispatch(getMe(user))
		},
		fetchUserLocation: (location) => {
			dispatch(fetchUserLocation(location))
		},
		fetchUserProfiles: (profile) => {
			dispatch(fetchUserProfiles(profile))
		},
		fetchMainCategories: (categories) => {
			dispatch(getAllMainCategories(categories))
		},
		fetchAllCategories: (categories) => {
			dispatch(getAllCategories(categories))
		},
		fetchProducts: (products) => {
			dispatch(getAllProducts(products))
		},
		createProductCountMap: (products) => {
			dispatch(createProductCountMap(products))
		},
		fetchUserPreferences: (preferences) => {
			dispatch(fetchUserPreferences(preferences));
		},
		fetchUserStatistics: (stats) => {
			dispatch(fetchUserStatistics(stats));
		},
		fetchUserOrders: (orders) => {
			dispatch(fetchUserOrders(orders));
		},
		fetchFavorites: (favorites) => {
			dispatch(fetchFavorites(favorites));
		},
		createCartStart: (cartData) => {
			dispatch(createCartStart(cartData));
		},
		defaultCart: (cart) => {
			dispatch(defaultCart(cart));
		},
		getCartItems: () => {
			dispatch(getCartItems());
		},
		getCartItemsStart: () => {
			dispatch(getCartItemsStart());
		},
		clearCartOnLogout: () => {
			dispatch(clearCartOnLogout());
		},
		getLists: (lists) => {
			dispatch(getLists(lists));
		},
		chooseCart: (cart) => {
			dispatch(chooseCart(cart));
		},
		updateCartItemsList: (cartItems) => {
			dispatch(updateCartItemsList(cartItems));
		},
		updateListItems: (listItem) => {
			dispatch(updateListItems(listItem));
		},
		getCartItemsList: (items) => {
			dispatch(getCartItemsList(items));
		},
		removeListItems: (listItem) => {
			dispatch(removeListItems(listItem));
		},
		getPages: (pages) => {
			dispatch(getPages(pages));
		},
		getAllCarts: (allCarts) => {
			dispatch(getAllCarts(allCarts));
		},
		clearUserObject: () => {
			dispatch(clearUserObject());
		},
		clearUserStatistics: () => {
			dispatch(clearUserStatistics());
		},
		hideSearch: () => {
			dispatch(hideSearch());
		},
		fillSearchValue: (value) => {
			dispatch(fillSearchValue(value));
		},
		clearSearchValue: () => {
			dispatch(clearSearchValue());
		},
		openLoginPopup: (type) => {
			dispatch(showAuthPopup(type));
		},
	}
}

App = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));