import React from "react";
import placeholder from "../assets/images/lazy-load.svg";

const ProductImage = (props) => {

		const src = props.image ? `${process.env.REACT_APP_MINIO_CDN}/products/${props.image.filter(i => i.cover)[0].path.replace(/\ /g, '%20')}` : null;
		return (
			<picture className="centered-data__picture">
				{
					props.image !== undefined
						? <source srcSet={src} />
						: null
				}
				<img src={placeholder} loading="lazy" alt="Placeholder" />
			</picture>
		)
}

export default ProductImage;
