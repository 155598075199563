import { useEffect, useState } from 'react';

import oblax from "../../../callsetter";
import {Order} from '@oblax/sdk-js';

import Datetime from "../../ui/Datetime";
import Select from '../../ui/Select';

const CheckoutPopupStepDeliveryDatetime = (props) => {

    // const testSkipDates = [
    //     {
    //         date_end: null,
    //         date_start: "2021-03-24T00:00:00Z",
    //         day: 0,
    //         id: "6NF20j4ojfRzkbYbvkZ838UR",
    //         repeat: true,
    //         type: "DATE",
    //     },
    //     {
    //         date_end: null,
    //         date_start: "2021-03-25T00:00:00Z",
    //         day: 0,
    //         id: "6NF20j4ojfRzkbYbvkZ838UZ",
    //         repeat: true,
    //         type: "DATE",
    //     }
    // ];

    const [timeslots, setTimeslots] = useState([]);
    const [skipDates, setSkipDates] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                let ds = await oblax.orders.getDeliverySchedules();
                // console.log(ds.data);
                setSkipDates(ds.data);
            } catch(err) {
                console.error(err);
            }
        })();
    }, []);
    
    useEffect(() => {
        // getFirstWorkingDay
        if(skipDates.length > 0) {
            let freeDate = getFirstWorkingDay();
            // check if same
            let pd = new Date(props.data.delivery_date);
            if(pd.getUTCDate() !== freeDate.getUTCDate()) {
                props.onChange({
                    target: {
                        name: 'delivery_date',
                        value: freeDate
                    }
                });
            }
        }
    }, [skipDates])

    useEffect(() => {
        (async () => {
            let d = props.data.delivery_date ? new Date(props.data.delivery_date) : new Date();
            let year = d.getFullYear();
            let month = (d.getMonth() + 1) < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
            let date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            let dateParam = `${year}-${month}-${date}`;
            // console.log('getting timeslots for date', dateParam);
            let ts = await oblax.orders.getTimeslotsForDate(dateParam);
            setTimeslots(ts.data.map(t => {
                return {
                    label: `${formatTime(t.start_time)} - ${formatTime(t.end_time)}`,
                    value: t.id
                }
            }));
        })();
    }, [props.data.delivery_date]);

    const formatTime = (time) => {
        let t = new Date(time);
        let hours = t.getUTCHours() < 10 ? `0${t.getUTCHours()}` : t.getUTCHours();
        let minutes = t.getUTCMinutes() < 10 ? `0${t.getUTCMinutes()}` : t.getUTCMinutes();
        return `${hours}:${minutes}`;
    };

    const getFirstWorkingDay = () => {
        let found = false;
        var today = new Date();
        if(today.getUTCHours() > 6) { // if workhours started, start counting from tomorrow
            today.setUTCDate(today.getUTCDate() + 1);
        }
        while(!found) {
            var match = false;
            for(let sd of skipDates) {
                switch(sd.type) {
                    case 'DAY':
                        let d = today.getDay() === 0 ? 6 : today.getDay() - 1;
                        match = d === sd.day;
                    break;
                    case 'DATE':
                        let date = new Date(sd.date_start);
                        date.setUTCHours(12);
                        date.setUTCMinutes(0);
                        date.setUTCSeconds(0);
                        if (sd.repeat) {
                            match = (
                                today.getUTCMonth() === date.getUTCMonth() &&
                                today.getUTCDate() === date.getUTCDate()
                            );
                        } else {
                            match = (
                                today.getUTCFullYear() === date.getUTCFullYear() &&
                                today.getUTCMonth() === date.getUTCMonth() &&
                                today.getUTCDate() === date.getUTCDate()
                            );
                        }
                    break;
                    case 'DATE_RANGE':
                        let dateStart = new Date(sd.date_start);
                        dateStart.setUTCDate(dateStart.getUTCDate());
                        dateStart.setUTCHours(12);
                        dateStart.setUTCMinutes(0);
                        dateStart.setUTCSeconds(0);
                        let dateEnd = new Date(sd.date_end);
                        dateEnd.setUTCDate(dateEnd.getUTCDate());
                        dateEnd.setUTCHours(12);
                        dateEnd.setUTCMinutes(0);
                        dateEnd.setUTCSeconds(0);
                        if (sd.repeat) {
                            dateStart.setFullYear(today.getUTCFullYear());
                            dateEnd.setFullYear(today.getUTCFullYear());
                            match = (
                                today.getTime() >= dateStart.getTime() &&
                                today.getTime() <= dateEnd.getTime()
                            );
                        } else {
                            match = (
                                today.getTime() >= dateStart.getTime() &&
                                today.getTime() <= dateEnd.getTime()
                            );
                        }
                        
                    break;
                }
                if (match) break;
            }
            if(match) {
                today.setDate(today.getDate() + 1);
            } else {
                found = true;
            }
        }
        return today;
    };

    return (
        <div className="workflow-step__delivery-datetime">
            <div className="workflow-step__form-container">
                <Datetime 
                    label="Датум на испорака"
                    name="delivery_date"
                    value={props.data.delivery_date}
                    error={props.errors.delivery_date.error}
                    onChange={props.onChange}
                    skipDates={skipDates}
                />
                <Select 
                    label="Термин за испорака" 
                    options={timeslots} 
                    firstLineEmpty={true}
                    name="delivery_timeslot"
                    value={props.data.delivery_timeslot}
                    error={props.errors.delivery_timeslot.error}
                    onChange={props.onChange}
                    placeholder="Одберете термин"
                />
            </div>
            <p className="workflow-step__info-container">Изберете го датумот и терминот за кој сте сигурни дека ќе бидете во можност да ја примите Вашата нарачка.<br/><br/>Нашите доставувачи максимално ќе го почитуваат датумот и времето, а ако се очекува некакво доцнење, ќе Ве информираат навремено.<br/><br/>Датумите и временските термини за испорака важат само за испораки во Скопје и околината.<br/><br/>За нарачките во другите градови, ќе бидете контактирани за точниот датум на испорака, кое е обично за 2-3 дена.</p>
        </div>
    );
};

export default CheckoutPopupStepDeliveryDatetime;