// **WALKTHROUGH_STEPS [WEB]** //
export const STEP_ONE = {
	title: "Добредојдовте на Raft.mk",
	content: "Ова упатство ќе ви помогне како да го извлечето најдоброто искуство во нашата онлајн продавница. Ако мислите дека не ви е потребно ова упатство можете да го исклучите на копчето 'x'.",
	media: ""
}

export const STEP_TWO = {
	title: "Картичката како производ",
	content: "Сите картички распоредени на рафтот се производи. Картичките се всушност линкови на кои можете да кликнете ако сакате да ги видите Деталните Информации на производот.",
	media: "product-popup-HELP.mp4"
} 

export const STEP_THREE = {
	title: "Додавање на производ во кошничка, преку копче.",
	content: "Има повеќе начини како да додадете производ во вашата кошчничката. Еден од нив е преку Копчето во долниот лев агол.",
	media: "add-to-cart-via-button-HELP.mp4"	
}

export const STEP_FOUR = {
	title: "Додавање на производ во кошничка, преку влечење.",
	content: "Има повеќе начини како да додадете производ во вашата кошчничката. Вториот начин е преку влечење на Картичката и пуштање на истата во Кошничката",
	media: "add-to-cart-via-dnd-HELP.mp4"	
}

export const STEP_FIVE = {
	title: "Вашата кошничка",
	content: "Ако копчето ОДИ НА КАСА е црвено тоа значи дека имате производи во вашата кошничка. Кога веќе ја знаете сумата и сте спремни да платите, кликнете на копчето кое ќе ве однесе на последниот чекор каде ќе мора да внесете основни информации како Име и презиме, Адреса на испорака и слично.",
	media: "payment-popup-HELP.mp4"
}


// **WALKTHROUGH_STEPS [MOBILE]** //
export const MOBILE_STEP_ONE = {
	title: "Добредојдовте на Raft.mk",
	content: "Ова упатство ќе ви помогне како да го извлечето најдоброто искуство во нашата онлајн продавница. Ако мислите дека не ви е потребно ова упатство можете да го исклучите на копчето 'x'.",
	media: ""
}

export const MOBILE_STEP_TWO = {
	title: "Картичката како производ",
	content: "Сите картички распоредени на рафтот се производи. Картичките се всушност линкови на кои можете да кликнете ако сакате да ги видите Деталните Информации на производот.",
	media: "mobile-product-popup-help.mp4"
} 

export const MOBILE_STEP_THREE = {
	title: "Додавање на производ во кошничка, преку копче.",
	content: "Има повеќе начини како да додадете производ во вашата кошчничката. Еден од нив е преку Копчето во долниот лев агол.",
	media: "mobile-add-to-cart-via-button.mp4"	
}

export const MOBILE_STEP_FIVE = {
	title: "Вашата кошничка",
	content: "Кликнете на копчето кое ќе ви ја покаже вашата кошничка и ќе ви даде инструкции како да платите.",
	media: "mobile-payment-popup-help.mp4"
}