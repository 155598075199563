export const showCart = () => {
	return {
		type: "SHOW_CART"
	}
};

export const hideCart = () => {
	return {
		type: "HIDE_CART"
	}
}