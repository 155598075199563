const initalState = {
	cartItems: [],
	cartItem: {},
	cart: {},
	defaultCart: {
		name: "Основна кошничка"
	},
	lists: [],
	allCarts: [],
	singleOrder: {},
	newOrder: {}
}

const shoppingCartReducer = (state = initalState, action) => {
	switch (action.type) {
		case "CREATE_CART":
			return {
				...state,
				cart: action.payload
			}
		case "DEFAULT_CART":
			return {
				...state,
				defaultCart: {
					...state.defaultCart,
					...action.payload
				}
			}
		case "GET_CART_ITEMS":
			return {
				...state,
				cartItems: action.payload
			}
		case "ADD_TO_CART":
			return {
				...state,
				cartItems: [
					action.payload,
					...state.cartItems,
				]
			}
		case "UPDATE_CART_ITEM":
			return {
				...state,
				cartItems: state.cartItems.map((item) => {
					return item.product_id !== action.payload.product_id ? item : { ...item, item_count: action.payload.item_count }
				})
			}
		case "DELETE_CART_ITEM":
			return {
				...state,
				cartItems: [
					...state.cartItems.filter((item) => {
						return item.product_id !== action.payload.product_id;
					})
				]
			}
		// case "GET_CART_ITEMS":
		// 	return {
		// 		...state,
		// 		cartItems: [
		// 			...action.payload
		// 		]
		// 	}
		case "INCREMENT":
			return {
				...state,
				cartItems: state.cartItems.map((item) => {
					return item.product_id !== action.payload.product_id ? item : { ...item, item_count: item.item_count + 1 }
				})
			}
		case "DECREMENT":
			return {
				...state,
				cartItems: state.cartItems.map((item) => {
					return item.product_id !== action.payload.product_id ? item : { ...item, item_count: item.item_count > 1 ? item.item_count - 1 : item.item_count }
				})
			}
		case "CLEAR_CART_ON_LOGOUT":
			return {
				...state,
				cartItems: []
			}
		case "FETCH_LISTS":
			return {
				...state,
				lists: action.payload
			}
		case "UPDATE_CART_ITEMS_LIST":
			return {
				...state,
				cartItems: [
					action.payload,
					...state.cartItems
				]
			}
		case "UPDATE_LIST_ITEMS":
			return {
				...state,
				lists: [
					action.payload,
					...state.lists
				]
			}
		case "REMOVE_LIST_ITEMS":
			return {
				...state,
				lists: [
					...state.lists.filter((item) => {
						return item.id !== action.payload.id;
					})
				]
			}
		case "GET_ALL_CARTS":
			return {
				...state,
				allCarts: action.payload
			}
		case "SINGLE_ORDER":
			return {
				...state,
				singleOrder: action.payload
			}
		case "CREATE_ORDER":
			return {
				...state,
				newOrder: action.payload
			}
		default: return state;
	}
}

export default shoppingCartReducer;