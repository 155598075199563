import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { Animated } from "react-animated-css";

const SessionReminderPopup = () => {

    let[showP, setShowP] = useState(true);
    let history = useHistory();

    const closePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push("/");
        setTimeout(() => {
            window.location.reload();
        }, 100)
    };

    return ( 
        <div className={"product__popup confirmation__popup"} id="confirmation__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={ showP } >
					<div className="product__popup--overlay"></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={ showP }>
					<div className="popup__header">
						<div className="popup__header--block">
							<h2>Потсетник</h2>
						</div>
					</div>

					<div className="popup__content">
						<h2 className="popup__disclaimer">Вашата сесија е истечена. За да не ги изгубите вашите податоци, освежете го прозорецот.</h2>
					</div>

					<div className="popup__buttons">
						<button aria-label="refresh" className="button--primary" onClick={ closePopup }>Освежи</button>
					</div>
				</Animated>
			</div>
     );
}
 
export default SessionReminderPopup;
