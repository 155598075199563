import { useState } from 'react';

import DateTime from "react-datetime";

import './style.css';

const Datetime = (props) => {
    let error = props.error ? 'error' : '';

    const onChange = (e) => {
        let out = {
            target: {
                name: props.name,
                value: e.format()
            }
        };
        props.onChange(out);
    };

    const normalizeTime = (date) => {
        date.setUTCHours(12);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        return date;
    }

    const validDates = (current) => {
        current = new Date(current.format());
        current = normalizeTime(current);
        let match = false;
        
        let now = new Date();
        now.setUTCHours(1);
        now.setUTCMinutes(0);
        now.setUTCSeconds(0);
        if(current.getTime() < now.getTime()){
            return false;
        }

        if(props.skipDates) {
            for(let i in props.skipDates) {
                switch(props.skipDates[i].type) {
                    case 'DAY':
                        match = current.getDay() === props.skipDates[i].day;
                    break;
                    case 'DATE':
                        let date = new Date(props.skipDates[i].date_start);
                        date = normalizeTime(date);
                        if (props.skipDates[i].repeat) {
                            match = (
                                current.getUTCMonth() === date.getUTCMonth() &&
                                current.getUTCDate() === date.getUTCDate() - 1 
                            );
                        } else {
                            match = (
                                current.getUTCFullYear() === date.getUTCFullYear() &&
                                current.getUTCMonth() === date.getUTCMonth() &&
                                current.getUTCDate() === date.getUTCDate() - 1
                            );
                        }
                    break;
                    case 'DATE_RANGE':
                        // current.setUTCDate(current.getUTCDate() - 1);
                        let dateStart = new Date(props.skipDates[i].date_start);
                        dateStart.setUTCDate(dateStart.getUTCDate() - 1);
                        dateStart = normalizeTime(dateStart);
                        let dateEnd = new Date(props.skipDates[i].date_end);
                        dateEnd.setUTCDate(dateEnd.getUTCDate() - 1);
                        dateEnd = normalizeTime(dateEnd);
                        if(props.skipDates[i].repeat){
                            dateStart.setFullYear(current.getUTCFullYear());
                            dateEnd.setFullYear(current.getUTCFullYear());
                            match = (
                                current.getTime() >= dateStart.getTime() &&
                                current.getTime() <= dateEnd.getTime()
                            );
                        } else {
                            match = (
                                current.getTime() >= dateStart.getTime() &&
                                current.getTime() <= dateEnd.getTime()
                            );
                        }
                    break;
                }
                if (match) return false;
            }
        }
        return true;
    };

    return (
        <label className={`form-datetime ${error}`}>
            <span className="form-datetime__input-label">{props.label}</span>
            <DateTime 
                className="form-datetime__input-field" 
                onChange={onChange} 
                value={new Date(props.value)}
                dateFormat="DD.MM.YYYY"
                timeFormat={false}
                closeOnClickOutside={true}
                isValidDate={validDates}
            />
        </label>
    );
};

export default Datetime;