import React from "react";
import  ProductImage  from "./ProductImage";
import ProductTitle from "./shared/ProductTitle";

const RecommendedProduct = (props) => {
    return ( 
        <div className={"recommended__product"} onClick={props.goToRecomendedItem}>
				<ProductImage image={props.data.images} />

				<div className="recommended__titles">
					<span className="recommended__sub-title" title={props.data.description}>{props.data.description}</span>
					<ProductTitle
						class="popup__product-title"
						name={props.data.name}
						insertBreak={false}
					/>
				</div>
			</div>
     );
}
 
export default RecommendedProduct;
