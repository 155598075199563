export default [

    {
        "name": "Скопје",
        "name_latin": "Skopje",
        "code": "SK",
        "population": 467257,
        "zipcode": 1000
    },
    {
        "name": "Берово",
        "name_latin": "Berovo",
        "code": "BE",
        "population": 7002,
        "zipcode": 2330
    },
    {
        "name": "Битола",
        "name_latin": "Bitola",
        "code": "BT",
        "population": 74550,
        "zipcode": 7000
    },
    {
        "name": "Богданци",
        "name_latin": "Bogdanci",
        "code": "BO",
        "population": 6011,
        "zipcode": 1484
    },
    {
        "name": "Валандово",
        "name_latin": "Valandovo",
        "code": "VA",
        "population": 4402,
        "zipcode": 2460
    },
    {
        "name": "Велес",
        "name_latin": "Veles",
        "code": "VE",
        "population": 43716,
        "zipcode": 1400
    },
    {
        "name": "Виница",
        "name_latin": "Vinica",
        "code": "VI",
        "population": 10863,
        "zipcode": 2310
    },
    {
        "name": "Гевгелија",
        "name_latin": "Gevgelija",
        "code": "GE",
        "population": 15685,
        "zipcode": 1480
    },
    {
        "name": "Гостивар",
        "name_latin": "Gostivar",
        "code": "GV",
        "population": 35847,
        "zipcode": 1230
    },
    {
        "name": "Дебар",
        "name_latin": "Debar",
        "code": "DB",
        "population": 14561,
        "zipcode": 1250
    },
    {
        "name": "Делчево",
        "name_latin": "Delcevo",
        "code": "DE",
        "population": 11500,
        "zipcode": 2320
    },
    {
        "name": "Демир Капија",
        "name_latin": "Demir Kapija",
        "code": "DK",
        "population": 3275,
        "zipcode": 1442
    },
    {
        "name": "Демир Хисар",
        "name_latin": "Demir Hisar",
        "code": "DH",
        "population": 2593,
        "zipcode": 7420
    },
    {
        "name": "Кавадарци",
        "name_latin": "Kavadarci",
        "code": "KA",
        "population": 29188,
        "zipcode": 1430
    },
    {
        "name": "Кичево",
        "name_latin": "Kicevo",
        "code": "KI",
        "population": 27067,
        "zipcode": 6250
    },
    {
        "name": "Кочани",
        "name_latin": "Kocani",
        "code": "KO",
        "population": 28330,
        "zipcode": 2300
    },
    {
        "name": "Кратово",
        "name_latin": "Kratovo",
        "code": "KR",
        "population": 6924,
        "zipcode": 1360
    },
    {
        "name": "Крива Паланка",
        "name_latin": "Kriva Palanka",
        "code": "KP",
        "population": 14558,
        "zipcode": 1330
    },
    {
        "name": "Крушево",
        "name_latin": "Krusevo",
        "code": "KS",
        "population": 5330,
        "zipcode": 7550
    },
    {
        "name": "Куманово",
        "name_latin": "Kumanovo",
        "code": "KU",
        "population": 70842,
        "zipcode": 1300
    },
    {
        "name": "Македонски Брод",
        "name_latin": "Makednoski Brod",
        "code": "MB",
        "population": 3740,
        "zipcode": 6530
    },
    {
        "name": "Македонска Каменица",
        "name_latin": "Makedonska Kamenica",
        "code": "MK",
        "population": 5147,
        "zipcode": 2304
    },
    {
        "name": "Неготино",
        "name_latin": "Negotino",
        "code": "NE",
        "population": 13284,
        "zipcode": 1440
    },
    {
        "name": "Охрид",
        "name_latin": "Ohrid",
        "code": "OH",
        "population": 42033,
        "zipcode": 6000
    },
    {
        "name": "Пехчево",
        "name_latin": "Pehcevo",
        "code": "BE",
        "population": 3237,
        "zipcode": 2326
    },
    {
        "name": "Прилеп",
        "name_latin": "Prilep",
        "code": "PP",
        "population": 66246,
        "zipcode": 7500
    },
    {
        "name": "Пробиштип",
        "name_latin": "Probistip",
        "code": "PS",
        "population": 8714,
        "zipcode": 2210
    },
    {
        "name": "Радовиш",
        "name_latin": "Radovis",
        "code": "RA",
        "population": 16223,
        "zipcode": 2420
    },
    {
        "name": "Ресен",
        "name_latin": "Resen",
        "code": "RE",
        "population": 8748,
        "zipcode": 7310
    },
    {
        "name": "Свети Николе",
        "name_latin": "Sveti Nikole",
        "code": "BE",
        "population": 13746,
        "zipcode": 2220
    },
    {
        "name": "Струга",
        "name_latin": "Struga",
        "code": "BE",
        "population": 16559,
        "zipcode": 6330
    },
    {
        "name": "Струмица",
        "name_latin": "Strumica",
        "code": "BE",
        "population": 35311,
        "zipcode": 2400
    },
    {
        "name": "Тетово",
        "name_latin": "Tetovo",
        "code": "BE",
        "population": 52915,
        "zipcode": 1200
    },
    {
        "name": "Штип",
        "name_latin": "Stip",
        "code": "BE",
        "population": 43652,
        "zipcode": 2000
    }
];