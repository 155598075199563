const initialState = {
	popupProps: {},
	opened: false
};

const productPopup = (state = initialState, action) => {
	switch(action.type) {
		case "OPEN_POPUP":
			return {
				...state,
				popupProps: action.values,
				opened: true
			}
		case "CLOSE_POPUP":
			return Object.assign({}, state, {
				opened: false
			})
		default: return state
	}
}

export default productPopup;