import React from "react";
import { NavLink } from "react-router-dom";
import { isEmpty } from "../../helper";

import DiemLogo from '../../assets/images/diem-gp-marketi-logo.svg';
import STBLogo from '../../assets/images/stpbank.png';
import VisaLogo from '../../assets/images/visa-logo.png';
import MasterLogo from '../../assets/images/mastercard-logo.png';


const Footer = (props) => {

	
	 const openTour = () => {
	 	props.openTour();
	}

	const scrollToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

		const SitemapDescription = (props) => {
			let description = ""
			switch(props.permalink) {
				case "najcesto-postavuvani-prasanja":
					description = "Тука можете да ги најдете одговорите на најчесто поставуваните прашања кои што ги имаат нашите купувачи, во врска со функционирањето на raft.mk";
				break; 
				case "nacin-i-rok-na-isporaka":
					description = "Прочитајте повеќе за стандардите кои ги применуваме низ целиот процес на пакување и достава на Вашите нарачки";
				break; 
				case "pravila-uslovi-na-koristenje":
					description = "Со користење и купување преку веб аликацијата raft.mk, се согласувате со општите правила и услови за користење.";
				break; 
				case "kvalitet-i-cena-na-proizvod":
					description = "Гарантираме дека на raft.mk секогаш ќе најдете производи со одличен квалитет кои што имаат одлични цени.";
				break; 
				case "bezbednost-pri-plakjanje":
					description = "Технологиите кои што ги користиме на raft.mk, гарантираат максимална безбедност на транскациите и на Вашите податоци.";
				break;
				case "politika-na-privatnost":
					description = "Спроведуваме сериозни мерки за безбедно чување на Вашите лични податоци и никогаш нема да ги отстапиме на трета страна.";
				break;
				default: description = "";
			}
			return description;
		}

		return (
			<div id="footer">
				<div className="footer--block question">
					<h2>Дали ви треба помош?</h2>

					<p className="footer-block-description">Тука сме да Ви помогнеме за да го имате најдоброто искуство додека пребарувате и купувате на raft.mk. Кликнете за <span className="tour-footer-link" onClick={props.openTour}>​упатството за користење</span> или прочитајте подетално во некоја од темите подолу.</p><br/>
					<p className="footer-block-description">Можете директно да нѐ контатирате преку e-mail на contact@raft.mk, на телефон 075 262 405 од 09:00 до 17:00 часот, или пак преку <NavLink className="tour-footer-link" to="/help/contact">контакт</NavLink> формата доколку е потребно детално објаснување на Вашето барање.</p>
					<div className="supported-payment-cards">
						<div className="single-spc"><img alt="Стопанска Банка" src={STBLogo} /></div>
						<div className="single-spc"><img alt="Visa" src={VisaLogo} /></div>
						<div className="single-spc"><img alt="Mastercard" src={MasterLogo} /></div>
					</div>
				</div>

				<div className="footer--block sitemap">
					{!isEmpty(props.pages) ?
						<ul>
							{
								props.pages.map(page => {
									return (
										<li key={page.id} onClick={scrollToTop}>
											<NavLink activeClassName="active-helper" to={"/help/" + page.permalink}>{page.title}</NavLink>
											<span className="footer-items-description"><SitemapDescription permalink={page.permalink} /></span>
										</li>
									)
								})
							}
							<li onClick={scrollToTop}>
								<NavLink activeClassName="active-helper" to="/help/contact">Контакт</NavLink>
								<span className="footer-items-description">Доколку имате дополнителни прашања, сакате да предложите нови производи или пак сакате да започнете деловна соработка со нас, контактирајте нѐ веднаш.</span>
							</li>
						</ul> :

						null}
				</div>

				<div className="footer--block disclaimer">
					{/* <span>Улица Перо Наков 99-1/5</span> */}
					<div className="company">
						<img src={DiemLogo} alt="Diem-GP" />
						<span>
							Дием-ГП Маркети ДООЕЛ Скопје<br/>
							Подружница за електронска трговија - Дием-ГП Рафт<br/>
							Ул. 16-та Македонска бригада, бр.4 - Скопје<br/>
							ЕДБ: 4006990106080<br/>
							EМБС: 4134745<br/>
							contact@raft.mk
						</span>
					</div>
					<span className="powered-by">Powered by <a href="https://oblax.io" target="_blank">oblax.io</a></span>
				</div>
			</div>
		)
}

export default Footer;
