export default {
    mk: {
		/* GENERIC */
		"SAVE": "Зачувај",
		"DISCARD": "Откажи",
		"ALL_PRODUCTS": "сите производи",
		"GO_BACK": "врати",
		"BACK": "назад",
		"ADD": "додади",
		"SEND": "Испрати",
		"MKD": "ден.",
		"KG": "кг.",
		"TYPE": "Тип",
		"VALUE": "Вредност",
		"UNIT": "Единица",
		"EDIT": "промени",
		"FAT": "Маснотии",
		"CARBOHYDRATE": "Карбохидрати",
		"PROTEIN": "Протеини",
		"ML": "МЛ.",
		"G": "Г.",
		"L": "Л.",
		"NUMBER": "број",

		/* CART */
		"CART_HEADER": "Вашата кошничка",
		"EMPTY_CART": "Вашата кошничка е празна",
		"CART_TOTAL": "Вкупно",
		"CLEAR_CART": "Испразни ја кошничката",
		"GO_TO_PAYMENT": "Оди на каса",

		/* REGISTER & LOGIN POPUP */
		"LOGIN": "Најави се",
		"HAVE_ACCOUNT_PH": "Или ако веќе имаш сметка:",
		"REGISTER": "Регистрирај се",
		"FACEBOOK_LOGIN": "Најави се со Facebook",
		"GOOGLE_LOGIN": "Најави се со Google",
		"REMEMBER_ME": "Запомни ме",
		"E_MAIL": "E-mail",
		"PASSWORD": "Лозинка",
		"NAME": "Име",
		"LAST_NAME": "Презиме",
		"ADDRESS": "Адреса на живеење",
		"CITY": "Град",
		"MUNICIPALITY": "Општина",
		"TELEPHONE": "Телефон",
		"ZIP_CODE": "Поштенски број",

		/* PASSWORD RECOVERY POPUP */
		"PASSWORD_RECOVERY": "Обновување на лозинка",

		/* TRACK ORDER */
		"PACKING": "пакување",
		"LOADING": "товарење",
		"SHIPPING": "во транзит",
		"DELIVERED_ORDER": "испорачано",
		"DELIVERY_TIME_PH": "време за испорака: ",
		"PREVIOUS_ORDERS": "претходни нарачки",

		/* FAVORITE PRODUCTS */
		"FAVORITE_PRODUCTS": "Омилени производи",
		"EMPTY_FAVORITE_PRODUCTS_PH": "Вашите омилени производи ќе се појават тука... кога ќе ги ставите тука.",

		/* HEADER ITEMS */
		"SEARCH_RAFT_PH": "Пребарај низ рафтовите, Пр: Зимница",

			/* HEADER ITEMS -- SLIDING MENU */
			"MENU": "мени",
			"CATEGORIES": "Категории",
			"WELCOME_PH": "Добродојдовте на raft.mk",
			"WELCOME_PH_SUBTEXT": "Raft.mk e најдобрата веб апликација за on-line купување во Македонија. Моментално имаме над 1.500 производи, распоредени во 35 категории.",

			/* HEADER DROPDOWNS */
			"SETTINGS": "Поставки",
			"STATISTICS": "Статистики",
			"ORDER_HISTORY": "Историја на нарачки",
			"LOG_OUT": "Одјави се",
			"CLEAR": "Исчисти",
			"EMPTY_NOTIFICATIONS_PH": "Вашите нотификации ќе се појават тука",

		/* FOOTER */
		"TERMS_OF_USE": "Услови на користење",
		"FAQ": "FAQ",
		"PRIVACY_POLICY": "Политика на приватност",
		"GENERAL_TERMS": "Општи Одредби",
		"CONTACT": "Контакт",

		/* SETTINGS */
		"BASIC_INFO": "Основни информации",
		"DELIVERY_LOCATIONS": "Локации за испорака",
		"NOTIFICATIONS": "Нотификации",
		"LINKED_PROFILES": "Поврзани профили",

			/* SETTINGS -- BASIC INFO */
			"AVATAR": "Аватар",
			"NAME_AND_LASTNAME": "Име и презиме",
			"CHOOSE_PHOTO": "одбери слика",
			"CHANGE_PASSWORD": "промени лозинка",

			/* SETTINGS -- DELIVERY LOCATIONS */
			"LOCATION": "Локација",
			"LOCATIONS": "Локации",
			"NEW_ADDRESS": "Нова адреса",
			"LABEL": "лабела",
			"REGION": "регион",
			"CITY_ZIP_CODE": "град и поштенски број",
			"EMPTY_LOCATIONS_PH": "Вашите локации ќе се појават тука",

			/* SETTINGS -- NOTIFICATIONS */
			"SMS_NOTIFICATIONS": "смс нотификации",
			"WEB_NOTIFICATIONS": "веб нотификации",
			"MOBILE_NOTIFICATIONS": "мобилни нотификации",

		/* STATISTICS */
		"CHOOSE_DATE": "Одбери датум",
		"FROM": "од",
		"TO": "до",
		"PRODUCTS": "производи",
		"CHEAPEST_PRODUCT": "најевтин производ",
		"MOST_EXPENSIVE_PRODUCT": "најскап производ",
		"MONTHLY_STATS": "Месечни статистики",
		"YEARLY_STATS": "Годишни статистики",
		"CHOOSE_MONTH": "Изберете месец",
		"CHOOSE_YEAR": "Изберете година",

		/* ORDER HISTORY */
		"PIECES": "парчиња",
		"PRODUCT": "производ",
		"QUANTITY": "количина",
		"PRICE": "цена",
		"DOWNLOAD_AS_PDF": "Преземи како .pdf",
		"PRINT": "Испринтај",

		/* PRODUCT POPUP */
		"DESCRIPTION": "опис",
		"INGRIDIENTS": "состав",
		"NUTRITIONAL_TABLE": "нутри табела",
		"ADD_TO_CART": "во кошничка",
		"RECOMMENDED_PRODUCTS": "препорачани производи",
		"UNAVAILABLE_PRODUCT": "Производот не е достапен",

		/* PAYMENT POPUP */
		"CASH_REGISTER": "Каса",
		"CARD": "Картичка",
		"DELIVERY": "Испорака",
		"PAY": "Плати",

		/* SAVE CART POPUP */
		"SAVE_CART_HEADER": "Зачувај ја кошничката",

		/* TRANSACTION */
		"ORDER_SUCCESS": "Нарачката е успешна",
		"ORDER_PROCESSING": "Нарачката се процесира",
		"ORDER_POPUP_REGISTER_PH": "Бидејќи ги имаме веќе вашите информации, се што е потребно да имате сметка на Raft е само вашата лозинка.",

		/* INGRIDIENTS */
		"GLUTEN": "Глутен",
		"MEAT": "Месо",
		"FLOUR": "Брашно",
		"KOFEIN": "кофеин",
		"TAURIN": "таурин",
		"SAHAROZA": "сахароза",

		/* ALERGENS */
		"MILK": "Млеко",
		"EGGS": "Јајца",
		"FISH": "Риба",
		"SHELLFISH": "Школки",
		"TREE_NUTS": "Јаткасти плодови",
		"PEANUTS": "Кикирики",
		"WHEAT": "Пченица",
		"SOYBEANS": "Соја",
		"CELERY": "Целер",
		"HONEY": "Мед",
		"SINAPIS": "Синап",
		"SATURATED_FAT": "Заситени масти",
		"SALT": "Сол",
		"FIBER": "Растителни Влакна",
		"ENERGY_VALUE": "Енергетска вредност",
		"SUGARS": "Шеќери",
		
		/* ORDER HISTORY STATUSES */
		"NEW": "Нова",
		"CHARGEABLE": "Се наплаќа",
		"QUEUED": "Во ред",
		"ACCEPTED": "Прифатена",
		"DECLINED": "Одбиена",
		"PROCESSING": "Се процесира",
		"SHIPPED": "Испратена",
		"DELIVERED": "Испорачана",
		"CANCELED": "Откажана",

		/* CHANGE_PASSWORD */
		"OLD_PASSWORD": "Стара лозинка",
		"NEW_PASSWORD": "Нова лозинка",
		"CONFIRM_PASSWORD": "Потврди лозинка"
	}
};