import React from "react";
import { connect } from "react-redux";

export class ScrollToPosition extends React.Component {
	shouldComponentUpdate(nextProps, nextState) {
		const changeProps = this.props.droppedCards.cart !== nextProps; 
		var elementToScroll = document.querySelector("." + this.props.content);
		
		if(this.props.droppedCards.cart !== nextProps) {
			var scrollInterval = setInterval(() => {
				if (elementToScroll.scrollTop > 0){
					elementToScroll.scrollTo(0, elementToScroll.scrollTop -= 40);		
				} else {
					clearInterval(scrollInterval);
				}
			}, 1);
		}

		return changeProps;

	}

	render() {
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		droppedCards: state.shoppingCartReducer
	}
}

ScrollToPosition = connect(mapStateToProps)(ScrollToPosition);