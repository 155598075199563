export const showDropdown = (data) => {
	return {
		type: "OPEN_DROPDOWN",
		values: data
	}
}

export const closeDropdown = (data) => {
	return {
		type: "CLOSE_DROPDOWN",
		values: data
	}
}

export const closeDropdownMobile = (data) => {
	return {
		type: "CLOSE_DROPDOWN--MOBILE",
		values: data
	}
}