import React from "react";

const RevealPassword = (props) => {
	return (
		<span>
			<input value={props.value} name="password" id="password" onChange={props.handleChange} type={props.type} className={"placeholder__input " + (props.errors.phone_number && props.touched.phone_number && props.errors.phone_number ? "invalid-input" : "")} placeholder={props.placeholder} />
			<button aria-label="reveal" onClick={props.handleReveal} className={"reveal__password-button icon-button--naked " + (props.type === "password" ? "revealed" : "hidden")}></button>
		</span>
	)
}

export default RevealPassword;
