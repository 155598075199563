import { combineReducers } from "redux";

import productPopup from "./ProductPopupReducer";
import authPopup from "./userAuthReducer";
import menuReducer from "./MenuReducer";
import trackOrder from "./TrackOrderReducer";
import dropdownReducer from "./DropdownsReducer";
import paymentReducer from "./PaymentReducer";
import saveCartReducer from "./SaveCartReducer";  
import showCartReducer from "./ShowCartReducer";
import searchReducer from "./SearchReducer";
import expandRaftReducer from "./ExpandRaftReducer";
import favoritesReducer from "./FavoritesReducer";
import filterRaftReducer from "./FilterRaftReducer";
import shoppingCartReducer from "./ShoppingCartReducer";
import locale from "./LanguageReducer";
import userReducer from "./UserReducer";
import productsReducer from "./ProductsReducer";
import categoriesReducer from "./CategoriesReducer";
import pagesReducer from "./PagesReducer";

export default combineReducers({
	productPopup,
	authPopup,
	menuReducer,
	trackOrder,
	dropdownReducer,
	paymentReducer,
	saveCartReducer,
	showCartReducer,
	searchReducer,
	expandRaftReducer,
	favoritesReducer,
	filterRaftReducer,
	shoppingCartReducer,
	locale,
	userReducer,
	productsReducer,
	categoriesReducer,
	pagesReducer
});