import React from "react";

// Tabs
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import ListPaneRow from "./ListPaneRow";

const CleaningTabs = (props) => {
		return (
			<Tabs
				defaultActiveKey="1"
				renderTabBar={() => <ScrollableInkTabBar />}
				renderTabContent={() => <TabContent />}
			>
				<TabPane tab='Опис' key="1">
					<p className="tab__paragraph">{props.productData.detail_description}</p>
				</TabPane>

				<TabPane tab='состав' key="2" className="ingridients-table-container">
					<div className="tab__paragraph">
						<ListPaneRow title="Состав" data={props.productData.meta.cleaning_ingredients} />
					</div>
				</TabPane>
			</Tabs>
		)
}

export default CleaningTabs;
