import React from "react";
import { connect } from "react-redux"
import { BrowserView, MobileView } from "react-device-detect";
import { NavLink } from 'react-router-dom';
import { isEmpty, ArrayIsEmpty } from "../helper";
import ReactToPrint from 'react-to-print';
import { FormattedMessage } from "react-intl";

import "moment/locale/mk";

// Import of components
import  ProductImage  from "./ProductImage";
import  Footer  from "./shared/Footer";
import { ScrollToTop } from "./ScrollToTop";

// Actions
import { fillSingleOrder } from "../actions/ShoppingCartAction";

import bc from "../callsetter";

const dateFormatter = (d) => {
	const weekdays = {
		'Mon': 'Понеделник',
		'Tue': 'Вторник',
		'Wed': 'Среда',
		'Thu': 'Четврток',
		'Fri': 'Петок',
		'Sat': 'Сабота',
		'Sun': 'Недела'
	};
	let format = '{WEEKDAY}, {DAY}.{MONTH}.{YEAR}';
	let dt = new Intl.DateTimeFormat("en-UK", {
		weekday: "short",
		year: 'numeric',
		month: '2-digit',
		day: 'numeric',
		timeZone: 'Europe/Skopje'
	}).formatToParts(new Date(d));

	for(let v of dt){
		switch(v.type) {
			case 'weekday': 
				format = format.replace('{WEEKDAY}', weekdays[v.value]);
				break;
			case 'year':
				format = format.replace('{YEAR}', v.value);
				break;
			case 'month':
				format = format.replace('{MONTH}', v.value);
				break;
			case 'day':
				format = format.replace('{DAY}', v.value);
				break;
		}
	}
	console.log(dt);
	return format;
};

export class OrderHistory extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			colapse: null,
			showLoader: false,
			colapsibleID: "",
			month: null,
			year: null
		};
		this.handleDropdownChange = this.handleDropdownChange.bind(this);
		this.filterOrder = this.filterOrder.bind(this);
	}

	handleDropdownChange(e) {
		this.setState({ [e.target.name]: e.target.value === '' ? null : Number(e.target.value)});
	}

	filterOrder(order) {
		let date = new Date(order._created);
		let month = date.getUTCMonth() + 1;
		let year = date.getUTCFullYear();
		if (this.state.month === null && this.state.year === null) {
			return true
		} else if (this.state.month === null && this.state.year !== null) {
			return year === Number(this.state.year);
		} else if (this.state.month !== null && this.state.year === null) {
			return month === Number(this.state.month);
		} else {
			return year === Number(this.state.year) && month === Number(this.state.month);
		}
	}

	render() {
		return (
			<div className="order-history">
				<ScrollToTop element="app-container" />
				{
					ArrayIsEmpty(this.props.orders) || (ArrayIsEmpty(this.props.products) && !isEmpty(this.props.productsMap)) || !isEmpty(this.props.user)
					? (
						<div className="single-page-wrapper order-history__wrapper">
							<div className="component__header">
								<div className="component__header--block">
									<div className="category-title">
										<button aria-label="home" className="component__header--home icon-button--naked">
											<NavLink to="/">
												рафт дома
										</NavLink>
										</button>
										<a>
											<span className="chevron"></span>
											<h2>Историја на нарачки</h2>
										</a>
									</div>
								</div>
								<div className="component__header--block date__input--holder">
									<div className="order-filter-datepicker-container">
										<span className="date-decorator--icon"></span>
										<span className="filter-title">Филтер</span>
										<select name="year" onChange={this.handleDropdownChange}>
											<option value="">Година</option>
											<option value="2020">2020</option>
											<option value="2021">2021</option>
											<option value="2022">2022</option>
											<option value="2023">2023</option>
											<option value="2024">2024</option>
											<option value="2025">2025</option>
										</select>
										<select name="month" onChange={this.handleDropdownChange}>
											<option value="">Месец</option>
											<option value="1">Јануари</option>
											<option value="2">Февруари</option>
											<option value="3">Март</option>
											<option value="4">Април</option>
											<option value="5">Мај</option>
											<option value="6">Јуни</option>
											<option value="7">Јули</option>
											<option value="8">Август</option>
											<option value="9">Септември</option>
											<option value="10">Октомври</option>
											<option value="11">Ноември</option>
											<option value="12">Декември</option>
										</select>
									</div>
								</div>
							</div>
							<div className="component__content">
								<div className="accordion-container">
									{
										this.props.orders.length === 0
										? (
											<div className="empty-data-container">
												<div className="empty-data-centered">
													<span>empty icon</span>
													<h2>Немате нарачки за овој датум.</h2>
												</div>
											</div>
										)
										: (
											this.props.orders.filter(this.filterOrder)
												.map((order, i) => {
													return (
														<OrderItem 
															i={i} 
															order={order} 
															key={order.id} 
															singleOrder={this.props.singleOrder} 
															products={this.props.products} 
															productsMap={this.props.productsMap}
															user={this.props.user}
															fillSingleOrder={this.props.fillSingleOrder}
														/>
													)
												}
											)
										)
									}
								</div>
							</div>
							<Footer pages={this.props.pages} openTour={this.props.openTour} />
						</div>
					)
					: (
						<div className="spinner-container order-history-id-spinner">
							<div className="centered-spinner">
								<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
								<h2>Ги вчитуваме вашите нарачки</h2>
							</div>
						</div>
					)
				}
			</div>
		)
	}
}

class OrderItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			colapse: null,
			showLoader: false,
			colapsibleID: ""
		};
	}

	getOrderItems(oid, i) {
		this.setState({
			showLoader: true
		})
		return bc.orders.getOrderById(oid)
			.then((res) => {
				if (res.ok) {
					this.props.fillSingleOrder(res.data);
					let active = this.state.colapse === i ? null : i

					this.setState({
						colapsibleID: res.data.id,
						colapse: active,
						showLoader: false
					});
				}
			})
			.catch((err) => console.error(err));
	}

	display(i) {
		return this.state.colapse === i ? 'expand' : ''
	}

	render() {
		return (
			<div ref={el => { this[`textInput${this.props.i}`] = el }} className={"collapsible__triger collapsible__content basic_info " + this.display(this.props.i)}>
				<div className="print-intro">
					<div className="basic-info-src">
						<div className="logo-src">
							<img alt="Raft lines" width="30" src="/storage/raft-lines.svg" />
						</div>
						<div className="text-src">
							<h2>Raft.mk</h2>
							<h2>Ул. Перо Наков бр. 25, 1000 Скопје</h2>
							<h2>contact@raft.mk</h2>
						</div>
					</div>
					<div className="date-src">
						<h2>
							Датум:
							<strong>
								{dateFormatter(this.props.order._created)}
							</strong>
						</h2>
						<h2>Број на нарачка:<strong>{this.props.order.id}</strong></h2>
						<h2>За:<strong>{this.props.user.first_name} {this.props.user.last_name}</strong></h2>
					</div>
				</div>
				<div className="collapsible__header print-mode" onClick={() => this.getOrderItems(this.props.order.id, this.props.i)}>
					<div className="category-title collapsible__header--content">
						<a>
							<span className="chevron"></span>
							<h2>{dateFormatter(this.props.order._created)}</h2>
						</a>
						{
							this.state.showLoader 
							? <div className="lds-ring loader-order-history"><div></div><div></div><div></div><div></div></div> 
							: null
						}
					</div>
					<div className="short__detail-info collapsible__header--content">
						<div className="chc--rounded items-price">
							<span class="value">{this.props.order.products_no}</span>
							<span class="measure">{this.props.order.products_no === 1 ? "производ" : "производи"}</span>
						</div>
						<div className="chc--rounded items-price">
							<span class="value">{Math.trunc(this.props.order.total)}</span>
							<span class="measure">мкд</span>
						</div>
					</div>
				</div>
				<MobileView renderWithFragment>
					<div className="short__detail-info collapsible__header--content chc--mobile">
						<div className="chc--rounded items-price">
							<span class="value">{this.props.order.products_no}</span>
							<span class="measure">{this.props.order.products_no === 1 ? "производ" : "производи"}</span>
						</div>
						<div className="chc--rounded items-price">
							<span class="value">{Math.trunc(this.props.order.total)}</span>
							<span class="measure">мкд</span>
						</div>
					</div>
				</MobileView>
				<div className="orders-src">
					<table className="orders__table">
						<thead>
							<tr>
								<th className="orders__table--product">Производ</th>
								<th className="orders__table--quantity mobile--alignment">Количина</th>
								<th className="orders__table--price mobile--alignment">Цена (мкд)</th>
								<th className="orders__table--price mobile--alignment print-table-data">Цена (ддв)</th>
							</tr>
						</thead>
						<tbody>
							{
								(!isEmpty(this.props.singleOrder) && this.props.singleOrder.items !== undefined) && (ArrayIsEmpty(this.props.products) && !isEmpty(this.props.productsMap))
								? (
									this.props.singleOrder.items.map((r, i) => {
										return (
											this.props.products[this.props.productsMap[r.product_id]] !== undefined
											? (
												<tr key={r.id} className={this.props.products[this.props.productsMap[r.product_id]].quantity <= 0 ? "unavaialbe" : ""}>
													<td>
														<div className="middle">
															<h2 className="row_count">{i + 1 + ". "}</h2>
															<div className="cart__product-img">
																<ProductImage image={this.props.products[this.props.productsMap[r.product_id]].images} />
															</div>
															<div className="cart__product--top">
																<h2 className="product__title">{this.props.products[this.props.productsMap[r.product_id]].name}</h2>
																<h4 className="product__subtitle">{this.props.products[this.props.productsMap[r.product_id]].description}</h4>
															</div>
															{this.props.products[this.props.productsMap[r.product_id]].quantity <= 0 ?
																<div className="if-unavailable">Производот е недостапен</div>
																: null
															}
														</div>
													</td>
	
													<td className="mobile--alignment"> {r.quantity} </td>
													<td className="mobile--alignment"> {Math.trunc(r.total_price)} </td>
													<td className="mobile--alignment print-table-data"> {Math.trunc(r.total_price)} </td>
												</tr>
											)
											: (
												<tr>
													<td>
														<div className="middle">
															<h2 className="row_count">{i + 1 + ". "}</h2>
															<div className="cart__product-img empty-order-data-placeholder">
																<div className={"if-not-loaded " + (!this.state.isImageLoaded ? "show-image" : "hide-image")}>
																	<span className="placeholder"></span>
																</div>
															</div>
															<div className="cart__product--top">
																<h2 className="product__title">Производот повеќе не постои на нашиот рафт</h2>
															</div>
														</div>
													</td>
													<td className="mobile--alignment">  </td>
													<td className="mobile--alignment">  </td>
													<td className="mobile--alignment print-table-data">  </td>
												</tr>
											)
										)
									})
								)
								: (
									<tr>
										<td>
											<p className="loading-placeholder">вчитуваме...</p>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
					<div className="component__content--footer">
						<div className="component__footer--block print-block">
							<ReactToPrint
								trigger={() =>
									<button aria-label="print" className="order__collapsible--button icon-button--primary print-button">
										<span className="order__collapsible-button-print-icon icon-button--naked">raft ponuda print</span>
										Отпечати
									</button>
								}
								content={() => this[`textInput${this.props.i}`]}
								closeAfterPrint={true}
								bodyClass={"print-mode"}
							/>
							<div className="order-status">
								<h2>Статус на нарачка: <span><FormattedMessage id={this.props.order.status} /></span></h2>
							</div>
						</div>
						<div className="component__footer--block cfb__total">
							<span>Вкупно:</span>
							<h2>{Math.trunc(this.props.order.total)} мкд</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		pages: state.pagesReducer.pages,
		singleOrder: state.shoppingCartReducer.singleOrder,
		user: state.userReducer.me
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fillSingleOrder: (order) => {
			dispatch(fillSingleOrder(order));
		}
	}
}

OrderHistory = connect(mapStateToProps, mapDispatchToProps)(OrderHistory);