import React, { useState, useEffect} from "react";
import { Animated } from "react-animated-css";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "../helper";
import { Formik, Form, ErrorMessage } from 'formik';


const LocationPopup = React.memo((props) => {

	const [theLocation, setTheLocation] = useState(props.data);
	const [basicInfo, setBasicInfo] = useState(props.basicInfo);

	const location = useLocation();

	let { id } = useParams();

	useEffect(() => {
		if (location.pathname === "/settings/locations/new") {
			setTheLocation({});
		} else {
			if(!isEmpty(props.data) && (location.pathname !== "/settings/locations/" + id)) {
				setTheLocation(props.data[0]);
			}
		}
	}, []);

	/**
    *  After user scrolls to the end of the content the gradient dissapears to reveal the slightly hidden content
    */

	const _hideGradientOnBottomScroll = () => {
		var element = document.querySelector(".inputs-container");
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			document.getElementById("mobile-popup-gradient--location").style.opacity = "0";
		} else {
			document.getElementById("mobile-popup-gradient--location").style.opacity = "1";
		};
	};

	/**
    *  Adds a new location and hides the location popup
    */

	const _addNewLocation = (newLocation) => {
		props.addNewLocation(newLocation);
		props.hideMap();
	};

	/**
    *  Removes a location and hides the location popup
    */

	const _removeLocation = (e, location) => {
		props.removeLocation(location);
		props.hideMap();
	};

	/**
    *  Updates the location and hides the location popup
    */

	const HandleLocation = (values) => {
		if (location.pathname === "/settings/locations/new") {
			_addNewLocation(values);
		} else {
			_updateLocation(values);
		}

	};

	const NewLocationFromPaymentPopup = (values) => {
		_addNewLocation(values);
	};

	const _updateLocation = (location) => {
		let props_data = {
			label: props.data.label,
			address: props.data.address,
			address_number: props.data.address_number,
			region: props.data.region
		}
		if (JSON.stringify(props_data) !== JSON.stringify(location)) {
			props.updateLocation(location, theLocation.id);
		}
		props.hideMap();
	};

		return (
			<div className={"product__popup"} id="location__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--overlay" onClick={props.hideMap}></div>
				</Animated>

				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={props.showPopupBool} >
					<div className="product__popup--full">
						{
							theLocation !== undefined

								?

								location.pathname === "/settings/locations/new" || location.pathname === ("/settings/locations/" + theLocation.id)

									?

									<div className="section__favorite">
										<h2><FormattedMessage id={"LOCATION"} /></h2>
									</div>

									:

									props.newAddress ?
										<div className="section__favorite">
											<h2><FormattedMessage id={"BASIC_INFO"} /></h2>
										</div> :
										<div className="section__favorite">
											<h2><FormattedMessage id={"LOCATION"} /></h2>
										</div>

								:

								null
						}

						<div className="section__close">
							<button aria-label="cancel" onClick={props.hideMap} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div onScroll={_hideGradientOnBottomScroll} className="inputs">
						{

							theLocation !== undefined

								?

								location.pathname === "/settings/locations/new" || location.pathname === ("/settings/locations/" + theLocation.id)

									?

									<Formik
										enableReinitialize={true}
										initialValues={{
											label: theLocation.label,
											address: theLocation.address,
											address_number: theLocation.address_number,
											region: theLocation.region
										}}
										validate={values => {
											let errors = {};

											if (!values.label) errors.label = "Задолжително";
											if (!values.address) errors.address = "Задолжително";
											if (!values.address_number) errors.address_number = "Задолжително";
											if (!values.region) errors.region = "Задолжително";

											return errors;
										}}
										onSubmit={values => {
											HandleLocation(values);
										}}
										render={({ errors, touched, values, handleChange }) => (
											<Form name="prefilled-location">
												<div className="inputs-container">
													<div className="input__holders--col">
														<div className="label-holder">
															<ErrorMessage name="label" component="span" className="invalid-feedback halfed" />
															<label htmlFor="label"><FormattedMessage id={"LABEL"} /></label>
														</div>
														<input type="text" name="label" onChange={handleChange} value={values.label || ""} className={"label__input " + (errors.label && touched.label && errors.label ? "invalid-input" : "")} id="label" />
													</div>

													<div className="input-grid">
														<div className="input__holders--col input__holders--col--half input__holders--col--sts">
															<div className="label-holder">
																<ErrorMessage name="address" component="span" className="invalid-feedback halfed" />
																<label htmlFor="address"><FormattedMessage id={"ADDRESS"} /></label>
															</div>
															<input type="text" name="address" onChange={handleChange} value={values.address || ""} className={"label__input " + (errors.address && touched.address && errors.address ? "invalid-input" : "")} id="address" />
														</div>
														<div className="input__holders--col input__holders--col--half input__holders--col--stt">
															<div className="label-holder">
																<ErrorMessage name="address_number" component="span" className="invalid-feedback halfed" />
																<label htmlFor="address_number"><FormattedMessage id={"NUMBER"} /></label>
															</div>
															<input type="text" name="address_number" onChange={handleChange} value={values.address_number || ""} className={"label__input " + (errors.address_number && touched.address_number && errors.address_number ? "invalid-input" : "")} id="address_number" />
														</div>
													</div>

													<div className="input__holders--col">
														<div className="label-holder">
															<ErrorMessage name="region" component="span" className="invalid-feedback halfed" />
															<label htmlFor="region"><FormattedMessage id={"REGION"} /></label>
														</div>
														<input type="text" name="region" onChange={handleChange} value={values.region || ""} className={"label__input " + (errors.region && touched.region && errors.region ? "invalid-input" : "")} id="region" />
													</div>

													{
														location.pathname === "/settings/locations/new" && location.pathname !== "/settings/basic-info/edit"

															?

															<div className="settings__content-block--actions">
																<div className="popup--gradient" id="mobile-popup-gradient--location"></div>
																<div>
																	<button aria-label="cancel" type="button" className="button__ghost--primary" onClick={props.hideMap}><FormattedMessage id={"DISCARD"} /></button>
																	<button aria-label="create" type="submit" className="button--primary"><FormattedMessage id={"ADD"} /></button>
																</div>
															</div>

															:

															null
													}

													{
														location.pathname !== "/settings/locations/new" && location.pathname !== "/settings/basic-info/edit"

															?

															<div className="settings__content-block--actions">
																<div className="popup--gradient" id="mobile-popup-gradient--location"></div>
																<button aria-label="delete" type="button" onClick={(event) => _removeLocation(event, theLocation)} className="button__ghost--borderless--primary delete-location">избриши</button>

																<div>
																	<button aria-label="cancel" type="button" className="button__ghost--primary" onClick={props.hideMap}>Откажи</button>
																	<button aria-label="save" type="submit" className="button--primary">Зачувај</button>
																</div>
															</div>

															:

															null
													}
												</div>
											</Form>
										)}
									/>

									:

									null

								:

								null
						}

						{
							props.newAddress ?
								<Formik
									initialValues={{
										label: "",
										address: "",
										address_number: "",
										region: ""
									}}
									validate={values => {
										let errors = {};

										if (!values.label) errors.label = "Задолжително";
										if (!values.address) errors.address = "Задолжително";
										if (!values.address_number) errors.address_number = "Задолжително";
										if (!values.region) errors.region = "Задолжително";

										return errors;
									}}
									onSubmit={values => {
										NewLocationFromPaymentPopup(values);
									}}
									render={({ errors, touched, values, handleChange }) => (
										<Form name="new-location">
											<div className="inputs-container">
												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="label" component="span" className="invalid-feedback halfed" />
														<label htmlFor="label"><FormattedMessage id={"LABEL"} /></label>
													</div>
													<input type="text" name="label" onChange={handleChange} value={values.label || ""} className={"label__input " + (errors.label && touched.label && errors.label ? "invalid-input" : "")} id="label" />
												</div>

												<div className="input-grid">
													<div className="input__holders--col input__holders--col--half input__holders--col--sts">
														<div className="label-holder">
															<ErrorMessage name="address" component="span" className="invalid-feedback halfed" />
															<label htmlFor="address"><FormattedMessage id={"ADDRESS"} /></label>
														</div>
														<input type="text" name="address" onChange={handleChange} value={values.address || ""} className={"label__input " + (errors.address && touched.address && errors.address ? "invalid-input" : "")} id="address" />
													</div>
													<div className="input__holders--col input__holders--col--half input__holders--col--stt">
														<div className="label-holder">
															<ErrorMessage name="address_number" component="span" className="invalid-feedback halfed" />
															<label htmlFor="address_number"><FormattedMessage id={"NUMBER"} /></label>
														</div>
														<input type="text" name="address_number" onChange={handleChange} value={values.address_number || ""} className={"label__input " + (errors.address_number && touched.address_number && errors.address_number ? "invalid-input" : "")} id="address_number" />
													</div>
												</div>

												<div className="input__holders--col">
													<div className="label-holder">
														<ErrorMessage name="region" component="span" className="invalid-feedback halfed" />
														<label htmlFor="region"><FormattedMessage id={"REGION"} /></label>
													</div>
													<input type="text" name="region" onChange={handleChange} value={values.region || ""} className={"label__input " + (errors.region && touched.region && errors.region ? "invalid-input" : "")} id="region" />
												</div>

												<div className="settings__content-block--actions">
													<div className="popup--gradient" id="mobile-popup-gradient--location"></div>
													<div>
														<button aria-label="cancel" type="button" className="button__ghost--primary" onClick={props.hideMap}><FormattedMessage id={"DISCARD"} /></button>
														<button aria-label="create" type="submit" className="button--primary"><FormattedMessage id={"ADD"} /></button>
													</div>
												</div>
											</div>
										</Form>
									)}
								/>
								: null
						}
					</div>
				</Animated>
			</div>
		)
}, (props, nextProps) => {
	return JSON.stringify(nextProps.data) !== JSON.stringify(props.data);
});

export default LocationPopup;
