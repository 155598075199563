export const getMe = (user) => {
	return {
		type: "FETCH_LOGGEDIN_USER",
		payload: user
	}
}

export const getUserById = (user) => {
	return {
		type: "FETCH_USER_BY_ID",
		payload: user
	}
}

export const fetchUserLocation = (location) => {
	return {
		type: "FETCH_USER_LOCATION",
		payload: location
	}
}

export const fetchUserProfiles = (profile) => {
	return {
		type: "FETCH_USER_PROFILES",
		payload: profile
	}
}

export const fetchUserPreferences = (preferences) => {
	return {
		type: "FETCH_USER_PREFERENCES",
		payload: preferences
	}
}

export const fetchUserStatistics = (stats) => {
	return {
		type: "FETCH_USER_STATISTICS",
		payload: stats
	}
}

export const clearUserStatistics = () => {
	return {
		type: "CLEAR_USER_STATISTICS"
	}
}

export const fetchUserOrders = (orders) => {
	return {
		type: "FETCH_USER_ORDERS",
		payload: orders
	}
}

export const setStatisticsFilter = (seasonType, month, year) => {
	return {
		type: "SET_STATISTICS_FILTER_TYPE",
		payload: {
			seasonType: seasonType,
			month: month,
			year: year
		}
	}
}

export const clearUserObject = () => {
	return {
		type: "CLEAR_USER_OBJECT_ON_LOGOUT"
	}
}