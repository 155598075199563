import React, { useState } from "react";
import Toast  from "./shared/Toast";

import bc from "../callsetter";
import { MessageFormData } from "@oblax/sdk-js";

import { Formik, Form, ErrorMessage } from 'formik';

const Contact = (props) => {

	const [toastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastCode, setToastCode] = useState("");
	const [toastType, setToastType] = useState("");
	const [buttonClickLoader, setButtonClickLoader] = useState("");


	/**
    *  Hide toast after finished render of message
    */

	const hideToast = () => {
		// let self = this;
		setTimeout(() => {
			setShowToast(false)
		}, 4000);
	};

	/**
    *  Highlight field with red border on error
    */

	const showWrongField = (field) => {
		document.querySelector('.label__input[name="' + field + '"]').classList.add("error-field");
	}

	/**
    *  Highlight field with red border on error
    */

	const hideWrongField = () => {
		let fields = document.querySelectorAll(".label__input");

		for (var i = 0; i < fields.length; i++) {
			fields[i].classList.remove("error-field");
		}
	}

	/**
    *  Submit the form
    */

	const postMessage = (data) => {
		
		hideWrongField();
		setButtonClickLoader(true);

		let p = new MessageFormData();
		p.loadFromJSON({
			name: data.name,
			email: data.email,
			phone: data.phone,
			address: data.address,
			content: data.message
		});

		return bc.forms.postMessage("contact", p)
			.then((params) => {
				if (params.ok) {
					setToastMessage("Вашата порака е успешно пратена");
					setShowToast(true);
					setToastCode("green");
					setToastType("contactFormSuccess");
					setButtonClickLoader(false);
				}
			})
			.catch(e => {
				switch (e.data.details) {
					case "Validation failed on field: name":
						setToastMessage("Вашето име и презиме е потребно за испраќање на пораката. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);
						showWrongField("name");
						break;
					case "Validation failed on field: email":
						setToastMessage("Вашата e-mail адреса е потребна за испраќање на пораката. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);
						showWrongField("email");
						break;
					case "Validation failed on field: phone":
						setToastMessage("Вашиот телефон е потребен за испраќање на пораката. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);
						showWrongField("phone");
						break;
					case "Validation failed on field: address":
						setToastMessage("Вашата адреса е потребна за испраќање на пораката. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);
						showWrongField("address");
						break;
					case "Validation failed on field: content":
						setToastMessage("Вашата порака е потребна за испраќање на пораката. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);
						showWrongField("message");
						break;
					default:
						setToastMessage("Имаше грешка при испраќање. Обидете се повторно");
						setShowToast(true);
						setToastCode("red");
						setToastType("contactFormError");
						setButtonClickLoader(false);

				}
			})

	}

		return (
			<div className="sub-page" id="contact">
				<div className="text-block">
					<div className="text-block-header text-block-header-main">
						<span className="chevron"></span>
						<h2>Контакт</h2>
					</div>

					<p className="text-block-text">
					Можете да нѐ контактирате директно доколку имате некои прашања во врска со производите или начинот на испорака, потребна ви е помош при користењето на raft.mk или пaк има некои грешки во веќе испорачаните производи до Вас.<br/><br/>
					Отворени сме за сите сугестии кои што користењето на raft.mk ќе го направат подобро, како и за сите предлози за соработка.<br/><br/>
					Нашите колеги ќе одговорат на Вашето барање во најбрз можен рок. 
					</p>

				</div>

				<div className="text-block iconless text-block-inputs">
					<Formik
						initialValues={{
							name: "",
							email: "",
							phone: "",
							address: "",
							message: "",
						}}
						validate={values => {
							let errors = {};

							if (!values.name) errors.name = "Задолжително";
							if (!values.email) { errors.email = "Задолжително"; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) { errors.email = "Неправилна e-mail адреса" }
							if (!values.phone) { errors.phone = "Задолжително"; } else if (!/^07[0-9]{7}$/.test(values.phone)) { errors.phone = "Неправилен број" }
							if (!values.address) errors.address = "Задолжително";
							if (!values.message) errors.message = "Задолжително";

							return errors;
						}}
						onSubmit={values => {
							postMessage(values);
						}}
						render={({ errors, touched, values, handleChange }) => (
							<Form name="contact">
								<div className="input__holders--col">
									<div className="label-holder">
										<ErrorMessage name="name" component="span" className="invalid-feedback halfed" />
										<label htmlFor="contact_name_lastname">име и презиме</label>
									</div>
									<input value={values.name} onChange={handleChange} name="name" type="text" className={"label__input " + (errors.name && touched.name && errors.name ? "invalid-input" : "")} id="contact_name_lastname" />
								</div>

								<div className="input__holders--col">
									<div className="label-holder">
										<ErrorMessage name="email" component="span" className="invalid-feedback halfed" />
										<label htmlFor="contact_email">e-mail</label>
									</div>
									<input value={values.email} onChange={handleChange} name="email" type="text" className={"label__input " + (errors.email && touched.email && errors.email ? "invalid-input" : "")} id="contact_email" />
								</div>

								<div className="input__holders--col">
									<div className="label-holder">
										<ErrorMessage name="phone" component="span" className="invalid-feedback halfed" />
										<label htmlFor="contact_phone">телефон</label>
									</div>
									<input value={values.phone} onChange={handleChange} name="phone" type="text" className={"label__input " + (errors.phone && touched.phone && errors.phone ? "invalid-input" : "")} id="contact_phone" />
								</div>

								<div className="input__holders--col">
									<div className="label-holder">
										<ErrorMessage name="address" component="span" className="invalid-feedback halfed" />
										<label htmlFor="contact_address">бр.фактура</label>
									</div>
									<input value={values.address} onChange={handleChange} name="address" type="text" className={"label__input " + (errors.address && touched.address && errors.address ? "invalid-input" : "")} id="contact_address" />
								</div>

								<div className="input__holders--col ihc--full">
									<div className="label-holder">
										<ErrorMessage name="message" component="span" className="invalid-feedback halfed" />
										<label htmlFor="contact_message">порака</label>
									</div>
									<textarea value={values.message} onChange={handleChange} name="message" className={"label__input " + (errors.message && touched.message && errors.message ? "invalid-input" : "")} id="contact_message"></textarea>
								</div>

								<div className="input__holders--col">
									<button aria-label="send" type="submit" className="send-contact-button button--primary">
										{buttonClickLoader ?
											<div className="button-loader">
												<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
											</div> : "Прати"}
									</button>
								</div>
							</Form>
						)}
					/>
				</div>

				{showToast ?
					<Toast
						show={showToast}
						type={toastType}
						message={toastMessage}
						code={toastCode}
						hideToast={hideToast}
					/> : null}
			</div >
		)
}

export default Contact;