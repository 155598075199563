import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

// Import of Shared Directivs and Components
import { Tabs } from "./shared/Tabs";
import Card from "../components/Card";

// Actions
import { expandRaft, shrinkRaft, shrinkViaButton, expandViaButton } from "../actions/ExpandRaftAction";
import { showPopup, closePopup } from "../actions/ProductPopupAction";
import { fetchFavorites } from "../actions/FavoritesAction";
import { addToCart } from "../actions/ShoppingCartAction";
import { SetCardWidth } from "../actions/ProductsAction";

import bc from "../callsetter";
import { Bookmark } from "@oblax/sdk-js";
import { isEmpty } from "../helper";


const Raft = (props) => {

	const [filteredRaftId, setFilteredRaftId] =  useState(props.category.id); // id from main category
	const [filteredRaftIdProducts, setFilteredRaftIdProducts] = useState(props.category.products); // id from main category // may be from redux
	const [filterRaft, setFilterRaft] = useState([]);
	const [WindowSize, setWindowSize] = useState(window.innerWidth);
	const [hideButton, setHideButton] = useState(false);
	const [showCards, setShowCards] = useState(true);

	const expandAllProducts = useSelector(state => state.expandRaftReducer);
	const expandAllProductsExpanded = useSelector(state => state.expandRaftReducer.expanded);
	const expandViaButtonConst = useSelector(state => state.expandRaftReducer.viaButton);
	const user = useSelector(state => state.userReducer);
	const auth = useSelector(state => state.authPopup.auth);
	const favorites = useSelector(state => state.favoritesReducer.favorites);
	const productsMap = useSelector(state => state.productsReducer.productsMap);
	const products = useSelector(state => state.productsReducer.products);

	const Slider = useRef();

	const dispatch = useDispatch();

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		}
	},[]);

	const handleResize = (WindowSize, event) => {
		setWindowSize(window.innerWidth);
	};

	const handleExpandViaButton = () => {
		dispatch(expandViaButton());
	};

	/**
    * Expanding a raft to show all of the products
	*/

	const handleRaftToggle = (raft) => {
		Slider.current.scrollLeft = 0;
		if (expandAllProductsExpanded.length === 0) {
			handleExpandViaButton();
			dispatch(expandRaft(raft));
		} else {
			if (expandAllProductsExpanded.includes(raft)) {
				dispatch(shrinkViaButton());
				dispatch(shrinkRaft(raft));
			} else {
				handleExpandViaButton();
				dispatch(expandRaft(raft));
			}
		}
	};

	const handleRaftToggle_mobile = (raft) => {
		Slider.current.scrollLeft = 0;
		if (expandAllProductsExpanded.length === 0) {
			dispatch(expandRaft(raft));
		} else {
			if (expandAllProductsExpanded.includes(raft)) {
				dispatch(shrinkRaft(raft));
			} else {
				dispatch(expandRaft(raft));
			}
		}
	};

	/**
    * Styling of expanded raft
	*/

	const selectedAccountClasses = (category) => {
		let classes = ['raft'];

		expandAllProductsExpanded.forEach((cat) => {
			if (category === cat) {
				classes.push('expand');

				if (expandViaButtonConst) {
					classes.push('button-expand');
				}
			}
		});


		return classes.join(' ');
	};

	/**
    * Drags a product
	*/

	const dragCard = (e, product) => {
		// e.dataTransfer.effectAllowed = 'move';
		e.dataTransfer.setData("id", product.id);
	};

	const handleAddToCart = (product) => {
		dispatch(addToCart(product));
	};

	/**
    * Filter the raft to show products relevant to it's category
	*/

	const handleFilterRaft = (category) => {
		setFilterRaft(category);
		setFilteredRaftId(category.id);
		setFilteredRaftIdProducts(category.products);
		setShowCards(false);
		setTimeout(() => {
			setShowCards(true);
		}, 50);
	};

	/**
    * Styling of filtered raft, displaying a gradient in the chosen tab
	*/

	const activeSubCategoryClasses = (sub) => {
		let classes = ["tab__item"];

		if (filterRaft.length !== 0) {
			if (filterRaft.id === sub.id && filteredRaftId !== props.category.id.id) {
				classes.push("active__sub");
			}
		}

		return classes.join(" ");
	};

	/**
    * Clear the filter and show every raft product
	*/

	const clearFilter = () => {
		setFilteredRaftId(props.category.id);
	};

	const handleFavorites = (productId) => {
		let favId = favorites.filter((item) => { return item.record_id === productId });

		if (favorites.length <= 0) {
			addToFavorites(productId);
		} else {
			if (favorites.includes(favId[0])) {
				removeFromFavorites(favId[0].id);
			} else {
				addToFavorites(productId);
			}
		}
	};

	/**
    * Add to favorites
	*/

	const addToFavorites = (productId) => {
		let b = new Bookmark();
		b.loadFromJSON({
			type: "product",
			record_id: productId,
			user_id: user.me.id 
		});

		return bc.bookmarks.createBookmark(user.me.id, "product", b) 
			.then((params) => {
				if (params !== null) {
					handleFetchFavorites();
				}
			})
			.catch((err) => {
				console.error("Error creating bookmark", err);
			})
	};

	/**
    * Remove from favorites
	*/

	const removeFromFavorites = (favId) => {
		return bc.bookmarks.removeBookmark(user.me.id, "product", favId)
			.then((params) => {
				if (params !== null) {
					handleFetchFavorites();
				}
			})
			.catch((err) => {
				console.error("Error removing bookmark", err);
			})
	};

	/**
    * Style favorite items with a filled red heart icon
	*/

	const handleFavoritesStyle = (productId) => {
		let classes = ['raft-card__favorite icon-button--naked'];

		if (favorites.length !== 0) {
			favorites.forEach((fav) => {
				if (fav.record_id === productId) {
					classes.push('favorite--active');
				}
			})
		}

		return classes.join(' ');
	};

	/**
    * Fetch list of favorited items
	*/

	const handleFetchFavorites = () => {
		if (!isEmpty(user.me)) {
			return bc.bookmarks.getCategoryBookmarks(user.me.id, "product")
				.then((params) => {
					if (params.ok) {
						dispatch(fetchFavorites(params.data));
					}
				})
				.catch((err) => {
					console.error("Error fetching Bookmarks", err);
				})
		}
	};

	/**
    * Check if Main category has no children
	*/

	const ifCategoryHasNoChildren = () => {
		let classes = [''];

		// if (this.props.category.id === catID) {
			if (isEmpty(props.category.children)) {
				classes.push('no-children');
			}

		return classes.join(' ');
	}

	const goNext = () => {
		Slider.current.scrollBy({
			top: 0,
			left: 398,
			behavior: 'smooth'
		});
	}

	const goPrev = () => {
		Slider.current.scrollBy({
			top: 0,
			left: -398,
			behavior: 'smooth'
		});
	}

		let productsRealCount = filteredRaftIdProducts.filter(el => {
			return productsMap.hasOwnProperty(el) && products[productsMap[el]].quantity > 0
		})

		return (
			<div className={selectedAccountClasses(props.category.id)} id={props.category.id}>
				<div className={"raft__categories " + ifCategoryHasNoChildren()}>
					<div className={`raft__categories--left`}>
						<Tabs
							categoryId={props.category.id}
							data={props.category}
							filterRaft={handleFilterRaft}
							filteredRaftId={filteredRaftId}
							filteredRaftIdProducts={filteredRaftIdProducts}
							activeRaftTab={activeSubCategoryClasses}
							clearFilter={clearFilter}
							handleRaftToggle={handleRaftToggle}
							handleRaftToggle_mobile={handleRaftToggle_mobile}
							expandAllProducts={expandAllProducts}
							hideButton={hideButton}
							productsMap={productsMap}
							products={products}
							productsRealCount={productsRealCount}
						/>
					</div>
					<div className="disabled-buttons">
						<button disabled={productsRealCount.length <= 4} onClick={() => goPrev()} className="carousel-arrow block carousel-prev custom" data-role="none"></button>
						<button disabled={productsRealCount.length <= 4} onClick={() => goNext()} className="carousel-arrow block carousel-next custom" data-role="none"></button>
					</div>
				</div>
				<div ref={Slider} className={"raft__content "} id="raft__slider">
					{/* <div className="ri raft-illustration--expanded"></div> */}
					{/* <div className="ris raft-illustration-shadow--expanded"></div> */}
					{
						filteredRaftIdProducts
							.filter(p => productsMap.hasOwnProperty(p) && products[productsMap[p]].quantity > 0)
							.map(p => {
								return (
									<Card
										key={p}
										product={products[productsMap[p]]}
										addToCart={handleAddToCart}
										dragCard={dragCard}
										favorites={favorites}
										handleFavorites={handleFavorites}
										handleFavoritesStyle={handleFavoritesStyle}
										auth={auth}
										user={user.me}
										showPlaceholder={showCards}
									/>
								)
							})
					}
				</div>
				{/* <div className="raft-illustration">
					<div className="raft-illustration__boards">
						<svg xmlns="http://www.w3.org/2000/svg" className="board" preserveAspectRatio="none" width="100%" height="47" viewBox="0 0 825 47">
							<defs>
								<clipPath id="a">
									<rect className="a" width="825" height="20" />
								</clipPath>
							</defs>
							<g transform="translate(-84 -301)">
								<g className="b" transform="translate(-170 -618)">
									<rect width="100%" height="20px" fill="#b2bec3" className="a" transform="translate(254 946)" />
									<g className="c" transform="translate(254 946)">
										<g className="d" transform="translate(-6.104 2.455)">
											<g className="e" transform="translate(53.915 5.545)">
												<ellipse className="h" cx="34.078" cy="6" rx="34.078" ry="6" />
												<ellipse className="i" cx="34.078" cy="6" rx="33.578" ry="5.5" />
											</g>
											<g className="e" transform="translate(0 3.545)">
												<ellipse className="h" cx="87.485" cy="8.5" rx="87.485" ry="8.5" />
												<ellipse className="i" cx="87.485" cy="8.5" rx="86.985" ry="8" />
											</g>
											<path className="f" d="M-3518.312,2189.093s166.878-4.441,184.839,1.3c77.657,10.211,64,1.2,91.229,0s184.618,4.478,207.6,0c24.726-4.615,127.213-2.486,218.021,0,69.358,1.9,123.689,6.217,130.781,2.674" transform="translate(3522.721 -2187.455)" />
											<path className="f" d="M0,1.638S166.892-2.8,184.855,2.939c77.663,10.211,64.009,1.2,91.237,0s184.632,4.478,207.614,0c24.728-4.615,127.225-2.486,218.04,0,69.362,1.9,123.7,6.217,130.79,2.674" transform="translate(835.858 11.757) rotate(180)" />
										</g>
									</g>
								</g>
								<path className="g" d="M28.482,0H796.476l28.482,27H0Z" transform="translate(84 301)" />
							</g>
						</svg>
					</div>
					<div className="raft-illustration__shadow">
						<svg width="100%" height="60" viewBox="0 0 889.226 60.039" preserveAspectRatio="none">
							<defs>
								<linearGradient id="a_02" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
									<stop offset="0" stopColor="#b2bec3" />
									<stop offset="1" stopColor="#b1bec3" stopOpacity="0" />
								</linearGradient>
							</defs>
							<path className="a_02" d="M1154,328l63.324,59.622h825.9l-63.768-60.039Z" transform="translate(-1154 -327.583)" />
						</svg>
					</div>
				</div> */}
				<div className="raft__slider--decorator-end"></div>
			</div>
		)
}

export default Raft;