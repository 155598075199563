import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import { connect, useSelector, useDispatch } from "react-redux";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { Formik, Form, ErrorMessage } from 'formik';
import { User } from "@oblax/sdk-js";
import { isEmpty } from "../helper";
import bc from "../callsetter";
import IMG_EMAIL from '../assets/images/register_check_email.svg';

// Components Import
import  RevealPassword  from "./RevealPassword";

// Actions
import { hideAuthPopup, showAuthPopup } from "../actions/userAuthAction";

const RegisterPopup = (props) => {

	const [showP, setShowP] = useState(true);
	const [trimMargin, setTrimMargin] = useState(false);
	const [formErrors, setFromError] = useState(false);
	const [successPanel, setSuccessPanel] = useState(false);
	const [registerFailed, setRegisterFailed] = useState(false);
	const [registerFailedMessage, setRegisteredFailedMessage] = useState('');
	const [buttonClickLoader, setButtonClickLoader] = useState(false);
	const [badRegister, setBadRegister] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleOpenRegisterPopup = (data) => {
		dispatch(showAuthPopup(data));
	}

	const _openLoginPopup = () => {
		handleOpenRegisterPopup("login");
		history.push("/login");
	}

	const handleCloseRegisterPopup = (data) => {
		dispatch(hideAuthPopup(data));
	}

	const _closePopup = () => {

		setShowP(false);
		setTimeout(() => {
			handleCloseRegisterPopup("register");
			history.push("/")
		},100)
	}

	const register = (data) => {
		let user = {
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			phone_number: data.phone_number,
			address: data.address,
			profile: {
				password: data.password
			},
			location: {
				city: data.city,
				continent: 'EU',
				country: 'MK',
				zipcode: data.zipcode,
				region: data.region,
			}
		}

		setButtonClickLoader(true);
		setRegisterFailed(false);

		let u = new User();
		u.loadFromJSON(user);

		bc.auth.createAccount(u)
			.then((params) => {
				if (params.data !== null) {
					if (params.data.status && params.data.status === 'ACCOUNT_EXISTS') {
						setButtonClickLoader(false);
						setRegisterFailed(true);
						setRegisteredFailedMessage('Корисник со истиот email веќе постои во нашиот систем.');
					} else {		
						setButtonClickLoader(false);
						setSuccessPanel(true);				
					}
				}
			})
			.catch((err) => {	
				setRegisterFailed(true);
				setBadRegister(true);
				setButtonClickLoader(false);
				setRegisterFailed(true);
				setRegisteredFailedMessage('Грешка при регистрација. Ве молиме пробајте повторно.')
			});
	};

		return (
			<div className={"product__popup register"} id="login__popup">
				<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
					<div className="product__popup--overlay" onClick={() => _closePopup()}></div>
				</Animated>
				<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP} >
					<div className="product__popup--full">
						<div className="section__favorite">
							<h2 className="auth__popup-title">
								{!successPanel ? 'Регистрирај се' : 'Уште само еден чекор'}
							</h2>
						</div>
						<div className="section__close">
							<button aria-label="cancel" onClick={() => _closePopup()} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>
					{!successPanel ? <RegisterForm buttonClickLoader={buttonClickLoader} register={register} /> : <SuccessMessage /> }
					{registerFailed ? <div class="signup-form-fail"><span>!</span>{registerFailedMessage}</div> : null}
				</Animated>
			</div>
		)
}

const SuccessMessage = (props) => {
	return (
		<div class="signup-form-success">
			<img class="success-email" src={IMG_EMAIL}/>
			<h3>Проверете го вашиот email за да завршите со регистрацијата</h3>
			<p>Линк за активација на вашата корисничка сметка е испратен на email адресата внесена во формуларот за регистрација. Кликнете на линкот и уживајте во купувањето на raft.mk</p>
			<p>Ви благодариме</p>
		</div>
	);
};

const RegisterForm = (props) => {
	const [form, setForm] = useState({
		trimMargin: false,
		formErrors: false,
		inputType: "password"
	});

	const formValues = {
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		zipcode: "",
		city: "",
		address: "",
		region: "",
		phone_number: ""
	};

	const validateForm = (values) => {
		let errors = {};
		if (!values.first_name) errors.first_name = "Задолжително";
		if (!values.last_name) errors.last_name = "Задолжително";
		if (!values.email) { errors.email = "Задолжително"; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) { errors.email = "Неправилна e-mail адреса" }
		if (!values.password) { errors.password = "Задолжително" } else if (values.password.length < 8) { errors.password = "Лозинката треба да има минимум 8 карактери" };
		if (!values.zipcode) errors.zipcode = "Задолжително";
		if (!values.city) errors.city = "Задолжително";
		if (!values.address) errors.address = "Задолжително";
		if (!values.region) errors.region = "Задолжително";
		if (!values.phone_number) { errors.phone_number = "Задолжително"; } else if (!/^07[0-9]{7}$/.test(values.phone_number)) { errors.phone_number = "Неправилен формат (Пр. 0726*****)" }

		if (!isEmpty(errors)) {
			setForm({
				trimMargin: true,
				formErrors: true,
				inputType: "password"
			});
		}
		return errors;
	};

	const onSubmit = (values) => {
		props.register(values);
	};

	const handlePasswordSwitch = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setForm({
			...form,
			inputType: form.inputType === "password" ? "text" : "password"
		});
	};

	return (
		<div className="product__popup-main">
			<div className={"popup__block popup__inputs " + (form.trimMargin ? "trim-margin" : "")}>
				<Formik
					initialValues={formValues}
					validate={validateForm}
					onSubmit={onSubmit}
					render={({ errors, touched, values, handleChange }) => (
						<Form name="register-form">
							<div className="error-holder">
								<div className="single-error"><ErrorMessage name="last_name" component="span" className="invalid-feedback halfed" /></div>
								<div className="single-error"><ErrorMessage name="first_name" component="span" className="invalid-feedback halfed" /></div>
							</div>
							<p className="popup__inputs--half">
								<input id="first_name" name="first_name" value={values.first_name} onChange={handleChange} type="text" className={"placeholder__input " + (errors.first_name && touched.first_name && errors.first_name ? "invalid-input" : "")} placeholder="Име" />
								<input name="last_name" id="last_name" onChange={handleChange} value={values.last_name} type="text" className={"placeholder__input " + (errors.last_name && touched.last_name && errors.last_name ? "invalid-input" : "")} placeholder="Презиме" />
							</p>
							<div className="error-holder">
								<div className="single-error"><ErrorMessage name="region" component="span" className="invalid-feedback halfed" /></div>
								<div className="single-error"><ErrorMessage name="address" component="span" className="invalid-feedback halfed" /></div>
							</div>
							<p className="popup__inputs--half">
								<input name="address" id="address" onChange={handleChange} value={values.address} type="text" className={"placeholder__input " + (errors.address && touched.address && errors.address ? "invalid-input" : "")} placeholder="Адреса" />
								<input onChange={handleChange} value={values.region} name="region" id="region" type="text" className={"placeholder__input " + (errors.region && touched.region && errors.region ? "invalid-input" : "")} placeholder="Општина" />
							</p>
							<div className="error-holder">
								<div className="single-error"><ErrorMessage name="zipcode" component="span" className="invalid-feedback halfed" /></div>
								<div className="single-error"><ErrorMessage name="city" component="span" className="invalid-feedback halfed" /></div>
							</div>
							<p className="popup__inputs--half">
								<input onChange={handleChange} value={values.city} name="city" id="city" type="text" className={"placeholder__input " + (errors.city && touched.city && errors.city ? "invalid-input" : "")} placeholder="Град" />
								<input onChange={handleChange} value={values.zipcode} name="zipcode" id="zipcode" type="text" className={"placeholder__input " + (errors.zipcode && touched.zipcode && errors.zipcode ? "invalid-input" : "")} placeholder="Поштенски број" />
							</p>
							<p>
								<ErrorMessage name="phone_number" component="span" className="invalid-feedback without-holder" />
								<input onChange={handleChange} value={values.phone_number} name="phone_number" id="phone_number" type="text" className={"placeholder__input " + (errors.phone_number && touched.phone_number && errors.phone_number ? "invalid-input" : "")} placeholder="Телефон" />
							</p>
							<p>
								<ErrorMessage name="email" component="span" className="invalid-feedback without-holder" />
								<input onChange={handleChange} value={values.email} name="email" id="email" type="text" className={"placeholder__input " + (errors.email && touched.email && errors.email ? "invalid-input" : "")} placeholder="email" />
							</p>
							<p className="password__input">
								<ErrorMessage name="password" component="span" className="invalid-feedback without-holder" />
								<RevealPassword
									handleChange={handleChange}
									handleReveal={handlePasswordSwitch}
									type={form.inputType}
									value={values.password}
									placeholder="Лозинка"
									errors={errors}
									touched={touched}
								/>
							</p>
							<p>
								<button aria-label="register" type="submit" className="full-width__button--primary">
									{props.buttonClickLoader ? <div className="button-loader">
										<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div> : <FormattedMessage id={"REGISTER"} />}
								</button>
							</p>
						</Form>
					)}
				/>
			</div>
		</div>
	);
};

export default injectIntl(RegisterPopup);
