import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import bc from "../callsetter";

const Confirm = (props) => {
    const history = useHistory();
    
    useEffect(() => {
        bc.auth.confirmAccount(props.match.params.id)
            .then((params) => {
                if(params.ok) {
                    history.push("/login");
                }
            })
            .catch(err =>{
                console.error(err);
                history.push("/");
            })
    },[]);

    return (
        <div id="confirm">
            <div className="centered">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                <h2>Работиме на вашето пренасочување на raft.mk</h2>

            </div>
            
        </div>
    )
}

export default Confirm;
