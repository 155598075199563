import React from "react";

const Walkthrough = (props) => {
		return (
			<div className="walkthrough-container">
				<div className="intro"><h2 className="walkthrough-title">{props.title}</h2></div>
				{props.content !== "none" ? <div className="content"><p className="walkthrough-content">{props.content}</p></div> : null}
				{props.media !== "" ?
					<div className="media">
						<video key={`${process.env.REACT_APP_MINIO_CDN}${props.media}`} autoPlay="autoplay" loop>
							<source src={`${process.env.REACT_APP_MINIO_CDN}${props.media}`} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div> : null}
			</div>
		)
	}
export default Walkthrough;