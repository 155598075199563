import React from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

// Actions
import { closeMenu } from "../../actions/MenuActions";

const MobileTrackOrder = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();

	const goToOrderHistory = () => {
		history.push("/order-history");
		dispatch(closeMenu());
	}

		return (
			<div className="track-order mobile__track-order">
				<div className="track-order__header">
					<h2>Статус на нарачка</h2>
				</div>
				<div className="track-order__container">
					<div className="track-order__item status">
						<div className="status__item packing">
							<div className="status__item--circle">
								<span></span>
							</div>
							<div className="status__item--info">
								<h2><FormattedMessage id={"PACKING"} /></h2>
								<span></span>
							</div>
						</div>

						<div className="status__item shipped">
							<div className="status__item--circle">
								<span></span>
							</div>
							<div className="status__item--info">
								<h2><FormattedMessage id={"SHIPPING"} /></h2>
								<span></span>
							</div>
						</div>

						<div className="status__item loading">
							<div className="status__item--circle">
								<span></span>
							</div>
							<div className="status__item--info">
								<h2><FormattedMessage id={"DELIVERED_ORDER"} /></h2>
								<span></span>
							</div>
						</div>
					</div>

					<div className="track-order__item  prev__orders">
						<div className="track-order__item  timing">
							<span>Време за испорака: </span>
							<h2>35 мин</h2>
						</div>
						<button aria-label="order-history" onClick={goToOrderHistory} className="button--primary">
							претходни нарачки
						</button>
					</div>
				</div>
			</div>
		)
}

export default MobileTrackOrder;
