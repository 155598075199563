const initialState = {
	expanded: [],
	expand: false,
	category: null,
	button: "сите производи",
	viaButton: false
};

const expandRaftReducer = (state = initialState, action) => {
	switch(action.type) {
		case "EXPAND_RAFT" : 
			return {
				...state,
				expanded: [
					...state.expanded, action.values.category,
				],
				expand: true,
				category: action.values.id,
				button: "врати",
			}
		case "SHRINK_RAFT" : 
			return {...state, 
				expanded: [
				...state.expanded.filter((raft) => {
					return raft !== action.values.id;
					})
				], 
				expand: false,
				category: action.values.id,
				button: "сите производи",
			}
		case "EXPAND_VIA_BUTTON" : 
			return {
				...state,
				viaButton: true
			}
		case "SHRINK_VIA_BUTTON" : 
			return {
				...state,
				viaButton: false,
				category: ""
			}
		default: return state;
	}
};

export default expandRaftReducer;