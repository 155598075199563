import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from 'formik';

import bc from "../callsetter";
import { User } from "@oblax/sdk-js";
import { isEmpty } from "../helper";
import Toast from "./shared/Toast"

import { slideDownTrack } from "../actions/TrackOrderAction";

import { Animated } from "react-animated-css";

//The commented part in the code on line 203 and 204 is temporary disabled until the track order option is done 

const OrderSuccess = (props) => {

	const [showP, setShowP] = useState(true);
	const [password, setPassword] = useState("");
	const [first_name, setFirstName]= useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [delivery_address, setDeliveryAddress] = useState("");
	const [delivery_address_number, setDeliveryAddressNumber] = useState("");
	const [registerSuccess, setRegisterSuccess] = useState(false);
	const [registerProcessing, setRegisterProcessing] = useState(false);
	const [accountTrue, setAccountTrue] = useState(false);
	const [showStatus, setShowStatus] = useState(false);
	const [registerError, setRegisterError] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastCode, setToastCode] = useState("");
	const [toastType, setToastType] = useState("");

	const auth = useSelector(state => state.authPopup.auth.isAuthenticated);
	const authUser = useSelector(state => state.authPopup.auth.authUser);
	const user = useSelector(state => state.userReducer.me);
	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {

		setTimeout(() => {
			setShowStatus(true);
		}, 500);

		let user = localStorage.getItem("pu");
		// puser = potential user
		let puser = JSON.parse(user);

		if(puser !== null) {
			setFirstName(puser.first_name);
			setLastName(puser.last_name);
			setEmail(puser.email);
			setDeliveryAddress(puser.delivery_address);
			setDeliveryAddressNumber(puser.delivery_address_number);
		}

		setTimeout(() => {
			if(auth && authUser !== null) {
				setShowP(false);
				setTimeout(() => {
					history.push("/");
				}, 100);
			}
		}, 5000);
	},[]); 

	const CloseProcessing = () => {
		localStorage.removeItem("pu");
		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);
	};

	const RegisterUser = (data) => {

		setRegisterProcessing(true);

		var u = new User();

		u.loadFromJSON({
			first_name: first_name,
			last_name: last_name,
			email: email,
			delivery_address: delivery_address,
			delivery_address_number: delivery_address_number,
			profile: { password: data.process_password }
		});

		return bc.auth.createAccount(u)
			.then((res) => {
				if (res.ok) {
					setRegisterSuccess(true);
					setRegisterProcessing(false);
					setAccountTrue(true);
					localStorage.removeItem("pu");
				}
			}).catch((err) => { 
				setToastMessage("Грешка при регистрација. Ве молиме обидете се подоцна.");
				setShowToast(true);
				setToastCode("red");
				setToastType("newregistererror");
				setRegisterError(true);
				console.error(err);
			 });
	};

	const HideErrorPopup = () => {
		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);
	};

	const HandlePasswordChange = (e) => {
		setPassword(e.target.value);
	};


	const SlideOrderTrack = () => {
		setShowP(false);
		setTimeout(() => {
			history.push("/");
		}, 100);

		dispatch(slideDownTrack());
	}

	const hideToast = () => {
		setTimeout(() => {
			setShowToast(false);
		}, 4000);
	};
		return (
			showStatus ?
				isEmpty(user) || (auth && authUser === null) ?
					<div className={"product__popup "} id="processing__popup">
						<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP}>
							<div className="product__popup--overlay" onClick={HideErrorPopup}></div>
						</Animated>

						<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" isVisible={showP}>
							<div className="product__popup-main payment">
								<div className="transaction-popup--block transaction-processing">
									<div className="transaction--done">
										<div className="done"></div>
										<h2>Нарачката е успешна</h2>
									</div>
								</div>

								<div className="transaction-popup--block transaction__register-helper">
									<div className="insider">
										<Formik
											initialValues={{
												process_password: ""
											}}
											validate={values => {
												let errors = {};

												if (!values.process_password) { errors.process_password = "Задолжително" } else if (values.process_password.length < 8) { errors.process_password = "Лозинката треба да има минимум 8 карактери" };

												return errors;
											}}
											onSubmit={values => {
												RegisterUser(values);
											}}
											render={({ errors, touched, values, handleChange }) => (
												<Form name="register-after-order">
													<h2 className="user-name">{first_name},</h2>
													<p className="transcation-info">Бидејќи ги имаме веќе вашите информации, се што е потребно да имате сметка на Raft е само вашата лозинка.</p>
													<p>
														<div className="label-holder">
															<ErrorMessage name="process_password" component="span" className="invalid-feedback halfed" />
															<label className="label__info" htmlFor="process_password">лозинка</label>
														</div>
														<input name="process_password" onChange={handleChange} type="process_password" value={values.process_password} className={"label__input " + (errors.process_password && touched.process_password && errors.process_password ? "invalid-input" : "")} id="process_password" />
													</p>
													<p className="transcation-button-holder">
														<button aria-label="register" type="submit" className="full-width__button--primary">{!registerProcessing || registerError ?
															"Регистрирај се" :
															<div className="button-loader">
																<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
															</div>
														}</button>
													</p>

													{!accountTrue ? <div className="section__close">
														<button aria-label="cancel" type="button" onClick={CloseProcessing} className="close__popup-button button__ghost--borderless--primary">Не сакам сметка</button>
													</div> : <div className="success-register"><h2>Успешна регистрација. Потврдете ја вашата сметка на: <u>{email}</u></h2></div>}
												</Form>
											)}
										/>
									</div>
								</div>

							</div>
						</Animated>
						{
							showToast

								?

								<Toast
									show={showToast}
									type={toastType}
									message={toastMessage}
									code={toastCode}
									hideToast={hideToast}
								/>

								:

								null
						}
					</div> :
					<div className={"product__popup trigger-resize"} id="processing__popup">
						<Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP}>
							<div className="product__popup--overlay" onClick={HideErrorPopup}></div>
						</Animated>

						<Animated style={{animationDuration:"0.2s"}} className="product__popup-container" isVisible={showP}>
							<div className="product__popup-main payment">
								<div className="transaction-popup--block transaction-processing">
									<div className="transaction--done">
										<div className="done"></div>
										<h2>Нарачката е успешна</h2>

										<div className="disclaimer-info">
											<p>Можете да ја пратите во вашата </p>

											<div className="grouped-buttons">
												<NavLink to="/order-history">Историја</NavLink> 
												{/* Temporary disabling the option to track the order after a successful order, the user can only see his order in Order History */}
												{/* <hr className="disclaimer-divider" /> */}
												{/* <button aria-label="diff-route" onClick={SlideOrderTrack} className="button__ghost--borderless--primary">или тука</button> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Animated>
					</div > : null
		)
}

export default OrderSuccess;
