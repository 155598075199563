import { useState } from 'react';

import './style.css';

const Select = (props) => {
    let error = props.error ? 'error' : '';
    return (
        <label className={`form-select ${error}`}>
            <span className="form-select__input-label">{props.label}</span>
            <select 
                className="form-select__input-field" 
                type="text" 
                onChange={props.onChange}
                name={props.name}
                value={props.value}
            >
                {props.firstLineEmpty ? <option value="">{props.placeholder}</option> : null}
                {props.options && props.options.map((o, i) => {
                    return <option value={o.value} key={`${o.value}_${o.label}`}>{o.label}</option>
                })}
            </select>
        </label>
    );
};

export default Select;