export const showSaveCartPopup = () => {
	return {
		type: "SHOW_SAVE_CART_POPUP"
	}
}

export const hideSaveCartPopup = () => {
	return {
		type: "HIDE_SAVE_CART_POPUP"
	}
}

export const saveCart = (cart) => {
	return {
		type: "SAVE_CART",
		values: cart
	}
}

export const showSavedCartsDropdown = (data) => {
	return {
		type: "SHOW_SAVED_CARTS_DROPDOWN",
		values: data
	}
}

export const hideSavedCartsDropdown = () => {
	return {
		type: "HIDE_SAVED_CARTS_DROPDOWN"
	}
}

export const startChooseCart = (list) => {
	return {
		type: "CHOOSE_CART",
		payload: list
	}
}

export const setDeletedItem = (item) => {
	return {
		type: "SET_DELETED_ITEM",
		payload: item
	}
}

export const chooseCart = (cart) => {
	return dispatch => {
		dispatch(hideSavedCartsDropdown());
		dispatch(startChooseCart(cart))	
	}
}