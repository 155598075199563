import React from "react";
import { FormattedMessage } from "react-intl";

const QuantityPicker = (props) => {
    
    const preventPopupOpening = (e) =>{
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div className="quantity-container">
				{
					props.showIncrement
	
					?

					<div className="quantity-container__actions">
						<button aria-label="minus" onClick={ props.removeItem } className="minus icon-button__textless--red ">
							<span></span>
						</button>

						<input 
							type="text"
							min="0"
							className="actions__value" 
							value={props.items || ''}
							onChange={props.changeItemNumber}
							onClick={preventPopupOpening}
							readOnly={true}
						/>

						<button aria-label="plus" onClick={ props.addItem } className="plus icon-button__textless--red ">
							<span></span>
						</button>
					</div>

					:

					null
				}

				<div className="quantity-container__values">
					<h2>{ props.price + " "}  <FormattedMessage id={"MKD"} />	{ " × " + props.items }</h2>
					<h1><span></span> = <span></span>{props.price * props.items + "  " } <FormattedMessage id={"MKD"} /></h1>
				</div>
		</div>
    )
}

export default QuantityPicker;
