import React from "react";

//TODO change login with social media buttons on login popup

const LinkedProfiles = (props) => {

	return(
		<div className="settings-container settings-children-container" id="settings--basic-info">
			<div>
				<div className="settings__content--block">
					<div className="settings__content--header">
						<div className="category-title">
							<a>
								<span className="chevron"></span>
								<h2>Поврзани Профили</h2>
							</a>
						</div> 
					</div>

					<div className="settings__content--main">
						{
							props.userProfiles.map((profile) => {
								return (
									<div key={ profile.id } className="linked-profile">
										<div className={"profile-intro " + (profile.provider)}>
											<div>
												<span className="profile-icon">icon</span>
												<h2 className="profile-network">{profile.provider}</h2>	
											</div>

											{/* <button aria-label="delete" className="small-button--primary">избриши</button> */}
										</div>

										<div className="profile-info">
											<p>
												<label className="label__info" htmlFor="settings__email">име и презиме</label>
												<span className="label__info-src">{props.user.first_name} {props.user.last_name}</span>
											</p>

											<p>
												<label className="label__info" htmlFor="settings__email">email</label>
												<span className="label__info-src">{props.user.email}</span>
											</p>
										</div>
									</div>
								)
							})
						}
						
					</div>
								
				</div>
			</div>
		</div>
	);
}

export default LinkedProfiles;
