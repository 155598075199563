import React, { useState } from "react";
import { connect } from "react-redux";
import placeholder from "../assets/images/user_avatar_placeholder.svg";

import Avatar from './Avatar';

// Component imports
import { showDropdown } from "../actions/DropdownsAction";

const MobileUser = (props) => {

		return(
			<div className="user__corner">
				<div className="dropdowns">
					<ul>
						<li>
							<button aria-label="favorites" onClick={ props.favorites } className="user__corner-favorites icon-button--naked">
								<span className="user__corner-favorites icon-button--naked user__corner-favorites-icon">favorites</span>
							</button>

							<hr className="icon__divider" />
						</li>

						{/* <li>
							<button aria-label="track-order" onClick={props.trackOrder} className="user__corner-track-order icon-button--naked">
								<span className="user__corner-track-order-icon">track order</span>
							</button>

							<hr className="icon__divider" />
						</li> */}
					</ul>
				</div>
				<div className="avatar">
					<div onClick={ props.settings } className="avatar__button">
						<figure className="avatar__image-holder">
							<Avatar src={props.user.picture} />
						</figure>
						<h2>{props.user.first_name} {props.user.last_name}</h2>
					</div>
				</div>
			</div>
		)
}


export default MobileUser;
