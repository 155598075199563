import { useState } from 'react';

import RadioButton from '../../ui/RadioButton';

import ICON_CARD from '../../../assets/images/payment_method_card.svg';
import ICON_INVOICE from '../../../assets/images/payment_method_invoice.svg';

const CheckoutPopupStepPaymentMethod = (props) => {
    return (
        <div className="workflow-step__payment-method">
            <div className="workflow-step__form-container">
                <label>
                    <input type="radio" name="payment_method" value="card"/>
                    <div className="workflow-step__payment-method__option">
                        <img src={ICON_CARD} />
                        <div className="workflow-step__payment-method__text" >
                            <span className="workflow-step__payment-method__text__top">Картичка</span>
                            <span className="workflow-step__payment-method__text__bottom">Директно плаќање со вашата компаниска картичка. Трансакцијата се процесира веднаш.</span>
                        </div>
                    </div>
                </label>
                <label>
                    <input type="radio" name="payment_method" value="invoice"/>
                    <div className="workflow-step__payment-method__option">
                        <img src={ICON_INVOICE} />
                        <div className="workflow-step__payment-method__text" >
                            <span className="workflow-step__payment-method__text__top">Фактура</span>
                            <span className="workflow-step__payment-method__text__bottom">Доставуваме фактура за нарачката. Плаќањето го вршите жирарно.</span>
                        </div>
                    </div>
                </label>
            </div>
            <p className="workflow-step__info-container">Изберете го начинот на плаќање. Доколку имате компаниска картичка плаќањето можете да го извршите веднаш.<br/><br/>Доколку изберете плаќање со фактура, истата ќе Ви биде доставена при испораката.</p>
        </div>
    );
};

export default CheckoutPopupStepPaymentMethod;