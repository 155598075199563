const initialState = {
	mainCategories: [],
	allCategories: [],
	category: {},
}

const categoriesReducer = (state = initialState, action) => {
	switch(action.type) {
		case "GET_ALL_MAIN_CATEGORIES":
			return {
				...state,
				mainCategories: action.payload
			};
		case "GET_ALL_CATEGORIES":
			return {
				...state,
				allCategories: action.payload
			}
		case "GET_CATEGORY_BY_ID":
			return {
				...state,
				category: action.payload
			};
		default: return state;
	}
};

export default categoriesReducer;