import { useState } from 'react';

import ICON_WORKFLOW_SELF from '../../../assets/images/checkout_step_chooser_self.svg';
import ICON_WORKFLOW_ANOTHER_PERSON from '../../../assets/images/checkout_step_chooser_another_person.svg';
import ICON_WORKFLOW_COMPANY from '../../../assets/images/checkout_step_chooser_company.svg';
import ICON_WORKFLOW_INFO from '../../../assets/images/checkout_step_chooser_info.svg';

const CheckoutPopupStepMain = (props) => {

    const [tooltipShow, setTooltipShow] = useState('');
    const [tooltipType, setTooltipType] = useState('default');
    
    const onMouseOver = (e) => {
        setTooltipShow('show');
        setTooltipType(e.target.dataset.var);
    };

    const onCheckoutPathClick = (e) => {
        if(props.data.delivery_city === 'Skopje') {
            props.onSetCheckoutPath(e.target.dataset.var);
        } else {
            props.onSetCheckoutPath(`${e.target.dataset.var}-no-datetime`);
        }
    };

    return (
        <div className="workflow-chooser">
            <p className="workflow-chooser__intro"><strong>Raft.mk</strong> ви овозможува неколку различни начини за купување. За да го подобриме корисничкото искуство со плаќањето и испораката, овој чекор го поделивме во неколку категории. Одберете еден од трите понудени начини за купување приложени долу.</p>
            <div className="workflow-chooser-box__list">
                <div className="workflow-chooser-box" onMouseOver={onMouseOver} data-var="self" onClick={onCheckoutPathClick}>
                    <img src={ICON_WORKFLOW_SELF} className="workflow-chooser-box__icon"/>
                    <span className="workflow-chooser-box__label">себе</span>
                </div>
                <div className="workflow-chooser-box" onMouseOver={onMouseOver} data-var="other-person" onClick={onCheckoutPathClick}>
                    <img src={ICON_WORKFLOW_ANOTHER_PERSON} className="workflow-chooser-box__icon" />
                    <span className="workflow-chooser-box__label">друга личност</span>
                </div>
                <div className="workflow-chooser-box disabled soon" onMouseOver={onMouseOver} data-var="company" onClick={() => {}}>
                {/* <div className="workflow-chooser-box disabled" onMouseOver={onMouseOver} data-var="company" onClick={onCheckoutPathClick}> */}
                    <img src={ICON_WORKFLOW_COMPANY} className="workflow-chooser-box__icon" />
                    <span className="workflow-chooser-box__label">фирма</span>
                </div>
            </div>
            <div className={`workflow-chooser__tooltip ${tooltipShow} ${tooltipType}`}>
                <img src={ICON_WORKFLOW_INFO} className="workflow-chooser__tooltip__icon" />
                <span className="workflow-chooser__tooltip__content default">Кликни на една од понудените опции </span>
                <span className="workflow-chooser__tooltip__content self">Нарачувам за себе и моето семејство. Нарачката се испорачува на мојата адреса и јас сум личноста која ќе ја подигне.</span>
                <span className="workflow-chooser__tooltip__content other-person">Нарачувам за близок. Нарачката ќе ја прими друго лице на различна локација од мојата.</span>
                <span className="workflow-chooser__tooltip__content company">Правам нарачка за фирма и канцеларија. Плаќањето може да биде со картичка или фактурно.</span>
            </div>
        </div>
    );
};

export default CheckoutPopupStepMain;