const initialState = {
	pages: []
}

const pagesReducer = (state = initialState, action) => {
	switch(action.type) {
		case "GET_PAGES" :
			return {
				...state,
				pages: action.payload
			}
		default: return state;
	}
}

export default pagesReducer;