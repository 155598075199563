const initialState = {
	lang: 'mk'
};

const locale = (state = initialState, action) => {
	switch(action.type) {
		case "LOCALE_SET":
			return {
				...state, lang: action.lang
			};
		default:
			return state;
	}
}

export default locale;
