import { useState } from 'react';

import './style.css';

const TextArea = (props) => {
    let error = props.error ? 'error' : '';
    return (
        <label className={`form-textarea ${error}`}>
            <span className="form-textarea__input-label">{props.label}</span>
            <textarea 
                className="form-textarea__input-field" 
                value={props.value} 
                onChange={props.onChange} 
                name={props.name}
            ></textarea>
        </label>
    );
};

export default TextArea;