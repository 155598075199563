export const expandRaft = (category) => {
	return {
		type: "EXPAND_RAFT",
		values: {
			id: category,
			category: category
		}
	}
};

export const shrinkRaft = (category) => {
	return {
		type: "SHRINK_RAFT",
		values: {
			id: category,
			category: category
		}
	}
};

export const shrinkViaButton = () => {
	return {
		type: "SHRINK_VIA_BUTTON"
	}
}

export const expandViaButton = () => {
	return {
		type: "EXPAND_VIA_BUTTON"
	}
}