import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { isEmpty, ArrayIsEmpty } from "../helper";

// Import of components
import  CartItem  from "./CartItem";
import SavedCarts from "./SavedCarts";

// Actions
import { showPaymentPopup } from "../actions/PaymentAction";
import { showSaveCartPopup, showSavedCartsDropdown, chooseCart, setDeletedItem } from "../actions/SaveCartAction";
import { dropCard } from "../actions/ShoppingCartAction";
import { ScrollToTop } from "./ScrollToTop";
import { hideCart } from "../actions/ShowCartAction";
import { addToCart, deleteCartItem, increment, decrement, createCartStart, getCartItemsList, createOrder } from "../actions/ShoppingCartAction";

import bc from "../callsetter";
import { Animated } from "react-animated-css";

const MobileCart = React.memo((props) => {

	const [item_count, setItemCount] = useState(1); //possibly not used state

	const savedCarts = useSelector(state => state.saveCartReducer.savedCarts);
	const cartData = useSelector(state => state.shoppingCartReducer);
	const cartDataId = useSelector(state => state.shoppingCartReducer.cart.id);
	const cartItems = useSelector(state => state.shoppingCartReducer.cartItems);
	const chosenCart = useSelector(state => state.saveCartReducer.chosenCart);
	const chosenCartId = useSelector(state => state.saveCartReducer.chosenCart.id);
	const defaultCart = useSelector(state => state.shoppingCartReducer.defaultCart);
	const defaultCartId = useSelector(state => state.shoppingCartReducer.defaultCart.id);
	const authUser = useSelector(state => state.authPopup.auth.authUser);
	const isAuthenticated = useSelector(state => state.authPopup.auth.isAuthenticated);
	const user = useSelector(state => state.userReducer.me);
	const showCart = useSelector(state => state.showCartReducer.showCart);
	const products = useSelector(state => state.productsReducer.products);
	const productsMap = useSelector(state => state.productsReducer.productsMap);


	const dispatch = useDispatch();

	const history = useHistory();

	const hideGradientOnBottomScroll = () => {
		var element = document.querySelector(".cart__items--scrollable");
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			document.getElementById("mobile-cart-gradient").style.opacity = "0";
		} else {
			document.getElementById("mobile-cart-gradient").style.opacity = "1";
		};
	};

	/**
    * Change quantity on mouse click. This function increases the item number
	*/

	const addItem = (product) => {
		dispatch(addToCart(product));
	};

	/**
    * Change quantity on mouse click. This function decreases the item number
	*/

	const removeItem = (product) => {
		dispatch(decrement(product));
	};

	/**
	* Displays the Payment Popup
	*/

	const showPaymentPopup = () => {
		return bc.orders.createOrder(cartDataId)
			.then((params) => {
				if (params.ok) {
					dispatch(createOrder(params.data));
					history.push("/cart/" + (cartDataId));
				}
			})
			.catch((err) => { console.error(err) });
	};

	/**
	* Deletes the product from the users' shopping cart
	*/

	const removeProduct = (product) => {
		dispatch(deleteCartItem(product));
	};

	/**
	* Display the Create List Popup
	*/

	const goToCreateList = () => {
		history.push("/create-list");
		handleHideCart();
	};

	const handleHideCart = () => {
		dispatch(hideCart());
	};

	/**
	* Pick Default cart from Lists dropdown
	*/

	const goToDefaultCart = () => {
		dispatch(chooseCart(defaultCart));
		dispatch(createCartStart(defaultCart));
		dispatch(getCartItemsList(defaultCartId));
	};

	/**
	* Pick any given list in the list dropdown
	*/

	const chooseList = (cartData) => {
		dispatch(chooseCart(cartData));
		dispatch(createCartStart(cartData));
		dispatch(getCartItemsList(cartData.id));
	};

	/**
	* Display the Create List Popup
	*/

	const askDeleteListConfirmation = (e, item) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(showSavedCartsDropdown(savedCarts));
		dispatch(setDeletedItem(item));
		history.push("/delete-list/" + item.id);
	};

	/**
    * Checks if product is on promotion to display promotional price. If not it switches to regular price
	*/

	const PriceValue = (product) => {
		let data;

		if (product.promotion_price !== null) {
			data = Math.trunc(product.promotion_price);
		} else {
			data = Math.trunc(product.price);
		}

		return data;
	};

		let sum = 0;

		const showSaveCartPopup = () => {
			dispatch(showSavedCartsDropdown(savedCarts));
		};

		return (
			<div className="cart__content">
				<div className={"mobile-cart__header " + (cartItems.length !== 0 ? "push__header" : null)}>
					<div className="full-width__cart-header--content">
						<div onClick={handleHideCart} className="close-cart">
							<button aria-label="cancel" className="close-car--icon icon-button--naked"></button>
						</div>

						<div className="mobile-cart__header-wrap">
							<div className="cart__header-title">
								<h2>Кошничка ({cartItems.length})</h2>
							</div>

							{
								isAuthenticated && authUser !== null

									?

									<div className="save-cart">
										<button aria-label="new-list" onClick={goToCreateList} className="cart__save-button icon-button--secondary">
											<span className="cart__save-button-icon icon-button--naked">зачувај</span>
											нова листа
									</button>
									</div>

									:

									null
							}
						</div>
					</div>

					<div className="saved-cards--mobile">
						{
							!isEmpty(user) && (isAuthenticated && authUser !== null)

								?

								<SavedCarts
									showSavedCarts={showSaveCartPopup}
									items={savedCarts}
									goToCreateList={goToCreateList}
									askDeleteListConfirmation={askDeleteListConfirmation}
									goToDefaultCart={goToDefaultCart}
									chooseList={chooseList}
									defaultCart={defaultCart}
								/>

								:

								null
						}
					</div>

				</div>

				{

					ArrayIsEmpty(cartItems) && defaultCart.hasOwnProperty("id") && ArrayIsEmpty(products) && !isEmpty(productsMap)

						?

						<div onScroll={hideGradientOnBottomScroll} className={"cart__items " + (cartItems.length !== 0 ? "trim__items " : null) + (isEmpty(user) && (!isAuthenticated || authUser === null) ? "expand-cart-items" : "")}>
							{showCart ? <ScrollToTop element={"cart__items"} /> : null}
							<Animated isVisible={ArrayIsEmpty(cartItems)} animationIn="fadeIn fast" animationOut="fadeOut fast" className="cart__items--scrollable">
								{
									cartItems
										.filter((item) => { return !isEmpty(chosenCart) ? item.cart_id === chosenCartId : defaultCartId === item.cart_id })
										.map((item) => {
											return (
												<div key={item.id}>
													{products[productsMap[item.product_id]] !== undefined &&
														<div
															className={"cart-item-src " + (products[productsMap[item.product_id]].quantity <= 0 ? "unavaialbe" : "")}
														>
															{

																products[productsMap[item.product_id]].quantity <= 0

																	?

																	<span className="cart-item-status">не е достапен</span>

																	:

																	null
															}
															<CartItem
																countInfo={item}
																data={products[productsMap[item.product_id]]}
																increment={addItem}
																decrement={removeItem}
																removeProduct={removeProduct}
																showIncrement={true}
																hideCart={handleHideCart}
															/>
														</div>
													}

												</div>
											)
										})
								}
							</Animated>

						</div>

						:

						null

				}

				{
					cartItems.length === 0

						?

						<div id="empty-cart" >
							<div className="empty-cart__container">
								<div className="empty-cart-icon">
									<span></span>
								</div>
								<h2>Вашата кошничка е празна</h2>
							</div>
						</div>

						:

						<div className="cart__payments">
							<div className="cart--gradient" id="mobile-cart-gradient"></div>

							<div className="cart--positioned">
								<div className="cart__payments-block cart__buttons">
									{
										(!isEmpty(cartData) && ArrayIsEmpty(cartItems)) || (ArrayIsEmpty(products) && !isEmpty(productsMap))

											?

											cartItems.map(item => {
												products[productsMap[item.product_id]] !== undefined

													? 

													sum += PriceValue(products[productsMap[item.product_id]]) * parseInt(item.item_count, 0)

													:

													null
											})

											:

											null
									}
									<button aria-label="payment" className="cart__pay-button icon-button--primary" onClick={showPaymentPopup}>
										<div className="total">
											<h2>{sum} <FormattedMessage id={"MKD"} /></h2>
										</div>

										<div className="cart-src">
											<span className="cart__pay-button-icon icon-button--naked">raft kasa</span>
											<span className="cart__pay-button-text">на каса</span>
										</div>
									</button>
								</div>
							</div>
						</div>
				}
			</div>
		)
}, (nextProps, props) => {
	return props.cartItems !== nextProps || props.cartItems !== nextProps.cartItems;
});

export default MobileCart;
