import { useState } from 'react';

import Select from '../../ui/Select';
import Input from '../../ui/Input';
import TextArea from '../../ui/TextArea';

const CheckoutPopupStepCompanyInfo = (props) => {

    let cities = [
        { value: 'skopje', label: 'Скопје' },
        { value: 'kumanovo', label: 'Куманово' },
        { value: 'bitola', label: 'Битола' },
        { value: 'gostivar', label: 'Гостивар' },
        { value: 'ohrid', label: 'Охрид' },
    ];

    return (
        <div className="workflow-step__company-info">
            <div className="workflow-step__form-container">
                <Input label="Име на компанијата" />
                <div className="workflow-step__company-info__id-numbers">
                    <Input label="ЕДБ" />
                    <Input label="ЕМБС" />
                </div>
                <div className="workflow-step__company-bank">
                    <Input label="Жиро с-ка." />
                    <Select label="Банка" options={cities} />
                </div>
                <Input label="Интерна шифра" />
            </div>
            <p className="workflow-step__info-container">Внесете ги точно сите барани податоци за компанијата за која се врши нарачката.<br/><br/>Доколку сте регистриран корисник, Внесете ја Вашата интерна шифра како би можеле полесно да подготвиме фактура за нарачката.<br/><br/>Испораката се врши во работно време, за кое дополнително ќе бидете информирани.</p>
        </div>
    );
};

export default CheckoutPopupStepCompanyInfo;