import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

const UserDropdown = (props) => {

	const scrollToTop = () => {
		window.scrollTo({top:0, behavior:'smooth'});
	};

		return (
			<div className={"dropdown-src user-dropdown " + ( (props.show && props.type === "settings") ? "show--dropdown" : "hide--dropdown")}>
				<div className="notifications-container">
					<ul>
						<li className="notifications__list--settings" onClick={scrollToTop}>
							<NavLink className="dropdown-item" to="/settings/basic-info">
								<span></span>
								<FormattedMessage id={"SETTINGS"} />
							</NavLink>
						</li>

						<li className="notifications__list--statistics" onClick={scrollToTop}>
							<NavLink className="dropdown-item" to="/statistics">
								<span></span>
								<FormattedMessage id={"STATISTICS"} />
							</NavLink>
						</li>

						<li className="notifications__list--order-history" onClick={scrollToTop}>
							<NavLink className="dropdown-item" to="/order-history">
								<span></span>
								<FormattedMessage id={"ORDER_HISTORY"} />
							</NavLink>
						</li>

						<li onClick={ props.logout } className="notifications__list--logout">
							<NavLink className="dropdown-item" to="/">
								<span className="dropdown-item"></span>
								<FormattedMessage id={"LOG_OUT"} />
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		)
}

export default UserDropdown;
