import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { Animated } from "react-animated-css";


const ConfirmationPopup = (props) => { 
    let[showP, setShowP] = useState(true);
    let history = useHistory();

    let { id } = useParams();

    const closePopup = (e) => {
        e.preventDefault();
		e.stopPropagation();
        setShowP(false);
        setTimeout (() => {
            history.push("/");
        }, 100)
    }

    const removeList = (e) =>{
        props.removeList(id);
        closePopup(e);
    }

    return ( 
        <div className={"product__popup confirmation__popup"} id="confirmation__popup">
            <Animated style={{animationDuration:"0.2s"}} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
                <div onClick={closePopup} className="product__popup--overlay"></div>
            </Animated>

            <Animated style={{animationDuration:"0.2s"}} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP}>
                <div className="popup__header">
                    <div className="popup__header--block">
                        <h2>Бришење на листа</h2>
                    </div>

                    <div className="popup__header--block">
                        <button aria-label="cancel" onClick={closePopup} className="close__popup-button icon-button--naked"></button>
                    </div>
                </div>

                <div className="popup__content">
                    <h2 className="popup__disclaimer">Со оваа акција листата која ја одбравте ќе биде избришана. Дали сте сигурни дека сакате да продолжите?</h2>
                </div>

                <div className="popup__buttons">
                    <button aria-label="cancel" className="button__ghost--primary" onClick={closePopup}>Откажи</button>
                    <button aria-label="delete" className="button--primary" onClick={removeList}>Избриши</button>
                </div>
            </Animated>
        </div>
     );
}
 
export default ConfirmationPopup;