export const showPaymentPopup = () => {
	return {
		type: "OPEN_PAYMENT_POPUP"
	}
}

export const hidePaymentPopup = () => {
	return {
		type: "CLOSE_PAYMENT_POPUP"
	}
}