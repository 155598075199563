// **CHART AND DROPDOWN RESOURCES** //
export const MONTHS = [
	'Јануари',
	'Февруари',
	'Март',
	'Април',
	'Мај',
	'Јуни',
	'Јули',
	'Август',
	'Септември',
	'Октомври',
	'Ноември',
	'Декември',
];

export const WEEKDAYS_LONG = [
	'Недела',
	'Понеделник',
	'Вторник',
	'Среда',
	'Четврток',
	'Петок',
	'Сабота',
];

export const WEEKDAYS_SHORT = ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб'];

export const FORMAT = 'M/D/YYYY';