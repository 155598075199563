import React, { useState } from "react";

import  RevealPassword  from "./RevealPassword";
import { Animated } from "react-animated-css";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { Formik, Form, ErrorMessage } from 'formik';
import { useHistory } from "react-router";

const ResetPasswordForm = (props) => {

	const[showP, setShowP] = useState(true);
	const[inputType, setInputType] = useState("password");
	const[hash, setHash] = useState(props.match.params.hash);
	const history = useHistory();

	/**
    * Handle password switch from type password to type text
	*/

	const handlePasswordSwitch = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setInputType(inputType === "password" ? "text" : "password")
	}

	/**
    * Closes the popup and returns home
	*/

	const closePopup = () => {
			setShowP(false)
			setTimeout(() => {
				history.push("/");
			}, 100)
	}


		const { intl } = props;
		const inputPlaceholder = defineMessages({
			password: {
				id: "PASSWORD",
			}
		});

		return (
			<div className={"product__popup"} id="recover__popup">
				<Animated style={{ animationDuration: "0.2s" }} animationIn="fadeIn" animationOut="fadeOut" isVisible={showP} >
					<div className="product__popup--overlay" onClick={closePopup}></div>
				</Animated>

				<Animated style={{ animationDuration: "0.2s" }} className="product__popup-container" animationIn="zoomIn" animationOut="fadeOut" isVisible={showP}>
					<div className="product__popup--full">
						<div className="section__favorite">
							<h2 className="auth__popup-title"><FormattedMessage id={"PASSWORD_RECOVERY"} /></h2>
						</div>

						<div className="section__close">
							<button aria-label="cancel" onClick={closePopup} className="close__popup-button icon-button--naked"></button>
						</div>
					</div>

					<div className="product__popup-main">
						<div className="popup__block popup__inputs">
							<div className="text-info">
								<p>Внесете ја вашата нова лозинка.</p>
							</div>
							<Formik
								initialValues={{
									password: ""
								}}
								validate={values => {
									let errors = {};
									if (!values.password) { errors.password = "Задолжително" } else if (values.password.length < 8) { errors.password = "Лозинката треба да има минимум 8 карактери" };
									
									return errors;
								}}
								onSubmit={values => {
									props.resetPassword(hash, values.password);
								}}
								render={({ errors, touched, values, handleChange }) => (
									<Form name="register-form">
										<p className="password__input">
											<ErrorMessage name="password" component="span" className="invalid-feedback without-holder" />
											<RevealPassword
												handleChange={handleChange}
												handleReveal={handlePasswordSwitch}
												type={inputType}
												value={values.password}
												errors={errors}
												touched={touched}
												placeholder={intl.formatMessage(inputPlaceholder.password)}
											/>
										</p>
										<p className="action-paragraph">
											<button aria-label="send" disabled={values.password === ""} type="submit" className="full-width__button--primary">
												{props.buttonLoader ?
													<div className="button-loader">
														<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
													</div> : <FormattedMessage id={"SEND"} />}
											</button>
										</p>
									</Form>
								)}
							/>
						</div>
					</div>
				</Animated>
			</div>
		)
}

export default injectIntl(ResetPasswordForm);
