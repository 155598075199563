import React from "react";
import { FormattedMessage } from "react-intl";

const NutriPaneRow = (props) => {
	return (
		<div className="tab__paragraph-container">
			<div className="ingridient-list">
				{Array.isArray(props.data) && props.data.length > 0 ?
					props.data.map((rowData, i) => {
						return (
							<div className="meta-item-group" key={i}>
								<h2>
									<FormattedMessage id={rowData.type.toUpperCase()} />
									<span>
										{rowData.value}
										{rowData.unit !== "" ? <span className="unit"><FormattedMessage id={rowData.unit} /></span> : null}
									</span>
								</h2>
							</div>
						)
					}) : "Нема информации за овој производ"}
			</div>
		</div>
	)
}

export default NutriPaneRow;