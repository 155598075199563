import { before } from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';

const Badge =(props) => {
    const [badgeIcon, setBadgeIcon] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");

    useEffect(() => {
        handleBadgeNames(props.data);
    }, [])

    const handleBadgeNames = (data) =>{
        switch(data) {
            case "NEW_PRODUCT" :
                setBadgeIcon("new-product-icon");
                setBadgeDescription("Нов производ");
            break;
            case "ONLY HERE" :
                setBadgeIcon("only-here-icon");
                setBadgeDescription("Само на рафт.мк");
            break;
            case "LOCAL_PRODUCT":
                setBadgeIcon("local-icon");
                setBadgeDescription("Локален производ");
            case "FLYER_PRODUCT" :
                setBadgeIcon("flyer-icon");
                setBadgeDescription("Производ на флаер");
            break;
            case "ORGANIC":
                setBadgeIcon("organic-icon");
                setBadgeDescription("Производот е огрански");
            break;
            case "HALAL" :
                setBadgeIcon("halal-icon");
                setBadgeDescription("Производот е халал");
            break;
            case "KOSHER" :
                setBadgeIcon("kosher-icon");
                setBadgeDescription("Производот е кошер");
            break;
            case "VEGAN":
                setBadgeIcon("vegan-icon");
                setBadgeDescription("Производот е вегански");
            break;
            case "RECOMENDED" : 
                setBadgeIcon("recommended-icon");
                setBadgeDescription("Го препорачуваме овој производ");
            break;
            case "BEST_SELLER" :
                setBadgeIcon("best-seller-icon");
                setBadgeDescription("Највеќе продавам производ");
            break;
            case "LOW_PRICE":
                setBadgeIcon("low-price-icon");
                setBadgeDescription("Производот има ниска цена");
            break;
            case "OUR_BRAND":
                setBadgeIcon("raft-icon");
                setBadgeDescription("Производот е наш");
            break;
            case "GLUTEN_FREE" :
                setBadgeIcon("gluten-icon");
                setBadgeDescription("Производот е без глутен");
            break;
            default:  
                setBadgeIcon("");
                setBadgeDescription("Побарајте повторно");
        }
    };

    const PreventDefault = (e) =>{
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <div className="badge__container" onClick={PreventDefault}>
				<div className="badge__tooltip">
					<h2>{badgeDescription}</h2>
				</div>
				<div title={ badgeDescription } className={"badge " + (badgeIcon)}>
					<span className={"badge__title " + (badgeIcon)}></span>
				</div>
			</div>

    )
}

export default Badge
