import React, { useState } from "react";
import { MobileView } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

// Component imports
import { ScrollToTop } from "./ScrollToTop";
import Card from "./Card";
import  Footer  from "./shared/Footer";

// Actions
import { expandRaft, shrinkRaft } from "../actions/ExpandRaftAction";
import { showPopup, closePopup } from "../actions/ProductPopupAction";
// import { fetchFavorites } from "../actions/FavoritesAction";
import { removeFavoritedItem } from "../actions/FavoritesAction";
import { fetchFavorites } from "../actions/FavoritesAction";
import { addToCart } from "../actions/ShoppingCartAction";

import bc from "../callsetter";
import { Bookmark } from "@oblax/sdk-js";
import { isEmpty } from "../helper";


const SearchedProducts = (props) => {

	const user = useSelector(state => state.userReducer);
	const auth = useSelector(state => state.authPopup.auth);
	const favorites = useSelector (state => state.favoritesReducer.favorites);
	const dispatch = useDispatch();


	/**
    * Drags a product
	*/

	const dragCard = (e, product) => {
		e.dataTransfer.setData("id", product.id);
	}


	const handleFavorites = (productId) => {
		let favId = favorites.filter((item) => { return item.record_id === productId });

		if (favorites.length <= 0) {
			addToFavorites(productId);
		} else {
			if (favorites.includes(favId[0])) {
				removeFromFavorites(favId[0].id);
			} else {
				addToFavorites(productId);
			}
		}
	};

	/**
    * Add to favorites
	*/

	const addToFavorites = (productId) => {
		let b = new Bookmark();
		b.loadFromJSON({
			type: "product",
			record_id: productId,
			user_id: user.me.id 
		});

		return bc.bookmarks.createBookmark(user.me.id, "product", b)
			.then((params) => {
				if (params !== null) {
					handleFetchFavorites();
				}
			})
			.catch((err) => {
				console.error("Error creating bookmark", err);
			})
	}

	/**
    * Remove from favorites
	*/

	const removeFromFavorites = (favId) => {
		return bc.bookmarks.removeBookmark(user.me.id, "product", favId)
			.then((params) => {
				if (params !== null) {
					handleFetchFavorites();
				}
			})
			.catch((err) => {
				console.error("Error removing bookmark", err);
			})
	}

	/**
    * Style favorite items with a filled red heart icon
	*/

	const handleFavoritesStyle = (productId) => {
		let classes = ['raft-card__favorite icon-button--naked'];

		if (favorites.length !== 0) {
			favorites.forEach((fav) => {
				if (fav.record_id === productId) {
					classes.push('favorite--active');
				}
			})
		}

		return classes.join(' ');
	}

	/**
    * Fetch list of favorited items
	*/

	const handleFetchFavorites = () => {
		if (!isEmpty(user.me)) {
			return bc.bookmarks.getCategoryBookmarks(user.me.id, "product")
				.then((params) => {
					if (params.ok) {
						dispatch(fetchFavorites(params.data));
					}
				})
				.catch((err) => {
					console.error("Error fetching Bookmarks", err);
				})
		}
	}

	const handleAddToCart = (product) => {
		dispatch(addToCart(product));
	}

		return (
			<div id="searched-products">
				<ScrollToTop element="app-container" />
				<MobileView renderWithFragment>
					<div className="searched-value">
						<h2>Пребаравте:</h2>
						<h4>{props.searchedTerm} <span onClick={props.clearSearchMobile} className="remove-search-value"></span></h4>
					</div>
				</MobileView>
				<div className="component__content all-products">
					<div className="raft__content" id="raft__slider">
						{
							props.products.map(p => {
								return (
									<div key={p.id} className="favorite">
										<Card
											{...props}
											key={p.id}
											product={p}
											addToCart={handleAddToCart}
											dragCard={dragCard}
											favorites={favorites}
											handleFavorites={handleFavorites}
											handleFavoritesStyle={handleFavoritesStyle}
											auth={auth}
											user={user.me}
										/>
									</div>
								)
							})
						}
					</div>
				</div>

				<Footer pages={props.pages} openTour={props.openTour} />
			</div>
		)
}

export default SearchedProducts;
