export default [
    {label: "Автокоманда", value: "Автокоманда"},
    {label: "Аеродром", value: "Аеродром"},
    {label: "Бутел I", value: "Бутел I"},
    {label: "Бутел II", value: "Бутел II"},
    {label: "Влае", value: "Влае"},
    {label: "Водно", value: "Водно"},
    {label: "Гази Баба", value: "Гази Баба"},
    {label: "Горно Лисиче", value: "Горно Лисиче"},
    {label: "Гоце Делчев", value: "Гоце Делчев"},
    {label: "Даме Груев", value: "Даме Груев"},
    {label: "Дебар Маало", value: "Дебар Маало"},
    {label: "Дексион", value: "Дексион"},
    {label: "Долно Нерези", value: "Долно Нерези"},
    {label: "Драчево", value: "Драчево"},
    {label: "Дуќанџик", value: "Дуќанџик"},
    {label: "Ѓорче Петров", value: "Ѓорче Петров"},
    {label: "Жданец", value: "Жданец"},
    {label: "Железара", value: "Железара"},
    {label: "Злокуќани", value: "Злокуќани"},
    {label: "Јане Сандански", value: "Јане Сандански"},
    {label: "Капиштец", value: "Капиштец"},
    {label: "Карпош I", value: "Карпош I"},
    {label: "Карпош II", value: "Карпош II"},
    {label: "Карпош III", value: "Карпош III"},
    {label: "Карпош IV", value: "Карпош IV"},
    {label: "Кисела Вода", value: "Кисела Вода"},
    {label: "Кисела Јабука", value: "Кисела Јабука"},
    {label: "Козле", value: "Козле"},
    {label: "Криводол", value: "Криводол"},
    {label: "Населба Лисиче", value: "Населба Лисиче"},
    {label: "Маџари", value: "Маџари"},
    {label: "Маџир Маало", value: "Маџир Маало"},
    {label: "Мичурин", value: "Мичурин"},
    {label: "Ново Лисиче", value: "Ново Лисиче"},
    {label: "Пинтија", value: "Пинтија"},
    {label: "Припор", value: "Припор"},
    {label: "Пролет", value: "Пролет"},
    {label: "Пржино", value: "Пржино"},
    {label: "Радишани", value: "Радишани"},
    {label: "Расадник", value: "Расадник"},
    {label: "Скопје Север", value: "Скопје Север"},
    {label: "Тафталиџе", value: "Тафталиџе"},
    {label: "Топанско Поле", value: "Топанско Поле"},
    {label: "Трнодол", value: "Трнодол"},
    {label: "Ќерамидница", value: "Ќерамидница"},
    {label: "Хиподром", value: "Хиподром"},
    {label: "Хром", value: "Хром"},
    {label: "Центар (населба)", value: "Центар (населба)"},
    {label: "Црниче", value: "Црниче"},
    {label: "Чаир", value: "Чаир"},
    {label: "Шуто Оризари", value: "Шуто Оризари"}
];